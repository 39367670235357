import { createFeatureSelector } from '@ngrx/store';
import { RegistrantDataState } from '@store/registrants/registrant.firestore.reducer';
import {
  FIRESTORE_COLLECTION_REGISTRANTS,
  FIRESTORE_REGISTRANTS_SUBCOLLECTION_EVENT_MAIL_LIST,
  FIRESTORE_EVENT_MAIL_LIST_SUBCOLLECTION_STATUS
} from '@store/firestore/allFirestoreCollections';
import { RegistrantEventMailListDataState } from './registrant-event-mail-list.firestore-subcollection.reducer';
import { RegistrantEventMailStatusDataState } from './registrant-event-mail-status.firestore-subcollection.reducer';

export const registrantDataState = createFeatureSelector<RegistrantDataState>(FIRESTORE_COLLECTION_REGISTRANTS);

export const eventMailListDataState = createFeatureSelector<RegistrantEventMailListDataState>(
  FIRESTORE_REGISTRANTS_SUBCOLLECTION_EVENT_MAIL_LIST
);

export const eventMailListStatusDataState = createFeatureSelector<RegistrantEventMailStatusDataState>(
  FIRESTORE_EVENT_MAIL_LIST_SUBCOLLECTION_STATUS
);
