import { Injectable, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env';

@Injectable({
  providedIn: 'root'
})
/**
 * Service used to manage all the requests related with the CMS plugins
 */
export class CMSPluginService implements OnInit {
  constructor(private http: HttpClient) {}

  ngOnInit(): void {}

  generateApiKey(userId: string): Observable<any> {
    const url = environment.api.generateAPIKeyUrl;
    return this.http.post<any>(url, { userId });
  }

  generateToken(apiKey: string): Observable<any> {
    const url = environment.api.createTokenUrl;
    return this.http.post<any>(url, { apiKey });
  }
}
