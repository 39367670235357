import { OnDestroy, OnInit, Directive } from '@angular/core';
import { Subject } from 'rxjs';

@Directive()
export class SubscriptionComponent implements OnInit, OnDestroy {
  protected stopSubscription = new Subject<void>();

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.stopSubscription.next();
    this.stopSubscription.unsubscribe();
  }
}
