import { Component, NgZone } from '@angular/core';
import { FormValidators } from '@shared/forms/form-validators';
import { FormGroup, AbstractControl } from '@angular/forms';
import { WodenFormBuilder } from '@shared/forms/woden-form-builder';
import { FormControlStructure } from '@core/models/form-control-structure';
import { Dictionary } from '@core/types/dictionary.interface';
import { limits } from '@shared/constants';
import { Store, select } from '@ngrx/store';
import { AuthDataState } from '@store/auth/auth.reducer';
import { authDataState } from '@store/auth';
import { ChangePasswordAction } from '@store/auth/auth.actions';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarService } from '@services/snack-bar.service';
import { handleAuthError } from '../auth-error-text-helper';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent extends FormValidators {
  myForm: FormGroup;
  limits: Dictionary;
  passwordLimit: Dictionary;

  public loading = false;
  private PASSWORD_FORM_CTRL_KEY = 'passwordFormCtrl';
  private CONFIRM_PASSWORD_FORM_CTRL_KEY = 'confirmPasswordFormCtrl';

  constructor(
    private store: Store<AuthDataState>,
    private translate: TranslateService,
    private snackBarService: SnackBarService,
    private zone: NgZone
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    this.passwordLimit = { limit: limits.PASSWORD };

    this.store.pipe(select(authDataState)).subscribe(({ loading, authError }) => {
      this.loading = loading;
      handleAuthError(authError, this.snackBarService, this.translate, this.zone);
    });
  }

  initForm(): void {
    this.limits = limits;
    const passwordFormCtrl = new FormControlStructure(this.PASSWORD_FORM_CTRL_KEY, 'forPassword');
    const confirmPasswordFormCtrl = new FormControlStructure(
      this.CONFIRM_PASSWORD_FORM_CTRL_KEY,
      'forEquals',
      'passwordFormCtrl'
    );
    const formBuilder = new WodenFormBuilder([passwordFormCtrl, confirmPasswordFormCtrl]);
    this.myForm = formBuilder.getFormInstance();
  }

  get passwordFormCtrl(): AbstractControl {
    return this.myForm.get(this.PASSWORD_FORM_CTRL_KEY);
  }

  get confirmPasswordFormCtrl(): AbstractControl {
    return this.myForm.get(this.CONFIRM_PASSWORD_FORM_CTRL_KEY);
  }

  onChangePassword() {
    const { valid, value } = this.validateForm();

    if (valid) {
      const { passwordFormCtrl } = value;

      this.store.dispatch(new ChangePasswordAction(passwordFormCtrl));
    }
  }
}
