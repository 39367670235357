/**Here we define the firestore collections names */
export const FIRESTORE_COLLECTION_PLANS = 'plans';
export const FIRESTORE_COLLECTION_USERS = 'users';
export const FIRESTORE_COLLECTION_SHOWS = 'events';
export const FIRESTORE_COLLECTION_TICKETS = 'tickets';
export const FIRESTORE_COLLECTION_COMPANIES = 'companies';
export const FIRESTORE_COLLECTION_REGISTRANTS = 'registrants';
export const FIRESTORE_COLLECTION_SUBSCRIPTIONS = 'subscriptions';
export const FIRESTORE_COLLECTION_PUBLIC_INFO = 'publicInfo';
export const FIRESTORE_COLLECTION_IMAGES = 'images';

export const FIRESTORE_EVENT_MAIL_LIST_SUBCOLLECTION_STATUS = 'status';
export const FIRESTORE_REGISTRANTS_SUBCOLLECTION_EVENT_MAIL_LIST = 'eventMailList';
