import { Component, NgZone } from '@angular/core';
import { limits } from '@shared/constants';
import { Dictionary } from '@core/types/dictionary.interface';
import { AuthDataState } from '@store/auth/auth.reducer';
import { authDataState } from '@store/auth';
import { select, Store } from '@ngrx/store';
import { FormControlStructure } from '@core/models/form-control-structure';
import { FormValidators } from '@shared/forms/form-validators';
import { WodenFormBuilder } from '@shared/forms/woden-form-builder';
import { FormGroup, AbstractControl } from '@angular/forms';
import { RegisterAction } from '@store/auth/auth.actions';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarService } from '@services/snack-bar.service';
import { handleAuthError } from '../auth-error-text-helper';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent extends FormValidators {
  private EMAIL_FORM_CTRL_KEY = 'emailFormCtrl';
  private PASSWORD_FORM_CTRL_KEY = 'passwordFormCtrl';
  private FULLNAME_FORM_CTRL_KEY = 'fullnameFormCtrl';
  private CONFIRM_PASSWORD_FORM_CTRL_KEY = 'confirmPasswordFormCtrl';

  myForm: FormGroup;
  limits: Dictionary;
  nameLimit: Dictionary;
  passwordLimit: Dictionary;

  loading = false;
  isVisible = false;
  isVisibleConfirmpass = false;

  constructor(
    private store: Store<AuthDataState>,
    private translate: TranslateService,
    private snackBarService: SnackBarService,
    private zone: NgZone
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    this.nameLimit = { limit: limits.FULL_NAME };
    this.passwordLimit = { limit: limits.PASSWORD };

    this.store.pipe(select(authDataState)).subscribe(({ loading, authError }) => {
      this.loading = loading;
      handleAuthError(authError, this.snackBarService, this.translate, this.zone);
    });
  }

  initForm(): void {
    this.limits = limits;
    const emailFormCtrl = new FormControlStructure(this.EMAIL_FORM_CTRL_KEY, 'forEmail');
    const fullnameFormCtrl = new FormControlStructure(this.FULLNAME_FORM_CTRL_KEY, 'forFullName');
    const passwordFormCtrl = new FormControlStructure(this.PASSWORD_FORM_CTRL_KEY, 'forPassword');
    const confirmPasswordFormCtrl = new FormControlStructure(
      this.CONFIRM_PASSWORD_FORM_CTRL_KEY,
      'forEquals',
      'passwordFormCtrl'
    );
    const formBuilder = new WodenFormBuilder([
      emailFormCtrl,
      fullnameFormCtrl,
      passwordFormCtrl,
      confirmPasswordFormCtrl
    ]);
    this.myForm = formBuilder.getFormInstance();
  }

  get emailFormCtrl(): AbstractControl {
    return this.myForm.get(this.EMAIL_FORM_CTRL_KEY);
  }

  get fullnameFormCtrl(): AbstractControl {
    return this.myForm.get(this.FULLNAME_FORM_CTRL_KEY);
  }

  get passwordFormCtrl(): AbstractControl {
    return this.myForm.get(this.PASSWORD_FORM_CTRL_KEY);
  }

  get confirmPasswordFormCtrl(): AbstractControl {
    return this.myForm.get(this.CONFIRM_PASSWORD_FORM_CTRL_KEY);
  }

  onContinue(): void {
    const { valid, value } = this.validateForm();

    if (valid) {
      const { emailFormCtrl, passwordFormCtrl, fullnameFormCtrl } = value;
      this.store.dispatch(new RegisterAction(emailFormCtrl, passwordFormCtrl, fullnameFormCtrl));
    }
  }
}
