import { BaseModel } from '../../core/models/basemodel';
import { FirestoreReducer, FirestoreReducerDataState } from './firestore.reducer';
import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
import { FirestoreCollectionReference } from '@core/models/firestore-collection-reference';

/**Abstract class modeling Firestore Reducers from Collections.
 * It doesn't support subcollections*/

export abstract class FirestoreCollectionReducer<
  T extends BaseModel,
  DataState extends FirestoreReducerDataState<T>
> extends FirestoreReducer<T, DataState> {
  getCollectionReference(
    db: AngularFirestore,
    firestoreCollectionReference: FirestoreCollectionReference
  ): AngularFirestoreCollection {
    const { queryFn } = firestoreCollectionReference;
    if (queryFn) {
      return db.collection<T>(this.collectionName, queryFn);
    } else {
      return db.collection<T>(this.collectionName);
    }
  }
}
