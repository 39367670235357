import { Show } from '../../core/models/show';
import { DashboardAction } from './dashboard.actions';
import { DashboardActionsType } from './dashboard.actions.name';
import { Company } from '@core/models';

export enum DashboardInterfaceMode {
  STEPPER,
  NORMAL,
  CARD_DIALOG,
  CARD_FORM,
  INITIAL /// Let effect calculate what's the initial InterfaceMode
}

export interface DashboardDataState {
  shows: Show[];
  search: string;
  revenues: number;
  attendees: number;
  pageIndex: number;
  showMessage: boolean;
  registrants: number;
  thereAreShows: boolean;
  countersLoaded: boolean;
  changePassword: boolean;
  stripeSessionId?: string;
  selectedTabIndex: number;
  dashboardInterfaceMode: DashboardInterfaceMode;
  message?: {
    type: 'info' | 'error';
    isTranslateKey: boolean;
    message: string;
  };
  myCompany?: Company;

  loading: boolean;
}

export const initialState: DashboardDataState = {
  shows: [],
  search: '',
  revenues: 0,
  attendees: 0,
  pageIndex: 0,
  showMessage: false,
  registrants: 0,
  thereAreShows: undefined,
  countersLoaded: false,
  changePassword: false,
  selectedTabIndex: 0,
  myCompany: undefined,
  dashboardInterfaceMode: DashboardInterfaceMode.NORMAL,
  loading: true
};

export function dashboardDataReducer(state = initialState, action: DashboardAction): DashboardDataState {
  const resetShowMessageState = { ...state, showMessage: false };

  switch (action.type) {
    case DashboardActionsType.SHOWS_UPDATED:
      const shows = action.shows;
      return { ...resetShowMessageState, shows, loading: false };
    case DashboardActionsType.ATTENDEES_UPDATED:
      const attendees = action.attendees;
      return { ...resetShowMessageState, attendees };
    case DashboardActionsType.REVENUES_UPDATED:
      const revenues = action.revenues;
      return { ...resetShowMessageState, revenues };
    case DashboardActionsType.REGISTRANTS_UPDATED:
      const registrants = action.registrants;
      return { ...resetShowMessageState, registrants };
    case DashboardActionsType.SHOWS_FILTER:
      const selectedTabIndex = action.filter;
      return { ...resetShowMessageState, selectedTabIndex, loading: true };
    case DashboardActionsType.SHOWS_SEARCH:
      const search = action.search;
      return { ...resetShowMessageState, search, loading: true };
    case DashboardActionsType.SHOWS_PAGE_CHANGED:
      const pageIndex = action.pageIndex;
      return { ...resetShowMessageState, pageIndex };
    case DashboardActionsType.USER_SELF_LOADED:
      const { userSelf } = action;
      return { ...resetShowMessageState, changePassword: userSelf.changePassword };
    case DashboardActionsType.MY_COMPANY_LOADED:
      const { myCompany } = action;
      return { ...resetShowMessageState, myCompany };
    case DashboardActionsType.RTDB_COUNTERS_LOADED:
      const { counters } = action;
      const countersLoaded = !!counters;
      return { ...resetShowMessageState, thereAreShows: countersLoaded && counters.showsCounter > 0, countersLoaded };
    case DashboardActionsType.URL_QUERIES_CHANGED:
      const { urlQueries } = action;
      const showMessage = !!urlQueries;
      const { purchaseSucceeded } = urlQueries;
      const message: {
        type: 'info' | 'error';
        message: string;
        isTranslateKey: boolean;
      } = showMessage
        ? {
            isTranslateKey: true,
            message: purchaseSucceeded ? 'snackbar.subscriptionPurchaseSuccess' : 'snackbar.paymentError',
            type: purchaseSucceeded ? 'info' : 'error'
          }
        : undefined;
      return { ...resetShowMessageState, showMessage, message };
    case DashboardActionsType.DEMO_PLAN_AND_ACTUAL_SUBSCRIPTION_LOADED:
      const { actualSubscription, demoPlan } = action;

      if (!actualSubscription || !demoPlan) {
        return resetShowMessageState;
      }
      const innerMyCompany = state.myCompany;
      const hasStripePaymentMethod = !!innerMyCompany.stripePaymentMethodId;
      const isDemoPlan = actualSubscription.planId === demoPlan.id;

      if (isDemoPlan && !hasStripePaymentMethod) {
        return {
          ...resetShowMessageState,
          showMessage: true,
          message: { isTranslateKey: true, message: 'snackbar.usingTrialSubscription', type: 'info' }
        };
      }
      return resetShowMessageState;
    case DashboardActionsType.INTERFACE_MODE_CHANGED:
      const { dashboardInterfaceMode } = action;
      return { ...resetShowMessageState, dashboardInterfaceMode };
    case DashboardActionsType.STRIPE_SESSION_LOADED:
      const { stripeSessionId } = action;
      return { ...resetShowMessageState, stripeSessionId };
    case DashboardActionsType.STRIPE_ERROR:
      const { errorMessage } = action;
      return {
        ...resetShowMessageState,
        showMessage: true,
        message: { isTranslateKey: false, message: errorMessage, type: 'error' }
      };
    default:
      return resetShowMessageState;
  }
}
