import { Company } from '@models/company';
import { FirestoreReducerDataState } from '@store/firestore/firestore.reducer';
import { FIRESTORE_COLLECTION_COMPANIES } from '@store/firestore/allFirestoreCollections';
import { companyDataState } from '@store/companies';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import { FirestoreCollectionReducer } from '@store/firestore/firestore-collection.reducer';

export interface CompanyDataState extends FirestoreReducerDataState<Company> {}

export class CompanyReducer extends FirestoreCollectionReducer<Company, CompanyDataState> {
  private static instance: CompanyReducer;

  featureSelector = companyDataState;
  collectionName = FIRESTORE_COLLECTION_COMPANIES;

  INNER_COLLECTION_ID = 'companyId';

  ADD_ACTION = '[Company] Add Company';
  UPSERT_ACTION = '[Company] Upsert company';
  DELETE_ACTION = '[Company] Delete company';
  MODIFY_ACTION = '[Company] Modify company';

  ADDED_ACTION = '[Company] Added company from firebase';
  DELETED_ACTION = '[Company] Deleted company from firebase';
  MODIFIED_ACTION = '[Company] Modified company from firebase';
  UPSERTED_ACTION = '[Company] Upserted company from firebase';
  LAST_DOCUMENT_SNAPSHOT = '[Company] Last documentSnapshot from firebase';
  MODIFIED_LOCALLY_ACTION = '[Company] Modify locally company';

  static getInstance() {
    if (!CompanyReducer.instance) {
      CompanyReducer.instance = new CompanyReducer();
    }
    return CompanyReducer.instance;
  }
}

export const myCompanySelector: MemoizedSelector<object, Company> = createSelector(
  CompanyReducer.getInstance().getConvertToArraySelector(),
  companies => {
    return companies[0] ? companies[0] : undefined;
  }
);
