import { FormGroup, AbstractControl } from '@angular/forms';
import { Dictionary } from '@core/types/dictionary.interface';
import { SubscriptionComponent } from '@shared/components/subscription.component';
import { Directive } from "@angular/core";

@Directive()
export abstract class FormValidators extends SubscriptionComponent {
  abstract myForm: FormGroup;

  ngOnInit(): void {
    super.ngOnInit();
    if (!this.myForm) {
      this.initForm();
    }
  }

  abstract initForm(): void;

  iptHasErrors(formControl: AbstractControl, validator: string = 'required'): boolean {
    const errorValidator = formControl.errors ? formControl.errors[validator] : false;
    return errorValidator && formControl.touched;
  }

  iptHasErrorsPristine(formControl: AbstractControl, validator: string = 'required'): boolean {
    const errorValidator = formControl.errors ? formControl.errors[validator] : false;
    return errorValidator && !formControl.pristine;
  }

  iptHasAnyError(formControl: AbstractControl): boolean {
    const haveErrors = formControl.errors ? Object.keys(formControl.errors).length > 0 : false;
    return haveErrors && formControl.touched;
  }

  getIptError(formControl: AbstractControl, validator: string = 'required', key?: string): string {
    const errorValidator = formControl.errors ? formControl.errors[validator][key] : '';
    return errorValidator;
  }

  isIptValid(formControl: AbstractControl): boolean {
    return formControl.valid && formControl.touched;
  }

  getClassesForIpt(formControl: AbstractControl): Dictionary {
    return { error: this.iptHasErrors(formControl), success: this.isIptValid(formControl) };
  }

  getClassesForIptPristine(formControl: AbstractControl): Dictionary {
    return { error: this.iptHasErrorsPristine(formControl), success: this.isIptValid(formControl) };
  }

  validateForm(): { valid: boolean; value: any } {
    const { controls, valid, value } = this.myForm;

    // Mark all form control as touched to show error in empty fields.
    for (const controlKey in controls) {
      if (controls.hasOwnProperty(controlKey)) {
        const formControl = controls[controlKey];
        formControl.markAsTouched();
      }
    }
    return { valid, value };
  }
}
