import { BaseModel } from '@models/basemodel';
import {
  FirestoreAction,
  AddedDataAction,
  ModifiedDataAction,
  DeletedDataAction,
  LastDocumentDataAction
} from './firestore.actions';
import { Store } from '@ngrx/store';
import {
  AngularFirestoreCollection,
  AngularFirestore,
  QueryDocumentSnapshot,
  DocumentData
} from '@angular/fire/firestore';
import { FirestoreReducerDataState, FirestoreReducer } from './firestore.reducer';
import { Subscription, of } from 'rxjs';
import { FirestoreCollectionReference } from '@core/models/firestore-collection-reference';
import { map, catchError, retry } from 'rxjs/operators';

/**Names used by firestore to publish their actions */

/**------------------------------------------ */
const FIRESTORE_ADDED_ACTION = 'added';
const FIRESTORE_REMOVED_ACTION = 'removed';
const FIRESTORE_MODIFIED_ACTION = 'modified';
/**------------------------------------------ */

/**Abstract class in charge of handling Firestore Redux Actions by listening to them and dispatch our own actions*/
export abstract class FirestoreReduxListener<T extends BaseModel, DataState extends FirestoreReducerDataState<T>> {
  protected collection: AngularFirestoreCollection;
  protected listeningSubscription: Subscription;
  protected lastDocumentSnapshot: QueryDocumentSnapshot<DocumentData>;
  //Only used for subcollections
  protected ancestorsId = '';
  protected receivedIds = new Set<string>();

  constructor(
    protected db: AngularFirestore,
    protected store: Store<DataState>,
    protected firebaseReducer: FirestoreReducer<T, DataState>
  ) {}

  public getLastDocumentSnapshot(): QueryDocumentSnapshot<DocumentData> {
    return this.lastDocumentSnapshot;
  }

  public removeReceivedDocumentsFromLocalStore() {
    for (const id of Array.from(this.receivedIds.keys())) {
      const deleteAction = new DeletedDataAction(this.firebaseReducer.DELETED_ACTION, id, {}, this.ancestorsId);
      this.store.dispatch(deleteAction);
    }
    this.receivedIds.clear();
  }

  /**Listens to Firebase Redux changes and dispatches our own actions to be handled internally */
  public startListening(): Subscription {
    this.listeningSubscription = this.collection
      .stateChanges([FIRESTORE_ADDED_ACTION, FIRESTORE_REMOVED_ACTION, FIRESTORE_MODIFIED_ACTION])
      .pipe(
        map(documentChangeActions => {
          //Used to send LastDocumentSnapshotDataAction with last added item coming from the FIRST request;
          let lastAddedDocumentSnapshot: QueryDocumentSnapshot<DocumentData>;
          documentChangeActions.forEach(actualDocumentChangeAction => {
            const doc = actualDocumentChangeAction.payload.doc;
            const id = doc.id;
            const data = doc.data();
            let actionToDispatch: FirestoreAction;

            switch (actualDocumentChangeAction.type) {
              case FIRESTORE_MODIFIED_ACTION:
                actionToDispatch = new ModifiedDataAction(
                  this.firebaseReducer.MODIFIED_ACTION,
                  id,
                  data,
                  this.ancestorsId
                );
                break;
              case FIRESTORE_REMOVED_ACTION:
                this.receivedIds.delete(id);
                actionToDispatch = new DeletedDataAction(
                  this.firebaseReducer.DELETED_ACTION,
                  id,
                  data,
                  this.ancestorsId
                );
                break;
              default:
                // case FIRESTORE_ADDED_ACTION
                lastAddedDocumentSnapshot = doc;
                this.receivedIds.add(id);

                actionToDispatch = new AddedDataAction(this.firebaseReducer.ADDED_ACTION, id, data, this.ancestorsId);
            }
            this.store.dispatch(actionToDispatch);
          });

          //Used to send LastDocumentSnapshotDataAction with last added item;
          if (lastAddedDocumentSnapshot) {
            this.lastDocumentSnapshot = lastAddedDocumentSnapshot;
            const action = new LastDocumentDataAction(
              this.firebaseReducer.LAST_DOCUMENT_SNAPSHOT,
              this.lastDocumentSnapshot.data()
            );
            this.store.dispatch(action);
          }
        }),
        retry(5),
        catchError(error => {
          console.log(`Error redux listener in ${this.firebaseReducer.collectionName}`, error);
          return of('Error');
        })
      )
      .subscribe();

    return this.listeningSubscription;
  }

  public stopListening(): void {
    if (this.listeningSubscription) {
      this.listeningSubscription.unsubscribe();
      this.listeningSubscription = undefined;
    }
  }

  protected initializeCollectionWithQuery(firestoreCollectionReference: FirestoreCollectionReference) {
    this.ancestorsId = firestoreCollectionReference.ancestorsId;
    this.collection = this.firebaseReducer.getCollectionReference(this.db, firestoreCollectionReference);
  }
}
