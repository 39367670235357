export const stts = [
  {
    icon: 'people',
    amount: 0,
    title: 'registrants',
    keyCounter: 'registrantsCounter',
    className: 'blue',
    isMoney: false
  },
  {
    icon: 'check-list',
    amount: 0,
    title: 'attendees',
    keyCounter: 'checkedCounter',
    className: 'blue-grey',
    isMoney: false
  },
  { icon: 'wallet', amount: 0, title: 'revenues', keyCounter: 'revenuesCounter', className: 'green', isMoney: true }
];

export const DELAY_UPDATE_UI = 300;
