import { version } from '../../package.json';
const cloudFunctionsUrl = 'https://us-central1-woden-prod.cloudfunctions.net';
const apiUrl = cloudFunctionsUrl + '/api';
const apiAuthUrl = apiUrl + '/auth';
const apiEmailUrl = apiUrl + '/emails';
const bigQueryUrl = apiUrl + '/bigquery';
const wodenUrl = 'https://admin.wodenevents.com/';

export const environment = {
  production: true,

  version,
  wodenUrl: wodenUrl,

  rsvpNotGoing: wodenUrl + '/emailRSVPResponse?newState=notGoing',
  rsvpAcceptFree: wodenUrl + '/emailRSVPResponse?newState=registered',

  gMapsApiKey: 'AIzaSyCq6HXCNZMny39Vl6DYpODPTElkvt6S1OM&',

  stripeApiKey: 'pk_live_BheLbHJZoFWYR8yyWdj7Q9yg00R3F7a267',

  bucketServer: 'woden-prod.appspot.com',

  oauth: {
    google: {
      clientId: '806864165335-84rrhrkgapbs4u5lhnfhfael8hilqsl8.apps.googleusercontent.com'
    }
  },

  api: {
    url: apiUrl,
    emailUrl: apiEmailUrl + '/show',
    rsvpEmailUrl: apiEmailUrl + '/rsvp',
    createTokenUrl: apiAuthUrl + '/createToken',
    invitationEmailUrl: apiEmailUrl + '/invitation',
    googleOAuthCode: apiAuthUrl + '/googleOAuthCode',
    generateAPIKeyUrl: apiAuthUrl + '/generateAPIKey',
    getLocationOffsetUrl: apiUrl + '/utc/locationOffset',
    addRegistrantsListUrl: apiUrl + '/registrantLists/events/:showId',
    stripeSessionIdUrl: apiUrl + '/payments/newPaymentMethodSessionId',
    bigQueryRegistrantsCountAndPaidSumByDateUrl: bigQueryUrl + '/registrantsSalesByShow'
  },

  chiketto: {
    apiKey: 'ign3letzwn.fb59772d-e900-4543-be4c-6a33264c653a',
    chikettoPostImageUrl: 'https://us-central1-chiketto-prod.cloudfunctions.net/api/images'
  },

  /**Firebase for Woden production */
  firebase: {
    apiKey: 'AIzaSyAuM9zSM9OUiRkR9Nr51-EeIzjhvb6n4HY',
    authDomain: 'woden-prod.firebaseapp.com',
    databaseURL: 'https://woden-prod.firebaseio.com',
    projectId: 'woden-prod',
    storageBucket: 'woden-prod.appspot.com',
    messagingSenderId: '806864165335'
  }
};
