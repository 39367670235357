import { NgModule, InjectionToken } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { StoreModule, ActionReducerMap } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AngularFireModule } from '@angular/fire';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AgmCoreModule } from '@agm/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { environment } from '@env';
import { reducers, metaReducers, effects, AppState } from '@store';
// Modules
import { CoreModule } from './core/core.module';
import { AuthModule } from './auth/auth.module';
import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
// Components
import { AppComponent } from './app.component';
import { CMSListConnectorComponent } from './components/cms-list-connector/cms-list-connector.component';
import { LandingComponent } from './landing/landing.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { SideNavComponent } from './components/side-nav/side-nav.component';
// Services
import { AuthService } from '@services/auth/auth.service';
import { ChikettoApiService } from '@services/chiketto/chiketto-api.service';
// Others
import { CanDeactivateGuard } from './guards/can-deactivate.guard';
import { ImageGalleryComponent } from './components/image-gallery/image-gallery.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';

const StoreDevTools = !environment.production ? StoreDevtoolsModule.instrument() : [];
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, './assets/locales/', '.json');
}

export const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<AppState>>('Root Reducer');

@NgModule({
  declarations: [
    AppComponent,
    CMSListConnectorComponent,
    LandingComponent,
    PageNotFoundComponent,
    SideNavComponent,
    ImageGalleryComponent
  ],
  imports: [
    AuthModule,
    SharedModule,
    AppRoutingModule,
    CoreModule.forRoot(),

    BrowserAnimationsModule,
    BrowserModule,
    RouterModule,
    EffectsModule.forRoot(effects),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      isolate: true
    }),
    StoreModule.forRoot(REDUCER_TOKEN, {
      metaReducers,
      runtimeChecks: { strictStateImmutability: true, strictActionImmutability: true }
    }),
    StoreDevTools,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireDatabaseModule,
    AgmCoreModule.forRoot({
      apiKey: environment['gMapsApiKey'],
      libraries: ['places']
    }),
    ScrollingModule,
    VirtualScrollerModule
  ],
  providers: [AuthService, ChikettoApiService, CanDeactivateGuard, { provide: REDUCER_TOKEN, useValue: reducers }],
  bootstrap: [AppComponent]
})
export class AppModule {}
