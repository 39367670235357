import { Show } from '@core/models/show';
import { ShowDetailActionsType } from '@store/show-detail/show-detail.actions.name';
import {
  ShowDetailAction,
  FILTER_INVITED,
  FILTER_ATTENDEES,
  FILTER_TICKET_ASSIGNED,
  FILTER_NOT_GOING
} from '@store/show-detail/show-detail.actions';
import { Registrant } from '@core/models/registrant';
import { ShowCounters } from '@core/models/company-counters';
import { Company, Ticket, Statistic } from '@core/models';
import { ShowTicketCounters } from '@core/models/show-ticket-counter.interface';
import { stts } from 'app/containers/dashboard/constants';
export interface ShowDetailDataState {
  show: Show;
  search: string;
  maxInvites: number;
  loading: boolean;
  company: Company;
  showTickets: Ticket[];
  revenuesNumber: number;
  attendeesNumber: number;
  selectedTabIndex: number;
  registrantsNumber: number;
  registrants: Registrant[];
  selectedRegistrants: Registrant[];
  showTicketsCounters: ShowTicketCounters;
  statistics: Statistic[];
  rtdbCounters: ShowCounters;
  lastSelectedPage: {
    [FILTER_INVITED]: number;
    [FILTER_NOT_GOING]: number;
    [FILTER_TICKET_ASSIGNED]: number;
    [FILTER_ATTENDEES]: number;
  };
}

export const initialState: ShowDetailDataState = {
  show: null,
  search: '',
  maxInvites: 0,
  loading: false,
  showTickets: [],
  company: new Company(),
  revenuesNumber: 0,
  attendeesNumber: 0,
  selectedTabIndex: 0,
  registrantsNumber: 0,
  registrants: [],
  selectedRegistrants: [],
  showTicketsCounters: { counters: [], total: 0 },
  statistics: stts,
  rtdbCounters: {
    checkedCounter: 0,
    confirmedCounter: 0,
    invitedCounter: 0,
    notGoingCounter: 0,
    registrantsCounter: 0,
    revenuesCounter: 0,
    tickets: {}
  },
  lastSelectedPage: {
    [FILTER_ATTENDEES]: 0,
    [FILTER_INVITED]: 0,
    [FILTER_NOT_GOING]: 0,
    [FILTER_TICKET_ASSIGNED]: 0
  }
};

export function showDetailDataReducer(state = initialState, action: ShowDetailAction): ShowDetailDataState {
  switch (action.type) {
    case ShowDetailActionsType.LOAD_SHOW:
    case ShowDetailActionsType.LOAD_REGISTRANTS:
      return { ...state, loading: true };
    case ShowDetailActionsType.CLEAR_DATA:
      return initialState;
    case ShowDetailActionsType.COMPANY_LOADED:
      const company = action.company;
      return { ...state, company };
    case ShowDetailActionsType.MAX_INVITES_LOADED:
      const maxInvites = action.maxInvites;
      return { ...state, maxInvites };
    case ShowDetailActionsType.SHOW_TICKETS_LOADED:
      const tickets = action.tickets;
      return { ...state, showTickets: tickets };
    case ShowDetailActionsType.SHOW_LOADED:
      const show = action.show;
      return { ...state, loading: false, show };
    case ShowDetailActionsType.SHOW_COUNTERS_LOADED:
      const { counters: rtdbCounters, statistics } = action;
      return { ...state, rtdbCounters, statistics };
    case ShowDetailActionsType.REGISTRANTS_LOADED:
      const registrants = action.registrants;
      return { ...state, loading: false, registrants };
    case ShowDetailActionsType.SELECTED_REGISTRANTS_LOADED:
      const selectedRegistrants = action.selectedRegistrants;
      return { ...state, selectedRegistrants };
    case ShowDetailActionsType.SHOW_TICKETS_COUNTERS_LOADED:
      const showTicketsCounters = action.showTicketsCounters;
      return { ...state, showTicketsCounters };
    case ShowDetailActionsType.ATTENDEES_UPDATED:
      const attendeesNumber = action.attendees;
      return { ...state, attendeesNumber };
    case ShowDetailActionsType.REGISTRANTS_UPDATED:
      const registrantsNumber = action.registrants;
      return { ...state, registrantsNumber };
    case ShowDetailActionsType.REVENUES_UPDATED:
      const revenuesNumber = action.revenues;
      return { ...state, revenuesNumber };
    case ShowDetailActionsType.SEARCH_REGISTRANTS:
      const search = action.text;
      return { ...state, search };
    case ShowDetailActionsType.FILTER_REGISTRANTS:
      const selectedTabIndex = action.filter;
      return { ...state, selectedTabIndex };
    case ShowDetailActionsType.TABLE_PAGE_CHANGED:
      const { tabIndex, page } = action;
      const { lastSelectedPage } = state;
      const newLastSelectedPage = { ...lastSelectedPage, [tabIndex]: page };
      return { ...state, lastSelectedPage: newLastSelectedPage };
    default:
      return state;
  }
}
