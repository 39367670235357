import { Store, select } from '@ngrx/store';
import { Component, Output, EventEmitter, NgZone } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { FormGroup, AbstractControl } from '@angular/forms';

import { Observable } from 'rxjs';
import { User } from '@models/user';

import { limits } from '@shared/constants';
import { authDataState } from '@store/auth/';
import { AuthDataState } from '@store/auth/auth.reducer';
import { Dictionary } from '@core/types/dictionary.interface';
import { LoginWithCredentialsAction } from '@store/auth/auth.actions';
import { FormValidators } from '@shared/forms/form-validators';
import { FormControlStructure } from '@core/models/form-control-structure';
import { WodenFormBuilder } from '@shared/forms/woden-form-builder';
import { SnackBarService } from '@services/snack-bar.service';
import { MatSnackBarRef } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { handleAuthError } from '../auth-error-text-helper';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends FormValidators {
  private EMAIL_FORM_CTRL_KEY = 'emailFormCtrl';
  private PASSWORD_FORM_CTRL_KEY = 'passwordFormCtrl';

  myForm: FormGroup;
  limits: Dictionary;
  passwordLimit: Dictionary;

  @Output()
  sendForgotPassword = new EventEmitter<boolean>();

  isVisible: boolean;
  userSelf: User;
  loading: boolean;
  snackBar: MatSnackBarRef<any>;

  user: Observable<User>;

  usersCollection: AngularFirestoreCollection<User> = this.db.collection<User>('users');

  constructor(
    private store: Store<AuthDataState>,
    private db: AngularFirestore,
    private translate: TranslateService,
    private snackBarService: SnackBarService,
    private zone: NgZone
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.isVisible = false;
    this.passwordLimit = { limit: limits.PASSWORD };

    this.store
      .pipe(
        select(authDataState),
        delay(300)
      )
      .subscribe(({ loading, authError, userSelf }) => {
        this.userSelf = userSelf;
        this.loading = authError ? false : loading;
        handleAuthError(authError, this.snackBarService, this.translate, this.zone);
      });
  }

  initForm(): void {
    this.limits = limits;
    const emailFormCtrl = new FormControlStructure(this.EMAIL_FORM_CTRL_KEY, 'forEmail');
    const passwordFormCtrl = new FormControlStructure(this.PASSWORD_FORM_CTRL_KEY, 'forPassword');

    const formBuilder = new WodenFormBuilder([emailFormCtrl, passwordFormCtrl]);
    this.myForm = formBuilder.getFormInstance();
  }

  get emailFormCtrl(): AbstractControl {
    return this.myForm.get(this.EMAIL_FORM_CTRL_KEY);
  }

  get passwordFormCtrl(): AbstractControl {
    return this.myForm.get(this.PASSWORD_FORM_CTRL_KEY);
  }

  onLogIn(): void {
    const { valid, value } = this.validateForm();

    if (valid) {
      const { emailFormCtrl, passwordFormCtrl } = value;
      this.store.dispatch(new LoginWithCredentialsAction(emailFormCtrl, passwordFormCtrl));
    }
  }

  onForgotPassword(): void {
    this.sendForgotPassword.emit(true);
  }
}
