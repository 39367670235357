<form [formGroup]="myForm" (ngSubmit)="onContinue()" class="cnt-form cnt-register">
  <div class="cnt-form-ipt">
    <div class="cnt-ipt" [ngClass]="getClassesForIpt(fullnameFormCtrl)">
      <span class="wi-user ipt-icon"></span>
      <input
        type="text"
        placeholder="{{'auth.fullName' | translate}}"
        class="form-ipt-full"
        formControlName="fullnameFormCtrl"
      />
    </div>
    <span class="form-error" *ngIf="iptHasErrors(fullnameFormCtrl)">{{ 'errors.required' | translate }}</span>
    <span class="form-error" *ngIf="iptHasErrors(fullnameFormCtrl, 'minlength')">{{
      'errors.minimum' | translate: nameLimit
    }}</span>
  </div>
  <div class="cnt-form-ipt">
    <div class="cnt-ipt" [ngClass]="getClassesForIpt(emailFormCtrl)">
      <span class="wi-mail ipt-icon"></span>
      <input
        type="emailFormCtrl"
        placeholder="{{'auth.email' | translate}}"
        class="form-ipt-full"
        formControlName="emailFormCtrl"
      />
    </div>
    <span class="form-error" *ngIf="iptHasErrors(emailFormCtrl)">{{ 'errors.required' | translate }}</span>
    <span class="form-error" *ngIf="iptHasErrors(emailFormCtrl, 'pattern')">{{ 'errors.email' | translate }}</span>
  </div>
  <div class="cnt-form-ipt">
    <div class="cnt-ipt" [ngClass]="getClassesForIpt(passwordFormCtrl)">
      <span class="wi-padlock ipt-icon"></span>
      <input
        [type]="isVisible ? 'text' : 'password'"
        name="passwordFormCtrl"
        placeholder="{{'auth.password' | translate}}"
        class="form-ipt-full"
        formControlName="passwordFormCtrl"
      />
      <app-toggle-type-ipt [(isVisible)]="isVisible"></app-toggle-type-ipt>
    </div>
    <span class="form-error" *ngIf="iptHasErrors(passwordFormCtrl)">{{ 'errors.required' | translate }}</span>
    <span class="form-error" *ngIf="iptHasErrors(passwordFormCtrl, 'minlength')">{{
      'errors.minimum' | translate: passwordLimit
    }}</span>
  </div>
  <div class="cnt-form-ipt">
    <div class="cnt-ipt" [ngClass]="getClassesForIpt(confirmPasswordFormCtrl)">
      <span class="wi-padlock ipt-icon"></span>
      <input
        [type]="isVisibleConfirmpass ? 'text' : 'password'"
        name="confirmPasswordFormCtrl"
        placeholder="{{'auth.confirmPassword' | translate}}"
        class="form-ipt-full"
        formControlName="confirmPasswordFormCtrl"
      />
      <app-toggle-type-ipt [(isVisible)]="isVisibleConfirmpass"></app-toggle-type-ipt>
    </div>
    <span class="form-error" *ngIf="iptHasErrors(confirmPasswordFormCtrl)">This field is required</span>
    <span class="form-error" *ngIf="iptHasErrors(confirmPasswordFormCtrl, 'notEqualsFormControl')">{{
      'errors.equalPassword' | translate
    }}</span>
  </div>
  <button
    type="submit"
    class="btn-rounded"
    [ngClass]="{ 'btn-blue': myForm.valid, 'btn-grey-disabled': myForm.invalid }"
    [disabled]="myForm.invalid"
  >
    {{ 'continue' | translate }}
  </button>
</form>

<app-loading class="center-loading" *ngIf="loading"></app-loading>
