<div class="spr-cnt-auth">
  <div class="platform-preview">
    <img src="assets/imgs/woden.svg" alt="Woden" class="logo" />
    <div class="cnt-slides" *ngFor="let slide of slides; let i = index" [ngClass]="slideClass(i)">
      <p class="description">{{ 'auth.' + slide.description | translate }}</p>
      <img src="{{ slide.image }}" alt="Registrant list woden managment" class="{{ slide.class }}" />
    </div>
    <div class="cnt-dots">
      <div
        *ngFor="let slide of slides; let i = index"
        class="dot"
        [class.mat-elevation-z5]="true"
        [ngClass]="activeClass(i)"
        (click)="chooseSlide(i)"
      ></div>
    </div>
  </div>

  <div class="cnt-auth" [class.mat-elevation-z2]="true">
    <header>
      <h2 class="title">{{ title | async }}</h2>
      <p class="body">
        {{ description | async }}
        <span class="highlight green" *ngIf="isLogIn || isForgotPass" (click)="changeTo('isSignUp')">{{
          'landing.signUp' | translate
        }}</span>
        <span *ngIf="isForgotPass"> or </span>
        <span class="highlight blue" *ngIf="isSignUp || isForgotPass" (click)="changeTo('isLogIn')">{{
          'landing.logIn' | translate
        }}</span>
      </p>
      <div class="cnt-btn" *ngIf="!isForgotPass">
        <button class="btn-white btn-rounded" (click)="onLogInWithGoogle()" [class.mat-elevation-z2]="true">
          <img src="assets/imgs/google-g.svg" alt="google" class="icon" /> {{ 'auth.logInWithGoogle' | translate }}
        </button>
      </div>
    </header>

    <div class="spr-cnt-form">
      <app-forgot *ngIf="isForgotPass"></app-forgot>
      <app-login *ngIf="isLogIn" (sendForgotPassword)="receiveForgotPassword()"></app-login>
      <app-register *ngIf="isSignUp"></app-register>
    </div>

    <p class="caption" *ngIf="!isForgotPass">{{ 'auth.terms' | translate }}</p>
  </div>
</div>
