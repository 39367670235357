import { Store } from '@ngrx/store';
import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { LoadUserAction } from './store/auth/auth.actions';
import { AuthDataState } from './store/auth/auth.reducer';
import { wodenConsole, wodenVersion } from '@shared/woden-text';
import { environment } from '@env';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(public matIconRegistry: MatIconRegistry, private store: Store<AuthDataState>) {
    matIconRegistry.registerFontClassAlias('fa');
  }

  ngOnInit() {
    this.store.dispatch(new LoadUserAction());
    console.log(`%c ${wodenConsole}`, 'color: #662d90');
    console.log(`%c ${wodenVersion(environment.version.split('.'))}`, 'color: #6cb9f0');
  }
}
