import { v4 as uuid } from 'uuid';
import { BaseModel } from './basemodel';
import { Plan } from './plan.model';

export class PlanSubscription implements BaseModel {
  id?: string;
  planId: string;
  maxDays: number;
  maxUsers: number;
  isActive: boolean;
  paidPrice: number;
  companyId: string;
  eventQty: number;
  maxInvites: number;
  addRegCost: number;
  maxRegistrants: number;
  subscriptionId: string;
  subscriptionDate: Date;
}

export function createSubscription(companyId: string, plan: Plan) {
  const id = uuid();
  const subscription = {
    id,
    companyId,
    isActive: true,
    planId: plan.id,
    paidPrice: plan.price * 100, //Stripe uses cents instead of dollars
    subscriptionDate: new Date(),
    maxDays: plan.maxDays,
    maxUsers: plan.maxUsers,
    eventQty: plan.eventQty,
    addRegCost: plan.addRegCost,
    maxInvites: plan.maxInvites,
    maxRegistrants: plan.maxRegistrants
  };

  return subscription;
}
