import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { CompanyReducer, myCompanySelector } from './company.firestore.reducer';
import { filter, combineLatest, switchMap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { PlanDataState, demoPlanSelector } from '@store/plans/plan.firestore.reducer';
import { createSubscription } from '@core/models/plan-subscription';
import { PlanSubscriptionReducer } from '@store/plan-subscription/plan-subscription.firestore.reducer';
import { UpsertDataAction, ModifyDataAction } from '@store/firestore/firestore.actions';
import { merge, of } from 'rxjs';

@Injectable()
export class CompaniesEffects {
  constructor(private actions$: Actions, private store: Store<PlanDataState>) {}

  //Creates subscription if logged company has no subscriptionId defined
  @Effect()
  companyLoaded$ = this.actions$.pipe(
    filter(action =>
      [CompanyReducer.getInstance().ADDED_ACTION, CompanyReducer.getInstance().MODIFIED_ACTION].includes(action.type)
    ),
    combineLatest(this.store.pipe(select(demoPlanSelector)), this.store.pipe(select(myCompanySelector))),
    switchMap(([, plan, company]) => {
      if (!company || company['subscriptionId']) {
        return of({ type: 'no need to create demo subscription or no company received' });
      }

      const demoSubscription = createSubscription(company.id, plan);
      const upsertActionType = PlanSubscriptionReducer.getInstance().UPSERT_ACTION;

      const modifyActionType = CompanyReducer.getInstance().MODIFY_ACTION;

      const innerCompany = { ...company };
      innerCompany.subscriptionId = demoSubscription.id;

      return merge(
        of(new UpsertDataAction(upsertActionType, demoSubscription)),
        of(new ModifyDataAction(modifyActionType, innerCompany))
      );
    })
  );
}
