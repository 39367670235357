export enum CreateShowActionsType {
  ADD_SHOW = '[Create Show] Add show',
  ADD_POSTER_TO_SHOW = '[Create Show] Add poster to show',
  ADD_TICKET = '[Create Show] Add ticket',
  EDIT_TICKET = '[Create Show] Edit ticket',
  EDITED_TICKET = '[Create Show] Edited ticket',
  EDITED_TICKET_CANCELED = '[Create Show] Edited ticket canceled',
  REMOVE_TICKET = '[Create Show] Remove ticket',
  SHOW_UPSERT_STARTED = '[Create Show] Show upsert started',
  SHOW_UPSERT_FINISHED = '[Create Show] Show upsert finished',
  ADD_REGISTRANT_LIST = '[Registrant List] Add registrant list',
  RESET_CREATE_SHOW_DATA = '[Registrant List] Reset Create show data',
  DELETE_REGISTRANT_LIST = '[Registrant List] Delete registrant list',
  UNASSIGN_REGISTRANT_LIST = '[Registrant List] Unassign registrant list from ticket',
  ASSIGN_TICKET_REGISTRANT_LIST = '[Registrant List] Assign registrant list to ticket'
}
