import { FirestoreReduxListener } from '@store/firestore/firestore.redux.listener';
import { PlanSubscription } from '@core/models/plan-subscription';
import { PlanSubscriptionDataState, PlanSubscriptionReducer } from './plan-subscription.firestore.reducer';
import { AngularFirestore, QueryFn } from '@angular/fire/firestore';
import { Store } from '@ngrx/store';

export class PlanSubscriptionFirestoreReduxListener extends FirestoreReduxListener<
  PlanSubscription,
  PlanSubscriptionDataState
> {
  constructor(db: AngularFirestore, store: Store<PlanSubscriptionDataState>, queryFn?: QueryFn) {
    super(db, store, PlanSubscriptionReducer.getInstance());

    this.initializeCollectionWithQuery({ queryFn });
    this.startListening();
  }
}
