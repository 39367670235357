import { Store } from '@ngrx/store';
import { Show } from '@models/show';
import { ShowDataState, ShowReducer } from '@store/shows/show.firestore.reducer';
import { AngularFirestore, QueryFn } from '@angular/fire/firestore';
import { FirestoreReduxListener } from '@store/firestore/firestore.redux.listener';

export class ShowFirestoreReduxListener extends FirestoreReduxListener<Show, ShowDataState> {
  constructor(db: AngularFirestore, store: Store<ShowDataState>, queryFn?: QueryFn) {
    super(db, store, ShowReducer.getInstance());

    this.initializeCollectionWithQuery({ queryFn });
    this.startListening();
  }
}
