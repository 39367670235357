export const userInvitationEmail = `
<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml" lang="en" xml:lang="en" style="background:0 0!important">
<head>
  <link href="https://fonts.googleapis.com/css?family=Montserrat:300,800,900" rel="stylesheet">
  <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
  <meta name="viewport" content="width=device-width"><title>Invitation</title>
</head>
<body>
    <table style="font-family:Arial,Helvetica,sans-serif; height:367px; width:758px">
        <tbody>
            <tr>
                <td style="height:51px; text-align:center; width:637px;background-color:#354b59">
                    <img alt="meet and greet title" src="https://firebasestorage.googleapis.com/v0/b/woden-dev.appspot.com/o/emailTemplate%2Flogo_woden_check_in.png?alt=media&token=74c114f8-ef75-47c0-8046-c0c0b715958c"
                        style="width: 180px;">
                </td>
            </tr>
            <tr>
                <td style="text-align: center">
                    <span style="color:#2C404C">
                        <span style="font-size:20px">
                            <strong>&nbsp;Hi,</strong>
                        </span>
                    </span>
                    </p>
                    <p>
                        <span style="color:#2C404C">
                            <span style="font-size:12px">
                                You are invited to become a new user in Wōden.
                            </span>
                        </span>
                    </p>
                    <p>
                        <span style="color:#2C404C">
                            <span style="font-size:14px">
                                This is you username and password
                            </span>
                        </span>
                    </p>
                    <p>
                        <span style="color:#2C404C">
                            <span style="font-size:14px">
                                <strong>Username:
                                    <strong>
                                        @userName
                            </span>
                        </span>
                    </p>
                    <p>
                        <span style="color:#2C404C">
                            <span style="font-size:14px">
                                <strong>Password:
                                    <strong>
                                        @password
                            </span>
                        </span>
                    </p>
                    <p>
                        <span style="color:#2C404C">
                            <span style="font-size:14px">
                                <a href="https://admin.wodenevents.com/">click here and enjoy with us</a>
                            </span>
                        </span>
                    </p>
                </td>
            </tr>
            <tr>
                <td style="height:51px; text-align:center; width:637px;background-color:#354b59">
                </td>
            </tr>
        </tbody>
    </table>
</body>
</html>`;
