import { FormControl, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';

import { limits } from '../constants';

export class FormsControl {
  static UNIQUE_SEPARATOR = '@#_$';

  private static INT_REGEX = /^\d+$/;
  private static PIN_REGEX = /^\d{4}$/;
  private static PHONE_REGEX = /^\+?\d{7,15}$/;
  private static EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  static forOptional() {
    return new FormControl('');
  }

  static forPin() {
    return new FormControl('', [Validators.required, Validators.pattern(this.PIN_REGEX)]);
  }

  static forPhone() {
    return new FormControl('', [Validators.required, Validators.pattern(this.PHONE_REGEX)]);
  }

  static forFullName() {
    return new FormControl('', [Validators.required, Validators.minLength(limits.FULL_NAME)]);
  }

  static forEmail() {
    return new FormControl('', [Validators.required, Validators.pattern(this.EMAIL_REGEX)]);
  }

  static forPassword() {
    return new FormControl('', [Validators.required, Validators.minLength(limits.PASSWORD)]);
  }

  static forHours() {
    return new FormControl('', [
      Validators.required,
      Validators.min(limits.HOURS_MIN),
      Validators.max(limits.HOURS_MAX)
    ]);
  }

  static forRequired() {
    return new FormControl('', [Validators.required]);
  }

  static forQuantity() {
    return new FormControl('', [
      Validators.required,
      Validators.min(1),
      // Validators.max(1000000),
      Validators.pattern(this.INT_REGEX)
    ]);
  }

  static forMoney() {
    return new FormControl('', [
      Validators.required,
      Validators.min(0),
      // Validators.max(1000000),
      Validators.pattern(this.INT_REGEX)
    ]);
  }

  static forUnique(otherControl: FormControl) {
    const validatorFn: ValidatorFn = (thisControl: AbstractControl): ValidationErrors | null => {
      const value = otherControl.value;
      const otherValues = value ? (value as string).split(this.UNIQUE_SEPARATOR) : [];

      if (otherValues.includes(thisControl.value)) {
        return { notUniqueFormControl: true };
      }

      return null;
    };

    return new FormControl('', [Validators.required, validatorFn]);
  }

  static forEquals(otherControl: FormControl) {
    const validatorFn: ValidatorFn = (thisControl: AbstractControl): ValidationErrors | null => {
      if (thisControl.value !== otherControl.value) {
        return { notEqualsFormControl: true };
      }

      return null;
    };

    return new FormControl('', [Validators.required, validatorFn]);
  }

  static forGreaterThan(otherControl: FormControl) {
    const validatorFn: ValidatorFn = (thisControl: AbstractControl): ValidationErrors | null => {
      if (thisControl.value <= otherControl.value) {
        return { notGreatherThan: { value: otherControl.value } };
      }

      return null;
    };

    return new FormControl('', [Validators.required, validatorFn]);
  }
}
