import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-toggle-type-ipt',
  templateUrl: './toggle-type-ipt.component.html',
  styleUrls: ['./toggle-type-ipt.component.scss']
})
export class ToggleTypeIptComponent {
  @Input()
  isVisible = false;

  @Output()
  isVisibleChange = new EventEmitter<boolean>();

  onChangeType(): void {
    this.isVisible = !this.isVisible;
    this.isVisibleChange.emit(this.isVisible);
  }
}
