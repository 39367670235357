import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '@env';
import { ChikettoImage } from '@models/chiketto-image';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface IChikettoImageResponse {
  data: ChikettoImage[];
  isError: boolean;
  meta?: { nextQuery: string };
}

@Injectable({
  providedIn: 'root'
})
export class ChikettoApiService {
  private HEADER_APP_JSON = new HttpHeaders({
    'Content-Type': 'application/json',
    authorization: `APIKey ${environment.chiketto.apiKey}`
  });

  constructor(private httpClient: HttpClient) {}

  getImages(companyId: string, pageQuery?: string, limit = 30): Observable<IChikettoImageResponse> {
    const fromObject: { 'subIds[]': string; page?: string; limit?: string } = { 'subIds[]': companyId };

    if (pageQuery) {
      fromObject.page = pageQuery;
    }

    if (limit) {
      fromObject.limit = `${limit}`;
    }

    const params = new HttpParams({ fromObject });

    return this.httpClient
      .get(environment.chiketto.chikettoPostImageUrl, { headers: this.HEADER_APP_JSON, params })
      .pipe(map(response => response as IChikettoImageResponse));
  }
}
