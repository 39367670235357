export enum AuthActionsType {
  LOGOUT = '[Auth] Logout',
  AUTH_ERROR = '[Auth] Auth Error',
  REGISTER = '[Auth] Register user',
  LOAD_USER_SELF = '[Auth] Load user',
  USER_SELF_LOADED = '[Auth] User loaded',
  CHANGE_PASSWORD = '[Auth] Change password',
  PASSWORD_CHANGED = '[Auth] Password changed',
  RECOVER_PASSWORD = '[Auth] Recover password',
  LOGIN_WITH_GOOGLE = '[Auth] Login with google',
  PASSWORD_RECOVERED = '[Auth] Password Recovered',
  LOGIN_WITH_CREDENTIALS = '[Auth] Login with credentials',
  SET_URL_FOR_REDIRECT = '[Auth] Set url for redirect after autentication'
}
