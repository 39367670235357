import { FirestoreSubCollectionReducer } from '@store/firestore/firestore-subcollection.reducer';
import { FirestoreReducerDataState } from '@store/firestore/firestore.reducer';
import {
  FIRESTORE_REGISTRANTS_SUBCOLLECTION_EVENT_MAIL_LIST,
  FIRESTORE_COLLECTION_REGISTRANTS
} from '@store/firestore/allFirestoreCollections';
import { EventMail } from '@core/models/event-mail';

export interface RegistrantEventMailListDataState extends FirestoreReducerDataState<EventMail> {}

export class RegistrantEventMailListReducer extends FirestoreSubCollectionReducer<
  EventMail,
  RegistrantEventMailListDataState
> {
  private static instance: RegistrantEventMailListReducer;

  parentCollectionName = FIRESTORE_COLLECTION_REGISTRANTS;
  collectionName = FIRESTORE_REGISTRANTS_SUBCOLLECTION_EVENT_MAIL_LIST;
  AUTO_SET_INNER_COLLECTION_ID = false;

  ADD_ACTION = '[EventMailList] Add eventMailList';
  UPSERT_ACTION = '[EventMailList] Upsert eventMailList';
  DELETE_ACTION = '[EventMailList] Delete eventMailList';
  MODIFY_ACTION = '[EventMailList] Modify eventMailList';

  ADDED_ACTION = '[EventMailList] Added eventMailList from firebase';
  DELETED_ACTION = '[EventMailList] Deleted eventMailList from firebase';
  MODIFIED_ACTION = '[EventMailList] Modified eventMailList from firebase';
  UPSERTED_ACTION = '[EventMailList] Upserted eventMailList firebase';
  LAST_DOCUMENT_SNAPSHOT = '[EventMailList] Last documentSnapshot from firebase';
  MODIFIED_LOCALLY_ACTION = '[EventMailList] Modify locally eventMailList';

  static getInstance() {
    if (!RegistrantEventMailListReducer.instance) {
      RegistrantEventMailListReducer.instance = new RegistrantEventMailListReducer();
    }
    return RegistrantEventMailListReducer.instance;
  }

  initializeFieldsToRemove(eventMail: EventMail): EventMail {
    eventMail.status = [];
    return eventMail;
  }
}
