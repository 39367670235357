import { User } from '@models/user';
import { Action } from '@ngrx/store';
import { AuthActionsType } from './auth.actions.name';

export type AuthAction =
  | LoadUserAction
  | LoginWithGoogleAction
  | LoginWithCredentialsAction
  | SetUrlForRedirectAction
  | UserLoadedAction
  | RecoverPasswordAction
  | RegisterAction
  | LoadUserAction
  | LoginWithGoogleAction
  | LoginWithCredentialsAction
  | AuthErrorAction
  | UserLoadedAction
  | ChangePasswordAction
  | PasswordChangedAction
  | PasswordRecoveredAction;

export class LogoutAction implements Action {
  readonly type = AuthActionsType.LOGOUT;
}

export class RecoverPasswordAction implements Action {
  readonly type = AuthActionsType.RECOVER_PASSWORD;
  constructor(public email: string) {}
}
export class RegisterAction implements Action {
  readonly type = AuthActionsType.REGISTER;
  constructor(public email: string, public password: string, public fullName: string) {}
}

export class LoadUserAction implements Action {
  readonly type = AuthActionsType.LOAD_USER_SELF;
}

export class LoginWithGoogleAction implements Action {
  readonly type = AuthActionsType.LOGIN_WITH_GOOGLE;
}

export class LoginWithCredentialsAction implements Action {
  readonly type = AuthActionsType.LOGIN_WITH_CREDENTIALS;

  constructor(public email: string, public password: string) {}
}

export class SetUrlForRedirectAction implements Action {
  readonly type = AuthActionsType.SET_URL_FOR_REDIRECT;

  constructor(readonly url: string) {}
}

// Effects Responses

export class AuthErrorAction implements Action {
  readonly type = AuthActionsType.AUTH_ERROR;

  constructor(public errorCode: string) {}
}

export class UserLoadedAction implements Action {
  readonly type = AuthActionsType.USER_SELF_LOADED;

  constructor(public user: User) {}
}

export class ChangePasswordAction implements Action {
  readonly type = AuthActionsType.CHANGE_PASSWORD;
  constructor(public password: string) {}
}

export class PasswordChangedAction implements Action {
  readonly type = AuthActionsType.PASSWORD_CHANGED;
}

export class PasswordRecoveredAction implements Action {
  readonly type = AuthActionsType.PASSWORD_RECOVERED;
}
