<form [formGroup]="myForm" (ngSubmit)="onReset()" class="cnt-form ctn-login" *ngIf="!emailSent">
  <div class="cnt-form-ipt">
    <div class="cnt-ipt" [ngClass]="getClassesForIpt(emailFormCtrl)">
      <span class="wi-mail ipt-icon"></span>
      <input
        type="email"
        placeholder="{{ 'auth.email' | translate }}"
        class="form-ipt-full"
        formControlName="emailFormCtrl"
      />
    </div>
    <span class="form-error" *ngIf="iptHasErrors(emailFormCtrl)">{{ 'errors.required' | translate }}</span>
    <span class="form-error" *ngIf="iptHasErrors(emailFormCtrl, 'pattern')">{{ 'errors.email' | translate }}</span>
  </div>
  <button
    type="submit"
    class="btn-rounded"
    [ngClass]="{ 'btn-blue': myForm.valid, 'btn-grey-disabled': myForm.invalid }"
    [disabled]="myForm.invalid"
    *ngIf="!loading"
  >
    {{ 'auth.sendResetLink' | translate }}
  </button>
  <mat-spinner diameter="50" *ngIf="loading"></mat-spinner>
</form>

<div class="cnt-email-sent" *ngIf="emailSent">
  <img src="assets/imgs/empty-states/mail.png" alt="mail" class="mail" />
  <p class="description">{{ 'auth.checkYourEmail' | translate }}</p>
</div>
