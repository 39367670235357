// import { Observable } from 'rxjs';
// import { Action } from '@ngrx/store';
// import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
// import { ShowReducer } from '@store/shows/show.firestore.reducer';
// import { Actions, Effect, ofType } from '@ngrx/effects';
// import { FirestoreReduxService } from '../../services/firebase/firestore-redux.service';
// import { createEqualsQueryAction, AddedDataAction } from '@store/firestore/firestore.actions';

@Injectable()
export class ShowEffects {
  // constructor(private actions$: Actions /* , private firestoreReduxService: FirestoreReduxService */) {}
  /**This effect is in charge to start listening for all our events registrants so we can make aggregate calculations */
  // @Effect({ dispatch: false })
  // eventAdded$: Observable<Action> = this.actions$.pipe(
  //   ofType(ShowReducer.getInstance().ADDED_ACTION)
  // ,
  // tap((action: AddedDataAction) => {
  // const eventId = createEqualsQueryAction('eventId', action.id);
  // this.firestoreReduxService.startTicketFirestoreReduxListener([eventId]);
  // this.firestoreReduxService.startRegistrantFirestoreReduxListener([eventId]);
  // })
  // );
}
