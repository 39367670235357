import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  loadImageGallery,
  loadImageGallerySuccess,
  loadImageGalleryFailure,
  noEventImageGallery
} from './image-gallery.actions';
import { mergeMap, map, withLatestFrom } from 'rxjs/operators';
import { ChikettoApiService } from '@services/chiketto/chiketto-api.service';
import { Store } from '@ngrx/store';
import { AppState } from '..';
import { imageGalleryDataState } from '.';
import { of } from 'rxjs';

@Injectable()
export class ImageGalleryEffects {
  loadImages$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadImageGallery),
      withLatestFrom(this.store.select(imageGalleryDataState)),
      mergeMap(([action, imageGalleryState]) => {
        const { nextQuery, isThereMoreToLoad } = imageGalleryState;

        if (!isThereMoreToLoad) {
          return of(noEventImageGallery());
        }
        return this.chikettoApiService.getImages(action.companyId, nextQuery, 10).pipe(
          map(response => {
            const { isError, meta, data } = response;
            if (isError) {
              return loadImageGalleryFailure({ error: 'Could not load chiketto images' });
            }

            return loadImageGallerySuccess({ chikettoImages: data, nextQuery: meta.nextQuery });
          })
        );
      })
    );
  });

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private chikettoApiService: ChikettoApiService
  ) {}
}
