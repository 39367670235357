import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from '@shared/shared.module';
import { AuthRoutingModule } from './auth-routing.module';
import { AuthComponent } from './auth.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ForgotComponent } from './forgot/forgot.component';
import { ChangePasswordComponent } from './change-password/change-password.component';

@NgModule({
  imports: [AuthRoutingModule, CommonModule, SharedModule, FormsModule, ReactiveFormsModule],
  declarations: [AuthComponent, LoginComponent, RegisterComponent, ForgotComponent, ChangePasswordComponent],
  exports: [AuthComponent, LoginComponent, RegisterComponent, ForgotComponent]
})
export class AuthModule {}
