import { FirestoreReduxListener } from '@store/firestore/firestore.redux.listener';
import { PublicInfo } from '@core/models/public-info';
import { PublicInfoDataState, PublicInfoReducer } from './public-info.firestore.reducer';
import { Store } from '@ngrx/store';
import { QueryFn, AngularFirestore } from '@angular/fire/firestore';

export class PublicInfoFirestoreReduxListener extends FirestoreReduxListener<PublicInfo, PublicInfoDataState> {
  constructor(db: AngularFirestore, store: Store<PublicInfoDataState>, queryFn?: QueryFn) {
    super(db, store, PublicInfoReducer.getInstance());

    this.initializeCollectionWithQuery({ queryFn });
    this.startListening();
  }
}
