import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { buildQueryFn } from '../../shared/helpers';
import { AngularFirestore, QueryFn } from '@angular/fire/firestore';
import { FilterAction, createStartAfterAction } from '../../store/firestore/firestore.actions';
import { UserFirestoreReduxListener } from '@store/users/user.firestore.redux.listener';
import { ShowFirestoreReduxListener } from '@store/shows/show.firestore.redux.listener';
import { RegistrantFirestoreReduxListener } from '@store/registrants/registrant.firestore.redux.listener';
import { CompanyFirestoreReduxListener } from '@store/companies/company.firestore.redux.listener';
import { TicketFirestoreReduxListener } from '@store/tickets/tickets.firestore.redux.listener';
import { FirestoreReduxListener } from '@store/firestore/firestore.redux.listener';
import { BaseModel } from '@core/models/basemodel';
import { FirestoreReducerDataState } from '@store/firestore/firestore.reducer';
import { PlanSubscriptionFirestoreReduxListener } from '@store/plan-subscription/plan-subscription.firestore.redux.listener';
import { RegistrantEventMailListFirestoreReduxListener } from '@store/registrants/registrant-event-mail-list.firestore-subcollection.redux-listener';
import { RegistrantEventMailStatusFirestoreReduxListener } from '@store/registrants/registrant-event-mail-status.firestore-subcollection.redux-listener';
import { PlanFirestoreReduxListener } from '@store/plans/plan.firestose.redux.listener';
import { PublicInfoFirestoreReduxListener } from '@store/public-info/public-info.redux.listener';
import { ImageFirestoreReduxListener } from '@store/images/image.firestore.redux.listener';
import { Registrant, Show, User, Company, Ticket, PlanSubscription, Plan } from '@core/models';
import { RegistrantDataState } from '@store/registrants/registrant.firestore.reducer';
import { ShowDataState } from '@store/shows/show.firestore.reducer';
import { UserDataState } from '@store/users/user.firestore.reducer';
import { CompanyDataState } from '@store/companies/company.firestore.reducer';
import { TicketDataState } from '@store/tickets/tickets.firestore.reducer';
import { PlanSubscriptionDataState } from '@store/plan-subscription/plan-subscription.firestore.reducer';
import { PlanDataState } from '@store/plans/plan.firestore.reducer';
import { RegistrantEventMailListDataState } from '@store/registrants/registrant-event-mail-list.firestore-subcollection.reducer';
import { PublicInfoDataState } from '@store/public-info';
import { PublicInfo } from '@core/models/public-info';
import { ImageDataState } from '@store/images/image.firebase.reducer';
import { Image } from '@core/models/image';
import { AttendedRegistrantFirestoreReduxListener } from '@store/registrants/attended-registrants/attended-registrants.firestore.redux.listener';
import { AttendedRegistrantDataState } from '@store/registrants/attended-registrants/attended-registrants.firestore.reducer';

@Injectable({
  providedIn: 'root'
})

/** Service used to create Firestore Redux Listeners and start listening to events Firestore events */

/**This functions must be called when we have the needed data to query fierstore collections.
 * e.g When the user is logged in we can start listening to Events collection changes from my companyId  */
export class FirestoreReduxService {
  constructor(private db: AngularFirestore, private store: Store<any>) {}

  private allReduxListeners: Map<
    string,
    FirestoreReduxListener<BaseModel, FirestoreReducerDataState<BaseModel>>
  > = new Map();

  buildUserByCompanyFirestoreReduxListenerId(companyId: string): string {
    return `users_by_company_${companyId}`;
  }

  startUserFirestoreReduxListener(operations: FilterAction[], companyId: string): UserFirestoreReduxListener {
    const id = this.buildUserByCompanyFirestoreReduxListenerId(companyId);
    return this.startFirestoreReduxListener<User, UserDataState, UserFirestoreReduxListener>(
      operations,
      id,
      (db, store, queryFn) => new UserFirestoreReduxListener(db, store, queryFn)
    );
  }

  stopUserFirestoreReduxListener(companyId: string) {
    const id = this.buildUserByCompanyFirestoreReduxListenerId(companyId);
    this.unsubscribeListener(id);
  }

  buildShowByCompanyFirestoreReduxListenerId(companyId: string): string {
    return `shows_by_company_${companyId}`;
  }

  startShowFirestoreReduxListener(operations: FilterAction[], companyId: string): ShowFirestoreReduxListener {
    const id = this.buildShowByCompanyFirestoreReduxListenerId(companyId);
    return this.startFirestoreReduxListener<Show, ShowDataState, ShowFirestoreReduxListener>(
      operations,
      id,
      (db, store, queryFn) => new ShowFirestoreReduxListener(db, store, queryFn)
    );
  }

  stopShowFirestoreReduxListener(companyId: string) {
    const id = this.buildShowByCompanyFirestoreReduxListenerId(companyId);
    this.unsubscribeListener(id);
  }

  buildRegistrantByShowFirestoreReduxListenerId(showId: string, registrantKind?: string): string {
    return `registrants_by_show_${showId}`.concat(!!registrantKind ? `_${registrantKind}` : '');
  }

  startRegistrantFirestoreReduxListener(
    operations: FilterAction[],
    showId: string,
    registrantKind?: string
  ): RegistrantFirestoreReduxListener {
    const id = this.buildRegistrantByShowFirestoreReduxListenerId(showId, registrantKind);
    return this.startFirestoreReduxListener<Registrant, RegistrantDataState, RegistrantFirestoreReduxListener>(
      operations,
      id,
      (db, store, queryFn) => new RegistrantFirestoreReduxListener(db, store, queryFn)
    );
  }

  startAttendedRegistrantFirestoreReduxListener(
    operations: FilterAction[],
    showId: string
  ): RegistrantFirestoreReduxListener {
    const id = this.buildRegistrantByShowFirestoreReduxListenerId(showId, 'attended');
    return this.startFirestoreReduxListener<
      Registrant,
      AttendedRegistrantDataState,
      AttendedRegistrantFirestoreReduxListener
    >(operations, id, (db, store, queryFn) => new AttendedRegistrantFirestoreReduxListener(db, store, queryFn));
  }

  stopRegistrantFirestoreReduxListener(showId: string, registrantKind?: string) {
    const id = this.buildRegistrantByShowFirestoreReduxListenerId(showId, registrantKind);
    this.unsubscribeAllListenersBeginningWith(id);
    //Used to unsubscribe from email and email list events
    this.unsubscribeAllListenersBeginningWith(`registrants/`);
  }

  buildCompanyByCompanyIdFirestoreReduxListenerId(companyId: string): string {
    return `company_by_companyId_${companyId}`;
  }

  startCompanyFirestoreReduxListener(operations: FilterAction[], companyId: string): CompanyFirestoreReduxListener {
    const id = this.buildCompanyByCompanyIdFirestoreReduxListenerId(companyId);
    return this.startFirestoreReduxListener<Company, CompanyDataState, CompanyFirestoreReduxListener>(
      operations,
      id,
      (db, store, queryFn) => new CompanyFirestoreReduxListener(db, store, queryFn)
    );
  }

  stopCompanyFirestoreReduxListener(companyId: string) {
    const id = this.buildCompanyByCompanyIdFirestoreReduxListenerId(companyId);
    this.unsubscribeListener(id);
  }

  buildTicketByShowFirestoreReduxListenerId(showId: string): string {
    return `tickets_by_show_${showId}`;
  }

  startTicketFirestoreReduxListener(operations: FilterAction[], showId: string): TicketFirestoreReduxListener {
    const id = this.buildTicketByShowFirestoreReduxListenerId(showId);
    return this.startFirestoreReduxListener<Ticket, TicketDataState, TicketFirestoreReduxListener>(
      operations,
      id,
      (db, store, queryFn) => new TicketFirestoreReduxListener(db, store, queryFn)
    );
  }

  stopTicketFirestoreReduxListener(showId: string) {
    const id = this.buildTicketByShowFirestoreReduxListenerId(showId);
    this.unsubscribeAllListenersBeginningWith(id);
  }

  buildSubscriptionByCompanyFirestoreReduxListenerId(companyId: string): string {
    return `subscriptioin_by_company_${companyId}`;
  }

  startSubscriptionFirestoreReduxListener(
    operations: FilterAction[],
    companyId: string
  ): PlanSubscriptionFirestoreReduxListener {
    const id = this.buildSubscriptionByCompanyFirestoreReduxListenerId(companyId);
    return this.startFirestoreReduxListener<
      PlanSubscription,
      PlanSubscriptionDataState,
      PlanSubscriptionFirestoreReduxListener
    >(operations, id, (db, store, queryFn) => new PlanSubscriptionFirestoreReduxListener(db, store, queryFn));
  }

  stopSubscriptionFirestoreReduxListener(companyId: string) {
    const id = this.buildSubscriptionByCompanyFirestoreReduxListenerId(companyId);
    this.unsubscribeListener(id);
  }

  startPlanFirestoreReduxListener(operations: FilterAction[]): PlanFirestoreReduxListener {
    const id = 'plans';
    return this.startFirestoreReduxListener<Plan, PlanDataState, PlanFirestoreReduxListener>(
      operations,
      id,
      (db, store, queryFn) => new PlanFirestoreReduxListener(db, store, queryFn)
    );
  }

  startRegistrantEventMailListFirestoreReduxListener(
    ancestorsId: string,
    operations: FilterAction[],
    requestNewPage = true
  ): RegistrantEventMailListFirestoreReduxListener {
    const id = ancestorsId;
    return this.startFirestoreReduxListener<
      BaseModel,
      RegistrantEventMailListDataState,
      RegistrantEventMailListFirestoreReduxListener
    >(
      operations,
      id,
      (db, store, queryFn) => new RegistrantEventMailListFirestoreReduxListener(db, store, { ancestorsId, queryFn }),
      requestNewPage
    );
  }

  startRegistrantEventMailStatusFirestoreReduxListener(
    ancestorsId: string,
    operations: FilterAction[]
  ): RegistrantEventMailStatusFirestoreReduxListener {
    const queryFn = buildQueryFn(operations);
    const registrantEventMailStatusFirestoreReduxListener = new RegistrantEventMailStatusFirestoreReduxListener(
      this.db,
      this.store,
      { ancestorsId, queryFn }
    );

    this.allReduxListeners[ancestorsId] = registrantEventMailStatusFirestoreReduxListener;

    return registrantEventMailStatusFirestoreReduxListener;
  }

  buildPublicInfoByShowFirestoreReduxListenerId(showId: string): string {
    return `public_info_by_show_${showId}`;
  }

  startPublicInfoByShowFirestoreReduxListener(
    operations: FilterAction[],
    showId: string
  ): PublicInfoFirestoreReduxListener {
    const id = this.buildPublicInfoByShowFirestoreReduxListenerId(showId);
    return this.startFirestoreReduxListener<PublicInfo, PublicInfoDataState, PublicInfoFirestoreReduxListener>(
      operations,
      id,
      (db, store, queryFn) => new PublicInfoFirestoreReduxListener(db, store, queryFn)
    );
  }

  stopPublicInfoByShowFirestoreReduxListener(showId: string) {
    const id = this.buildPublicInfoByShowFirestoreReduxListenerId(showId);
    this.unsubscribeListener(id);
  }

  buildPublicInfoByCompanyFirestoreReduxListenerId(companyId: string): string {
    return `public_info_by_company_${companyId}`;
  }

  startPublicInfoByCompanyFirestoreReduxListener(
    operations: FilterAction[],
    companyId: string
  ): PublicInfoFirestoreReduxListener {
    const id = this.buildPublicInfoByCompanyFirestoreReduxListenerId(companyId);
    return this.startFirestoreReduxListener<PublicInfo, PublicInfoDataState, PublicInfoFirestoreReduxListener>(
      operations,
      id,
      (db, store, queryFn) => new PublicInfoFirestoreReduxListener(db, store, queryFn)
    );
  }

  stopPublicInfoByCompanyFirestoreReduxListener(companyId: string) {
    const id = this.buildPublicInfoByCompanyFirestoreReduxListenerId(companyId);
    this.unsubscribeListener(id);
  }

  buildImageByCompanyFirestoreReduxListenerId(companyId: string): string {
    return `image_by_company_${companyId}`;
  }

  startImageByCompanyFirestoreReduxListener(
    operations: FilterAction[],
    companyId: string
  ): ImageFirestoreReduxListener {
    const id = this.buildImageByCompanyFirestoreReduxListenerId(companyId);
    return this.startFirestoreReduxListener<Image, ImageDataState, ImageFirestoreReduxListener>(
      operations,
      id,
      (db, store, queryFn) => new ImageFirestoreReduxListener(db, store, queryFn)
    );
  }

  stopImageByCompanyFirestoreReduxListener(companyId: string) {
    const id = this.buildImageByCompanyFirestoreReduxListenerId(companyId);
    this.unsubscribeListener(id);
  }

  createNewListenerId(id: string) {
    const actualListeners = this.listenersStartingWith(id);
    const newPage = actualListeners.length + 1;
    const newId = `${id}_${newPage}`;
    return newId;
  }

  private startFirestoreReduxListener<
    M extends BaseModel,
    D extends FirestoreReducerDataState<M>,
    T extends FirestoreReduxListener<M, D>
  >(
    operations: FilterAction[],
    originalId: string,
    reduxListenerCreator: (db: AngularFirestore, store: Store<D>, queryFn: QueryFn) => T,
    requestNewPage = true
  ) {
    const listenersStartingWith = this.listenersStartingWith(originalId);

    const actualListenersSize = listenersStartingWith.length;
    const finalOperations = [...operations];
    if (requestNewPage && actualListenersSize > 0) {
      const lastListener = listenersStartingWith[actualListenersSize - 1];
      const lastDocumentSnapshot = lastListener.getLastDocumentSnapshot();

      if (lastDocumentSnapshot) {
        const startAfterAction = createStartAfterAction(lastDocumentSnapshot);
        finalOperations.push(startAfterAction);
      }
    }

    const queryFn = buildQueryFn(finalOperations);
    const firestoreReduxListener = reduxListenerCreator(this.db, this.store, queryFn);

    const newId = this.createNewListenerId(originalId);

    this.allReduxListeners.set(newId, firestoreReduxListener);

    return firestoreReduxListener;
  }

  public existsListener(id: string) {
    return this.allReduxListeners.has(id);
  }

  public listenersStartingWith(idStart: string) {
    return Array.from(this.allReduxListeners.entries())
      .filter(([key]) => key.includes(idStart))
      .map(([, listener]) => listener);
  }

  public listenersIdStartingWith(idStart: string) {
    return Array.from(this.allReduxListeners.keys()).filter(key => key.includes(idStart));
  }

  public unsubscribeListener(id: string) {
    const listener = this.allReduxListeners.get(id);
    if (listener) {
      listener.removeReceivedDocumentsFromLocalStore();
      listener.stopListening();
      this.allReduxListeners.delete(id);
    } else {
      console.log(`Listener with id:`, id, ' does not exist');
    }
  }

  public unsubscribeAllListenersBeginningWith(id: string) {
    this.listenersIdStartingWith(id).forEach(listenerId => this.unsubscribeListener(listenerId));
  }

  public unsubscribeAll(): void {
    Array.from(this.allReduxListeners.keys()).forEach(listenerId => this.unsubscribeListener(listenerId));
    this.allReduxListeners.clear();
  }
}
