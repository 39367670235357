import { InviteUserActions } from './invite-user.actions';
import { InviteUserActionsType } from './invite-user.actions.name';

export interface InviteUserDataState {
  loading: boolean;
  inviteError: string;
}

export const initialState: InviteUserDataState = {
  loading: false,
  inviteError: ''
};

export function inviteUserDataReducer(state = initialState, action: InviteUserActions): InviteUserDataState {
  switch (action.type) {
    case InviteUserActionsType.INVITE_USER:
      return { ...state, loading: true };
    case InviteUserActionsType.USER_INVITED:
      return { ...state, loading: false };
    case InviteUserActionsType.INVITE_USER_ERROR:
      const error = action.error;
      return { ...state, inviteError: error, loading: false };
    default:
      return state;
  }
}
