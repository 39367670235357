<form [formGroup]="myForm" (ngSubmit)="onLogIn()" class="cnt-form ctn-login">
  <div class="cnt-form-ipt">
    <div class="cnt-ipt" [ngClass]="getClassesForIpt(emailFormCtrl)">
      <span class="wi-mail ipt-icon"></span>
      <input
        type="email"
        placeholder="{{ 'auth.email' | translate }}"
        class="form-ipt-full"
        formControlName="emailFormCtrl"
      />
    </div>
    <span class="form-error" *ngIf="iptHasErrors(emailFormCtrl)">{{ 'errors.required' | translate }}</span>
    <span class="form-error" *ngIf="iptHasErrors(emailFormCtrl, 'pattern')">{{ 'errors.email' | translate }}</span>
  </div>

  <div class="cnt-form-ipt">
    <div class="cnt-ipt" [ngClass]="getClassesForIpt(passwordFormCtrl)">
      <span class="wi-padlock ipt-icon"></span>
      <input
        [type]="isVisible ? 'text' : 'password'"
        name="password"
        placeholder="{{ 'password' | translate }}"
        class="form-ipt-full"
        formControlName="passwordFormCtrl"
      />
      <app-toggle-type-ipt [(isVisible)]="isVisible"></app-toggle-type-ipt>
    </div>
    <span class="form-error" *ngIf="iptHasErrors(passwordFormCtrl)">{{ 'errors.required' | translate }}</span>
    <span class="form-error" *ngIf="iptHasErrors(passwordFormCtrl, 'minlength')">{{
      'errors.minimum' | translate: passwordLimit
    }}</span>
  </div>

  <button type="button" class="btn-link-blue" (click)="onForgotPassword()">
    {{ 'auth.forgotPasswordTitle' | translate }}
  </button>

  <button
    type="submit"
    class="btn-rounded"
    [ngClass]="{ 'btn-blue': myForm.valid, 'btn-grey-disabled': myForm.invalid }"
    [disabled]="myForm.invalid"
  >
    {{ 'landing.logIn' | translate }}
  </button>

  <app-loading class="center-loading" *ngIf="loading"></app-loading>

  <a
    target="_blank"
    href="https://play.google.com/store/apps/details?id=com.woden.events.checkin&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
  >
    <img
      class="badge-google-play"
      alt="Get it on Google Play"
      src="https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png"
    />
  </a>
</form>
