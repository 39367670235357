/*
 * Insert item in array respecting inmutability principle .
 * array: Array of elements.
 * item: item to insert in array
 * position: position for item in array
 * acceptancePercent: Percent of coincidence to determine if an element is the same that other.
 *                    Should be a int [0, 100], default 80.
*/
export function insertInArray(array: any[], item: any, position: number = 0, acceptancePercent: number = 80): any[] {
  const matchingItem = array.filter(element => matchPercent(element, item) >= acceptancePercent)[0];
  const newArray = array.slice();
  if (matchingItem === undefined) {
    newArray.splice(position, 0, item);
    return newArray;
  }
  return newArray;
}

/*
 * Delete item in array respecting inmutability principle .
 * array: Array of elements.
 * itemToDelete: item to delete in array
*/
export function deleteInArray(array: any[], itemToDelete: any): any[] {
  const itemIndex = array.indexOf(itemToDelete);
  const newArray = array.slice();
  if (itemIndex !== -1) {
    newArray.splice(itemIndex, 1);
  }
  return newArray;
}

/*
 * Delete contiguous items in array respecting inmutability principle .
 * array: Array of elements.
 * itemToDelete: item to delete in array
*/
export function deleteContiguousInArray(array: any[], initialIndex: number, itemAmountToDelete: number): any[] {
  const newArray = array.slice();
  if (initialIndex !== -1) {
    newArray.splice(initialIndex, itemAmountToDelete);
  }
  return newArray;
}

/*
 * Update item in array respecting inmutability principle .
 * array: Array of elements.
 * newItem: Item to update in array.
 * key: item's key to match with the prev version of this one.
 *      By default it's a key 'id'.
*/
export function updateInArray(array: any[], newItem: any, key: any = 'id'): any[] {
  if (array.length === 0) {
    return [newItem];
  }

  return array.map(item => {
    if (item[key] !== newItem[key]) {
      return item;
    }

    return { ...item, ...newItem };
  });
}

/*
 * Compare two objects that have the same structure and determinate if is the same.
 * according with his num attributes and each value.
 * Example:
 *   const obj1 = { name:'Jhon', kind:'hero', age: 22 }
 *   const obj2 = { name:'Jhon', kind:'hero', age: 21 }
 *   matchPercent(obj1, obj2) >> 66
*/
export function matchPercent(itemA: any, itemB: any): number {
  const itemAType = typeof itemA;
  if (itemAType === 'string' || itemAType === 'number') {
    return itemA === itemB ? 100 : 0;
  }
  const itemAKeys = Object.keys(itemA);
  let counter = 0;
  itemAKeys.forEach(key => {
    if (itemB.hasOwnProperty(key) && itemA[key] === itemB[key]) {
      counter = counter++;
    }
  });
  if (itemAKeys.length === 0) {
    return 0;
  }
  const matchArray = Math.floor((counter / itemAKeys.length) * 100);
  const match = Math.max(matchArray) || 0;
  return match;
}
