import { FormControl, FormGroup } from '@angular/forms';

import { FormsControl } from './form-controls';
import { FormControlStructure } from '@core/models/form-control-structure';

interface InputControl {
  [key: string]: FormControl;
}

export class WodenFormBuilder {
  inputControls: InputControl;
  form: FormGroup;

  constructor(controls: FormControlStructure[]) {
    this.inputControls = {};
    this.createFormsControl(controls);
    this.form = new FormGroup(this.inputControls);
  }

  createFormsControl(controls: FormControlStructure[]): void {
    controls.forEach((control: FormControlStructure) => {
      const otherFormControlName = control.formControlName;
      const otherControl = this.inputControls[otherFormControlName];
      // if (!!control.formControlName && otherControl === null) {
      if (otherFormControlName && otherFormControlName.trim().length > 0 && !otherControl) {
        throw new Error(`We can't create this control, because the control to compare doesn't exist`);
      }
      this.inputControls[control.name] = FormsControl[control.validator](otherControl);
    });
  }

  getFormInstance(): FormGroup {
    return this.form;
  }

  getFormControls(): InputControl {
    return this.inputControls;
  }

  getControl(controlName: string): FormControl | undefined {
    const control = this.inputControls[controlName];
    return control;
  }
}
