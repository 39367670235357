import { Store } from '@ngrx/store';
import { Image } from '@models/image';
import { FirestoreReduxListener } from "@store/firestore/firestore.redux.listener";
import { ImageDataState, ImageReducer } from "./image.firebase.reducer";
import { AngularFirestore, QueryFn } from '@angular/fire/firestore';

export class ImageFirestoreReduxListener extends FirestoreReduxListener<Image, ImageDataState> {
    constructor(db: AngularFirestore, store: Store<ImageDataState>, queryFn?: QueryFn){
        super(db, store, ImageReducer.getInstance());

        this.initializeCollectionWithQuery({ queryFn });
        this.startListening();
    }
}