import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { takeUntil, delay } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { AuthDataState } from '@store/auth/auth.reducer';
import { LoginWithGoogleAction } from '@store/auth/auth.actions';
import { authDataState } from '@store/auth/';
import { SubscriptionComponent } from '@shared/components/subscription.component';
import { Dictionary } from '@core/types';

interface Slide {
  image: string;
  active: boolean;
  description: string;
  class?: string;
}

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent extends SubscriptionComponent {
  isForgotPass: boolean;
  isLogIn: boolean;
  isSignUp: boolean;
  slides: Slide[];
  currentSlide = 0;
  timerRef: any;

  constructor(
    private router: Router,
    private store: Store<AuthDataState>,
    private readonly translate: TranslateService
  ) {
    super();

    this.slides = [
      {
        image: 'assets/imgs/various/cms-integration.png',
        active: true,
        description: 'connectCms',
        class: 'cms-preview'
      },
      {
        image: 'assets/imgs/phones/registrant-list.png',
        active: false,
        description: 'manageBusiness'
      },
      {
        image: 'assets/imgs/phones/wallet-passes-cards.png',
        active: false,
        description: 'ticketing'
      }
    ];
  }

  ngOnInit(): void {
    this.translate.use('en');

    this.timerRef = setInterval(() => this.passSlide(), 4000);

    const { url } = this.router;
    switch (url) {
      case '/login':
        this.isLogIn = true;
        break;
      case '/register':
      case '/signup':
        this.isSignUp = true;
        break;
      case '/forgot-pass':
        this.isForgotPass = true;
        break;

      default:
        this.isLogIn = true;
        break;
    }

    this.store
      .pipe(
        takeUntil(this.stopSubscription),
        select(authDataState),
        delay(50)
      )
      .subscribe(({ isAuthenticated, redirectUrl }) => {
        if (isAuthenticated) {
          // TODO: analyze the url to extract param and make a correct redirect
          const toUrl = redirectUrl ? redirectUrl.substring(1, redirectUrl.length) : 'dashboard';
          this.router.navigateByUrl(toUrl);
        }
      });
  }

  ngOnDestroy() {
    clearInterval(this.timerRef);
  }

  activeClass(slideIndex: number): Dictionary {
    return { active: slideIndex === this.currentSlide };
  }

  slideClass(slideIndex: number): Dictionary {
    const currentSlide = this.currentSlide;

    let isPrev = slideIndex < currentSlide;
    const isCurrent = slideIndex === currentSlide;
    let isNext = slideIndex > currentSlide;

    if (slideIndex === 0 && currentSlide === 2) {
      isPrev = false;
      isNext = true;
    }

    if (slideIndex === 2 && currentSlide === 0) {
      isPrev = true;
      isNext = false;
    }

    return {
      prev: isPrev,
      current: isCurrent,
      next: isNext
    };
  }

  passSlide() {
    const currentSlide = this.currentSlide;
    this.currentSlide = currentSlide > 1 ? 0 : currentSlide + 1;
  }

  chooseSlide(slideIndex: number) {
    this.currentSlide = slideIndex;
    clearInterval(this.timerRef);
    setTimeout(() => (this.timerRef = setInterval(() => this.passSlide(), 4000)), 4000);
  }

  onLogInWithGoogle(): void {
    this.store.dispatch(new LoginWithGoogleAction());
  }

  get title(): Observable<string> {
    let title: Observable<string>;

    if (this.isLogIn) {
      title = this.translate.get('auth.logInTitle'); //'Log in to WODEN';
    } else if (this.isSignUp) {
      title = this.translate.get('auth.signUpTitle'); //'Great events start with WODEN';
    } else if (this.isForgotPass) {
      title = this.translate.get('auth.forgotPasswordTitle'); //'Forgot your password?';
    }

    return title;
  }

  get description(): Observable<string> {
    let desc: Observable<string>;

    if (this.isLogIn) {
      desc = this.translate.get('auth.logInDesc'); //'Don’t have an account?';
    }
    if (this.isSignUp) {
      desc = this.translate.get('auth.signUpDesc');
    }
    if (this.isForgotPass) {
      desc = this.translate.get('auth.forgotPasswordDesc');
    }

    return desc;
  }

  changeTo(flag: string): void {
    switch (flag) {
      case 'isLogIn':
        this.router.navigateByUrl('login');
        break;
      case 'isSignUp':
        this.router.navigateByUrl('signup');
        break;
      case 'isForgotPass':
        this.router.navigateByUrl('forgot-pass');
        break;

      default:
        break;
    }
  }

  receiveForgotPassword(): void {
    this.changeTo('isForgotPass');
  }
}
