import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { ModifiedDataAction, AddedDataAction } from '@store/firestore/firestore.actions';
import { RegistrantEventMailStatusReducer } from './registrant-event-mail-status.firestore-subcollection.reducer';
import { withLatestFrom, map } from 'rxjs/operators';
import { eventMailListDataState } from '.';
import { select, Store } from '@ngrx/store';
import { RegistrantEventMailListReducer } from './registrant-event-mail-list.firestore-subcollection.reducer';
import { EventMail } from '@core/models/event-mail';
import { AppState } from '..';
import { handleSubcollectionEffectAction } from '@store/firestore/firestore-subcollection-effect-helper';
import { Entities } from '@store/firestore/firestore.reducer';

@Injectable()
export class RegistrantEventMailStatusEffects {
  private reducer = RegistrantEventMailStatusReducer.getInstance();
  constructor(private actions$: Actions, private store: Store<AppState>) {}

  @Effect()
  addedModel$: Observable<ModifiedDataAction> = this.actions$.pipe(
    ofType(this.reducer.ADDED_ACTION),
    withLatestFrom(this.store.pipe(select(eventMailListDataState))),
    map(
      ([action, { eventMailList }]): ModifiedDataAction => {
        const innerAction = action as AddedDataAction;
        const actionType = RegistrantEventMailListReducer.getInstance().MODIFIED_LOCALLY_ACTION;

        const eventMailListEntities = eventMailList as Entities<EventMail>;

        return handleSubcollectionEffectAction<EventMail>(innerAction, actionType, eventMailListEntities, 'status');
      }
    )
  );
}
