import { FirestoreReduxListener } from '@store/firestore/firestore.redux.listener';
import { AngularFirestore } from '@angular/fire/firestore';
import { Store } from '@ngrx/store';
import { BaseModel } from '@core/models/basemodel';
import { FirestoreCollectionReference } from '@core/models/firestore-collection-reference';
import {
  RegistrantEventMailStatusDataState,
  RegistrantEventMailStatusReducer
} from './registrant-event-mail-status.firestore-subcollection.reducer';

export class RegistrantEventMailStatusFirestoreReduxListener extends FirestoreReduxListener<
  BaseModel,
  RegistrantEventMailStatusDataState
> {
  constructor(
    db: AngularFirestore,
    store: Store<RegistrantEventMailStatusDataState>,
    firestoreCollectionReference: FirestoreCollectionReference
  ) {
    super(db, store, RegistrantEventMailStatusReducer.getInstance());
    this.initializeCollectionWithQuery(firestoreCollectionReference);
    this.startListening();
  }
}
