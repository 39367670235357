import { FirestoreReducerDataState } from '@store/firestore/firestore.reducer';
import { FIRESTORE_COLLECTION_PUBLIC_INFO } from '@store/firestore/allFirestoreCollections';
import { FirestoreCollectionReducer } from '@store/firestore/firestore-collection.reducer';
import { PublicInfo } from '@core/models/public-info';
import { publicInfoDataState } from '.';

export interface PublicInfoDataState extends FirestoreReducerDataState<PublicInfo> {}

export class PublicInfoReducer extends FirestoreCollectionReducer<PublicInfo, PublicInfoDataState> {
  private static instance: PublicInfoReducer;

  featureSelector = publicInfoDataState;

  AUTO_SET_INNER_COLLECTION_ID = false;

  collectionName = FIRESTORE_COLLECTION_PUBLIC_INFO;

  ADD_ACTION = '[PublicInfo] Add public info';
  UPSERT_ACTION = '[PublicInfo] Upsert public info';
  DELETE_ACTION = '[PublicInfo] Delete public info';
  MODIFY_ACTION = '[PublicInfo] Modify public info';

  ADDED_ACTION = '[PublicInfo] Added public info from firebase';
  DELETED_ACTION = '[PublicInfo] Deleted public info from firebase';
  MODIFIED_ACTION = '[PublicInfo] Modified public info from firebase';
  UPSERTED_ACTION = '[PublicInfo] Upserted public info from firebase';
  LAST_DOCUMENT_SNAPSHOT = '[PublicInfo] Last documentSnapshot from firebase';
  MODIFIED_LOCALLY_ACTION = '[PublicInfo] Modify locally public info';

  static getInstance() {
    if (!PublicInfoReducer.instance) {
      PublicInfoReducer.instance = new PublicInfoReducer();
    }
    return PublicInfoReducer.instance;
  }
}
