export enum ShowDetailActionsType {
  LOAD_SHOW = '[ShowDetail] Load show',
  CLEAR_DATA = '[ShowDetail] Clear data',
  SHOW_LOADED = '[ShowDetail] Show loaded',
  LOAD_COMPANY = '[ShowDetail] Load company',
  COMPANY_LOADED = '[ShowDetail] Company loaded',
  LOAD_MAX_INVITES = '[ShowDetail] Load max invites',
  LOAD_REGISTRANTS = '[ShowDetail] Load registrants',
  REVENUES_UPDATED = '[ShowDetail] Revenues updated',
  LOAD_SHOW_TICKETS = '[ShowDetail] Load show tickets',
  ATTENDEES_UPDATED = '[ShowDetail] Attendees updated',
  FILTER_REGISTRANTS = '[ShowDetail] Filter registrants',
  TABLE_PAGE_CHANGED = '[ShowDetail] Table page changed',
  MAX_INVITES_LOADED = '[ShowDetail] Max invites loaded',
  SEARCH_REGISTRANTS = '[ShowDetail] Search registrants',
  REGISTRANTS_LOADED = '[ShowDetail] Registrants loaded',
  SHOW_TICKETS_LOADED = '[ShowDetail] Show tickets loaded',
  REGISTRANTS_UPDATED = '[ShowDetail] Registrants updated',
  SHOW_COUNTERS_LOADED = '[ShowDetail] Show counters loaded',
  LOAD_SELECTED_REGISTRANTS = '[ShowDetail] Load selected registrants',
  LOAD_SHOW_AGGREGATED_DATA = '[ShowDetail] Load show aggregated data',
  SELECTED_REGISTRANTS_LOADED = '[ShowDetail] Selected registrants loaded',
  REQUEST_NEW_REGISTRANTS_PAGE = '[ShowDetail] Request new registrants page',
  SHOW_TICKETS_COUNTERS_LOADED = '[ShowDetail] Show tickets counters loaded',
  START_LISTENING_SHOW_TICKETS = '[ShowDetail] Start listening show tickets',
  START_LISTENING_SHOW_COUNTERS = '[ShowDetail] Start listening show counters',
  SELECT_ALL_REGISTRANTS_IN_TAB = '[ShowDetail] Select all registrants in tab',
  START_LISTENING_REGISTRANTS_NEXT_PAGE = '[ShowDetail] Start listening registrants next page'
}
