// import { Show } from '../../core/models/show';
import { StepperAction } from './stepper.actions';
import { StepperActionsType } from './stepper.actions.name';
import { Step } from '@core/types/step.interface';

export interface StepperDataState {
  isCurrentStepValid: boolean;
  currentStep: number;
  steps: Step[];
  stepperActive: boolean;
}

export const initialState: StepperDataState = {
  isCurrentStepValid: false,
  currentStep: 0,
  steps: [
    { id: 'ShowFormComponent', title: 'started' },
    { id: 'TicketFormComponent', title: 'defineTickets' },
    { id: 'CreateGuestListComponent', title: 'invitePeople' }
  ],
  stepperActive: false
};

export function stepperDataReducer(state = initialState, action: StepperAction): StepperDataState {
  switch (action.type) {
    case StepperActionsType.NEXT_STEP: {
      const { currentStep, steps } = state;
      const maxLimit = steps.length - 1;
      const newCurrentStep = currentStep < maxLimit ? currentStep + 1 : maxLimit;
      return { ...state, currentStep: newCurrentStep };
    }
    case StepperActionsType.PREV_STEP: {
      const { currentStep } = state;
      const newCurrentStep = currentStep >= 1 ? currentStep - 1 : 0;
      return { ...state, currentStep: newCurrentStep };
    }
    case StepperActionsType.GO_TO_STEP:
      return { ...state, currentStep: action.step };
    case StepperActionsType.TOOGLE_STEP_VALID: {
      const isCurrentStepValid = action.isValid; // || !state.isCurrentStepValid;
      return { ...state, isCurrentStepValid };
    }
    case StepperActionsType.TOOGLE_STEPPER_ACTIVE: {
      const stepperActive = action.isActive;
      return { ...state, stepperActive };
    }
    case StepperActionsType.RESET_STEPPER: {
      return initialState;
    }
    default:
      return state;
  }
}
