import { FirestoreReduxListener } from '@store/firestore/firestore.redux.listener';
import { Registrant } from '@core/models';
import { AttendedRegistrantDataState, AttendedRegistrantReducer } from './attended-registrants.firestore.reducer';
import { AngularFirestore, QueryFn } from '@angular/fire/firestore';
import { Store } from '@ngrx/store';

export class AttendedRegistrantFirestoreReduxListener extends FirestoreReduxListener<
  Registrant,
  AttendedRegistrantDataState
> {
  constructor(db: AngularFirestore, store: Store<AttendedRegistrantDataState>, queryFn: QueryFn) {
    super(db, store, AttendedRegistrantReducer.getInstance());

    this.initializeCollectionWithQuery({ queryFn });
    this.startListening();
  }
}
