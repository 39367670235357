import { Image } from '@models/image';
import { imageDataState } from '@store/images';
import { FirestoreReducerDataState } from '@store/firestore/firestore.reducer';
import { FirestoreCollectionReducer } from '@store/firestore/firestore-collection.reducer';
import { FIRESTORE_COLLECTION_IMAGES } from '@store/firestore/allFirestoreCollections';
import { createSelector, MemoizedSelector, Store } from '@ngrx/store';
import { ModifiedDataAction } from '@store/firestore/firestore.actions';

export interface ImageDataState extends FirestoreReducerDataState<Image> {}

export class ImageReducer extends FirestoreCollectionReducer<Image, ImageDataState> {
  private static instance: ImageReducer;

  collectionName = FIRESTORE_COLLECTION_IMAGES;
  featureSelector = imageDataState;

  INNER_COLLECTION_ID = 'imageId';

  ADD_ACTION = '[Image] Add image';
  UPSERT_ACTION = '[Image] Upsert image';
  DELETE_ACTION = '[Image] Delete image';
  MODIFY_ACTION = '[Image] Modify image';

  ADDED_ACTION = '[Image] Added image from firebase';
  DELETED_ACTION = '[Image] Deleted image from firebase';
  MODIFIED_ACTION = '[Image] Modified image from firebase';
  UPSERTED_ACTION = '[Image] Upserted image from firebase';
  LAST_DOCUMENT_SNAPSHOT = '[Image] Last documentSnapshot from firebase';
  MODIFIED_LOCALLY_ACTION = '[Image] Modify locally image';

  static getInstance() {
    if (!ImageReducer.instance) {
      ImageReducer.instance = new ImageReducer();
    }
    return ImageReducer.instance;
  }

  getImagesByCompanyIdSelector(companyId: string): MemoizedSelector<object, Image[]> {
    const CUSTOM_ID = `COMPANY_${companyId}_IMAGES`;
    const selectorToCreate = createSelector(this.getConvertToArraySelector(), companyImages => {
      return companyImages.filter(image => {
        return image.companyId === companyId;
      });
    });

    return this.getOrCreateSelectorById(CUSTOM_ID, selectorToCreate);
  }
}

export function onChangeImageUrlValidation(store: Store<any>, image: Image, isUrlValid: boolean): void {
  const imageReducer = ImageReducer.getInstance();

  const innerImage = { ...image, isUrlValid };

  const actionType = imageReducer.MODIFIED_LOCALLY_ACTION;
  store.dispatch(new ModifiedDataAction(actionType, innerImage.id, innerImage));
}
