import { Show } from '@core/models/show';
import { EmailActionsType } from '@store/email/email.actions.name';
import { EmailAction } from '@store/email/email.actions';

export interface EmailDataState {
  show: Show;
  loading: boolean;
}

export const initialState: EmailDataState = {
  show: null,
  loading: false
};

export function emailDataReducer(state = initialState, action: EmailAction): EmailDataState {
  switch (action.type) {
    case EmailActionsType.LOAD_SHOW:
    case EmailActionsType.SAVE_EMAIL:
      return { ...state, loading: true };

    case EmailActionsType.EMAIL_SAVED:
    case EmailActionsType.SHOW_LOADED:
      const show = action.show;
      return { ...state, loading: false, show };

    default:
      return state;
  }
}
