<div class="cnt-auth">
  <header>
    <h2 class="title">{{ 'auth.changeYourPassword' | translate }}</h2>
  </header>
  <div class="spr-cnt-form">
    <form [formGroup]="myForm" (ngSubmit)="onChangePassword()" class="cnt-form cnt-register">
      <div class="cnt-form-ipt">
        <div class="cnt-ipt" [ngClass]="getClassesForIpt(passwordFormCtrl)">
          <mat-icon class="ipt-icon" fontSet="fa" fontIcon="fa-lock" aria-hidden="true"></mat-icon>
          <input
            type="password"
            name="passwordFormCtrl"
            placeholder="{{'auth.password' | translate}}"
            class="form-ipt-full"
            formControlName="passwordFormCtrl"
          />
        </div>
        <span class="form-error" *ngIf="iptHasErrors(passwordFormCtrl)">{{ 'errors.required' }}</span>
        <span class="form-error" *ngIf="iptHasErrors(passwordFormCtrl, 'minlength')">{{
          'errors.minimum' | translate: passwordLimit
        }}</span>
      </div>
      <div class="cnt-form-ipt">
        <div class="cnt-ipt" [ngClass]="getClassesForIpt(confirmPasswordFormCtrl)">
          <mat-icon class="ipt-icon" fontSet="fa" fontIcon="fa-lock" aria-hidden="true"></mat-icon>
          <input
            type="password"
            name="confirmPasswordFormCtrl"
            placeholder="{{'auth.confirmPassword' | translate}}"
            class="form-ipt-full"
            formControlName="confirmPasswordFormCtrl"
          />
        </div>
        <span class="form-error" *ngIf="iptHasErrors(confirmPasswordFormCtrl)">This field is required</span>
        <span class="form-error" *ngIf="iptHasErrors(confirmPasswordFormCtrl, 'notEqualsFormControl')">{{
          'errors.equalPassword' | translate
        }}</span>
      </div>
      <button
        type="submit"
        class="btn-rounded"
        [ngClass]="{ 'btn-blue': myForm.valid, 'btn-grey-disabled': myForm.invalid }"
        [disabled]="myForm.invalid"
      >
        {{ 'auth.change' | translate }}
      </button>
    </form>
  </div>
</div>

<app-loading class="center-loading" *ngIf="loading"></app-loading>
