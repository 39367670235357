export enum RTDBCountersKey {
  CHECKED = 'checkedCounter',
  CONFIRMED = 'confirmedCounter',
  INVITED = 'invitedCounter',
  NOT_GOING = 'notGoingCounter',
  REGISTRANTS = 'registrantsCounter',
  REVENUES = 'revenuesCounter',
  SHOWS = 'showsCounter',
  TICKETS = 'tickets'
}

interface RTDBCounters {
  [RTDBCountersKey.CHECKED]: number;
  [RTDBCountersKey.CONFIRMED]: number;
  [RTDBCountersKey.INVITED]: number;
  [RTDBCountersKey.NOT_GOING]: number;
  [RTDBCountersKey.REGISTRANTS]: number;
  [RTDBCountersKey.REVENUES]: number;
}
export interface TicketCounters {
  totalCounter: number;
}
export interface CompanyCounters extends RTDBCounters {
  [RTDBCountersKey.SHOWS]: number;
}

export interface ShowCounters extends RTDBCounters {
  [RTDBCountersKey.TICKETS]?: {
    [key: string]: TicketCounters;
  };
}

export const EMPTY_RTDB_COUNTERS: RTDBCounters = {
  [RTDBCountersKey.CHECKED]: 0,
  [RTDBCountersKey.CONFIRMED]: 0,
  [RTDBCountersKey.INVITED]: 0,
  [RTDBCountersKey.NOT_GOING]: 0,
  [RTDBCountersKey.REGISTRANTS]: 0,
  [RTDBCountersKey.REVENUES]: 0
};

export const EMPTY_SHOW_COUNTERS: ShowCounters = {
  ...EMPTY_RTDB_COUNTERS,
  [RTDBCountersKey.TICKETS]: {}
};
