import { FirestoreReduxListener } from '@store/firestore/firestore.redux.listener';
import { Registrant } from '../../core/models/registrant';
import { RegistrantDataState, RegistrantReducer } from '@store/registrants/registrant.firestore.reducer';
import { AngularFirestore, QueryFn } from '@angular/fire/firestore';
import { Store } from '@ngrx/store';

export class RegistrantFirestoreReduxListener extends FirestoreReduxListener<Registrant, RegistrantDataState> {
  constructor(db: AngularFirestore, store: Store<RegistrantDataState>, queryFn: QueryFn) {
    super(db, store, RegistrantReducer.getInstance());

    this.initializeCollectionWithQuery({ queryFn });
    this.startListening();
  }
}
