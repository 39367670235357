import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store, Action, select, MemoizedSelector } from '@ngrx/store';
import { AppState } from '@store';
import { RegistrantReducer } from '@store/registrants/registrant.firestore.reducer';
import { ShowReducer } from '@store/shows/show.firestore.reducer';
import { Observable, Subject } from 'rxjs';
import { ShowDetailActionsType } from '@store/show-detail/show-detail.actions.name';
import { switchMap, map, withLatestFrom, filter, first, takeUntil, tap, distinctUntilChanged } from 'rxjs/operators';
import {
  ShowDetailLoadShow,
  ShowDetailShowLoaded,
  ShowDetailCountersLoaded,
  ShowDetailRegistrantsLoaded,
  ShowDetailSearchRegistrants,
  ShowDetailFilterRegistrant,
  FILTER_TICKET_ASSIGNED,
  FILTER_ATTENDEES,
  FILTER_INVITED,
  FILTER_NOT_GOING,
  ShowDetailSelectAllRegistrantsInTab,
  ShowDetailStartListeningForShowCounters,
  ShowDetailCompanyLoaded,
  ShowDetailMaxInvitesLoaded,
  ShowDetailShowTicketsLoaded,
  ShowDetailTicketsCountersLoaded,
  ShowDetailLoadMaxInvites,
  ShowDetailLoadCompany,
  ShowDetailLoadShowTickets,
  ShowDetailLoadRegistrants,
  ShowDetailLoadSelectedRegistrants,
  ShowDetailSelectedRegistrantsLoaded,
  ShowDetailStartListeningRegistrantsNextPage,
  ShowDetailStartListeningShowTickets,
  ShowDetailClearData,
  ShowDetailRequestNewRegistrantsPage
} from '@store/show-detail/show-detail.actions';
import { showDetailDataState } from '.';
import { Registrant } from '@core/models/registrant';
import { AngularFireDatabase } from '@angular/fire/database';
import { ShowCounters, RTDBCountersKey, EMPTY_SHOW_COUNTERS } from '@core/models/company-counters';
import { CompanyReducer } from '@store/companies/company.firestore.reducer';
import { maxShowInvitesIfTrialSubscription } from '@store/plan-subscription/plan-subscription.firestore.reducer';
import { TicketReducer } from '@store/tickets/tickets.firestore.reducer';
import { ShowTicketCounters } from '@core/models/show-ticket-counter.interface';
import { AddShowAction } from '@store/create-show/create-show.actions';
import { mapTabIndexToRegistrantState } from '@shared/helpers';
import {
  createEqualsQueryAction,
  FilterAction,
  createLimitAction,
  createSortAction,
  SortActionOrder
} from '@store/firestore/firestore.actions';
import { FirestoreReduxService } from '@services/firebase/firestore-redux.service';
import { AttendedRegistrantReducer } from '@store/registrants/attended-registrants/attended-registrants.firestore.reducer';

interface PageRequest {
  key: string;
  page: number;
  pageLimit: number;
}

@Injectable()
export class ShowDetailEffects {
  private showReducer: ShowReducer;
  private regReducer: RegistrantReducer;
  private attendedRegReducer: AttendedRegistrantReducer;
  private actualFilter: MemoizedSelector<object, Registrant[]>;
  private stopSubscription = new Subject<void>();
  private readonly newPageRequests: Set<String> = new Set();
  private readonly queueNewPageRequest: { key: string; pageLimit: number; page: number }[] = [];

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private rtdb: AngularFireDatabase,
    private firestoreReduxService: FirestoreReduxService
  ) {
    this.showReducer = ShowReducer.getInstance();
    this.regReducer = RegistrantReducer.getInstance();
    this.attendedRegReducer = AttendedRegistrantReducer.getInstance();
  }

  @Effect()
  countersLoaded$: Observable<Action> = this.actions$.pipe(
    ofType(ShowDetailActionsType.SHOW_COUNTERS_LOADED, ShowDetailActionsType.SHOW_TICKETS_LOADED),
    withLatestFrom(this.store.pipe(select(showDetailDataState))),
    map(([, { rtdbCounters, showTickets }]) => {
      const { confirmedCounter: total, tickets: ticketCounters } = rtdbCounters;

      const showTicketsCounters: ShowTicketCounters = { counters: [], total };

      // ticketCounters is optional but ompiler does not complain about it
      if (ticketCounters) {
        for (const ticket of showTickets) {
          const ticketCounter = ticketCounters[ticket.id];
          if (!ticketCounter) {
            continue;
          }
          showTicketsCounters.counters.push({
            amount: ticketCounter.totalCounter,
            name: ticket.name
          });
        }
      }

      return new ShowDetailTicketsCountersLoaded(showTicketsCounters);
    })
  );

  @Effect()
  listenShowCounters$: Observable<Action> = this.actions$.pipe(
    ofType(ShowDetailActionsType.START_LISTENING_SHOW_COUNTERS),
    switchMap((action: ShowDetailStartListeningForShowCounters) => {
      const showId = action.showId;

      return this.rtdb
        .object(`events/${showId}`)
        .valueChanges()
        .pipe(
          takeUntil(this.stopSubscription),
          withLatestFrom(this.store.pipe(select(showDetailDataState))),
          map(([rtdbCounters, { statistics }]) => {
            const counters = rtdbCounters as ShowCounters;

            if (!counters) {
              return new ShowDetailCountersLoaded(EMPTY_SHOW_COUNTERS, []);
            }

            const newStatistics = statistics.map(stt => {
              const innerStt = { ...stt };
              if (!!counters && !!innerStt && innerStt.keyCounter && counters[innerStt.keyCounter] !== undefined) {
                innerStt.amount = counters[innerStt.keyCounter];
              }
              return innerStt;
            });

            return new ShowDetailCountersLoaded(counters, newStatistics);
          })
        );
    })
  );

  @Effect({ dispatch: false })
  listenRegistrantsNextPage$ = this.actions$.pipe(
    ofType(ShowDetailActionsType.START_LISTENING_REGISTRANTS_NEXT_PAGE),
    tap((action: ShowDetailStartListeningRegistrantsNextPage) => {
      const { tabIndex, pageLimit, showId } = action;

      const queryFilters = this.getQueryFilters(tabIndex, showId, pageLimit);
      const registrantKind = this._mapTabIndexToRegistrantCounterKey(tabIndex);
      if (tabIndex === FILTER_ATTENDEES) {
        this.firestoreReduxService.startAttendedRegistrantFirestoreReduxListener(queryFilters, showId);
      } else {
        this.firestoreReduxService.startRegistrantFirestoreReduxListener(queryFilters, showId, registrantKind);
      }
    })
  );

  @Effect({ dispatch: false })
  listenShowTickets$ = this.actions$.pipe(
    ofType(ShowDetailActionsType.START_LISTENING_SHOW_TICKETS),
    tap((action: ShowDetailStartListeningShowTickets) => {
      const { showId } = action;

      const eventId = createEqualsQueryAction('eventId', showId);
      this.firestoreReduxService.startTicketFirestoreReduxListener([eventId], showId);
    })
  );

  @Effect({ dispatch: false })
  clearData$ = this.actions$.pipe(
    ofType(ShowDetailActionsType.CLEAR_DATA),
    tap((action: ShowDetailClearData) => {
      const showId = action.showId;
      this.stopSubscription.next();
      this.stopSubscription.complete();
      this.firestoreReduxService.stopTicketFirestoreReduxListener(showId);
      this.firestoreReduxService.stopRegistrantFirestoreReduxListener(showId);
      this.newPageRequests.clear();
      this.queueNewPageRequest.splice(0);
    })
  );

  @Effect()
  loadShowTickets$: Observable<Action> = this.actions$.pipe(
    ofType(ShowDetailActionsType.LOAD_SHOW_TICKETS),
    switchMap((action: ShowDetailLoadShowTickets) => {
      const showId = action.showId;

      return this.store
        .pipe(
          takeUntil(this.stopSubscription),
          select(TicketReducer.getInstance().getTicketsByShowIdSelector(showId))
        )
        .pipe(map(tickets => new ShowDetailShowTicketsLoaded(tickets)));
    })
  );

  @Effect()
  loadSelectedRegistrants$: Observable<Action> = this.actions$.pipe(
    ofType(ShowDetailActionsType.LOAD_SELECTED_REGISTRANTS),
    switchMap((action: ShowDetailLoadSelectedRegistrants) => {
      const showId = action.showId;

      return this.store.pipe(
        takeUntil(this.stopSubscription),
        select(RegistrantReducer.getInstance().getSelectedRegistrantsByShowIdSelector(showId)),
        map(selectedRegistrants => new ShowDetailSelectedRegistrantsLoaded(selectedRegistrants))
      );
    })
  );

  @Effect()
  loadShow$: Observable<Action> = this.actions$.pipe(
    ofType(ShowDetailActionsType.LOAD_SHOW),
    tap((action: ShowDetailLoadShow) => this.store.dispatch(new ShowDetailLoadShowTickets(action.showId))),
    tap((action: ShowDetailLoadShow) => this.store.dispatch(new ShowDetailLoadRegistrants(action.showId))),
    tap(action => this.store.dispatch(new ShowDetailStartListeningForShowCounters(action.showId))),
    tap(action => this.store.dispatch(new ShowDetailLoadSelectedRegistrants(action.showId))),
    withLatestFrom(this.store.pipe(select(showDetailDataState))),
    map(([action, { selectedTabIndex }]) => {
      this.store.dispatch(new ShowDetailFilterRegistrant(action.showId, selectedTabIndex));
      return action;
    }),
    switchMap((action: ShowDetailLoadShow) => {
      const showId = action.showId;
      this.actualFilter = this.regReducer.getInvitedByShowIdSelector(showId);
      const selector = this.showReducer.getEntityByIdSelector(showId);

      return this.store
        .pipe(
          takeUntil(this.stopSubscription),
          select(selector),
          filter(show => !!show)
        )
        .pipe(map(show => new ShowDetailShowLoaded(show)));
    })
  );

  @Effect()
  loadMaxInvites$: Observable<Action> = this.actions$.pipe(
    ofType(ShowDetailActionsType.LOAD_MAX_INVITES),
    switchMap((action: ShowDetailLoadMaxInvites) =>
      maxShowInvitesIfTrialSubscription(action.show, this.store).pipe(
        takeUntil(this.stopSubscription),
        distinctUntilChanged()
      )
    ),
    map(maxInvited => new ShowDetailMaxInvitesLoaded(maxInvited.maxInvites))
  );

  @Effect({ dispatch: false })
  requestNewRegistrantsPage$ = this.actions$.pipe(
    ofType(ShowDetailActionsType.REQUEST_NEW_REGISTRANTS_PAGE),
    withLatestFrom(this.store.pipe(select(showDetailDataState))),
    tap(([action, { show, selectedTabIndex, registrants }]) => {
      const { requestPage, pageSize } = action as ShowDetailRequestNewRegistrantsPage;
      this.handleNewPageRequest(requestPage, registrants, pageSize, show.id, selectedTabIndex);
    })
  );

  @Effect()
  loadCompany$: Observable<Action> = this.actions$.pipe(
    ofType(ShowDetailActionsType.LOAD_COMPANY),
    switchMap((action: ShowDetailLoadCompany) => {
      const companySelector = CompanyReducer.getInstance().getEntityByIdSelector(action.companyId);
      return this.store.pipe(
        select(companySelector),
        filter(company => !!company),
        first()
      );
    }),
    map(company => new ShowDetailCompanyLoaded(company))
  );

  @Effect()
  showLoaded$: Observable<Action> = this.actions$.pipe(
    ofType(ShowDetailActionsType.SHOW_LOADED),
    switchMap((action: ShowDetailShowLoaded) => {
      const show = action.show;
      const addShowAction = new AddShowAction(show);
      const loadMaxInvitesAction = new ShowDetailLoadMaxInvites(show);
      const loadCompanyAction = new ShowDetailLoadCompany(show.companyId);

      return [addShowAction, loadMaxInvitesAction, loadCompanyAction];
    })
  );

  @Effect()
  loadRegistrants$: Observable<Action> = this.actions$.pipe(
    ofType(ShowDetailActionsType.LOAD_REGISTRANTS),
    switchMap((action: ShowDetailLoadShow) => {
      const showId = action.showId;
      const selector = this.regReducer.getInvitedByShowIdSelector(showId);
      return this.store.pipe(
        takeUntil(this.stopSubscription),
        select(selector),
        map(registrants => new ShowDetailRegistrantsLoaded(registrants))
      );
    })
  );

  @Effect()
  searchRegistrants$: Observable<Action> = this.actions$.pipe(
    ofType(ShowDetailActionsType.SEARCH_REGISTRANTS),
    switchMap((action: ShowDetailSearchRegistrants) => {
      const text = action.text;
      let selector = this.regReducer.getRegistrantsByShowIdSearchSelector(text, this.actualFilter);
      if (text.trim().length === 0) {
        selector = this.actualFilter;
      }

      return this.store.pipe(
        takeUntil(this.stopSubscription),
        select(selector),
        map(registrants => new ShowDetailRegistrantsLoaded(registrants))
      );
    })
  );

  @Effect()
  showFilter$: Observable<Action> = this.actions$.pipe(
    ofType(ShowDetailActionsType.FILTER_REGISTRANTS),
    withLatestFrom(this.store.pipe(select(showDetailDataState))),
    switchMap(([action, { search }]) => {
      const showDetailAction = action as ShowDetailFilterRegistrant;

      const showId = showDetailAction.showId;
      const innerFilter = showDetailAction.filter;
      switch (innerFilter) {
        case FILTER_INVITED:
          this.actualFilter = this.regReducer.getInvitedByShowIdSelector(showId);
          break;
        case FILTER_NOT_GOING:
          this.actualFilter = this.regReducer.getNotGoingByShowIdSelector(showId);
          break;
        case FILTER_TICKET_ASSIGNED:
          this.actualFilter = this.regReducer.getTicketAssignedByShowIdSelector(showId);
          break;
        case FILTER_ATTENDEES:
          this.actualFilter = this.attendedRegReducer.getAttendeesByShowIdSelector(showId);
          break;
        default:
          this.actualFilter = this.regReducer.getRegistrantsByShowIdSelector(showId);
          break;
      }

      return [new ShowDetailSelectAllRegistrantsInTab(), new ShowDetailSearchRegistrants(showId, search)];
    })
  );

  private getQueryFilters(tabIndex: number, showId: string, pageLimit: number) {
    const [registrantState, status] = mapTabIndexToRegistrantState(tabIndex);
    const eventId = createEqualsQueryAction('eventId', showId);
    const actualState = createEqualsQueryAction('actualState.type', registrantState);
    const queries: FilterAction[] = [eventId, actualState];
    if (status) {
      const checkedQuery = createEqualsQueryAction('status', status);
      queries.push(checkedQuery);
    }
    const limit = createLimitAction(pageLimit);
    queries.push(limit);
    const sortBy = createSortAction('fullName', SortActionOrder.ASC);
    queries.push(sortBy);
    const notDeleted = createEqualsQueryAction('isDeleted', false);
    queries.push(notDeleted);
    return queries;
  }

  private _mapTabIndexToRegistrantCounterKey(tabIndex: number): RTDBCountersKey {
    let placeholder = RTDBCountersKey.INVITED;

    switch (tabIndex) {
      case FILTER_INVITED:
        placeholder = RTDBCountersKey.INVITED;
        break;
      case FILTER_TICKET_ASSIGNED:
        placeholder = RTDBCountersKey.CONFIRMED;
        break;
      case FILTER_NOT_GOING:
        placeholder = RTDBCountersKey.NOT_GOING;
        break;
      case FILTER_ATTENDEES:
        placeholder = RTDBCountersKey.CHECKED;
        break;
    }
    return placeholder;
  }

  private buildPageRequestKey(pageRequest: PageRequest): string {
    const { key, page } = pageRequest;
    return `${key}_${page}`;
  }

  private handleNewPageRequest(
    pageRequest: PageRequest,
    registrants: Registrant[],
    pageSize: number,
    showId: string,
    selectedTabIndex: number
  ) {
    const requestKey = this.buildPageRequestKey(pageRequest);
    const minSizeForRequest = (pageRequest.page - 1) * pageSize;
    const hasMinSizeForRequest = registrants.length > minSizeForRequest;
    const hasntBeenRequested = !this.newPageRequests.has(requestKey);

    if (hasMinSizeForRequest && hasntBeenRequested) {
      this.newPageRequests.add(requestKey);
      this.store.dispatch(
        new ShowDetailStartListeningRegistrantsNextPage(showId, selectedTabIndex, pageRequest.pageLimit)
      );
    } else {
      this.queueNewPageRequest.push(pageRequest);
    }
  }
}
