import { Action } from '@ngrx/store';
import { ShowDetailActionsType } from '@store/show-detail/show-detail.actions.name';
import { Show } from '@core/models/show';
import { Registrant } from '@core/models/registrant';
import { ShowCounters } from '@core/models/company-counters';
import { Company, Ticket, Statistic } from '@core/models';
import { ShowTicketCounters } from '@core/models/show-ticket-counter.interface';

export type ShowDetailAction =
  | ShowDetailLoadShow
  | ShowDetailLoadCompany
  | ShowDetailLoadMaxInvites
  | ShowDetailClearData
  | ShowDetailMaxInvitesLoaded
  | ShowDetailSearchRegistrants
  | ShowDetailFilterRegistrant
  | ShowDetailTablePageChanged
  | ShowDetailStartListeningForShowCounters
  | ShowDetailCountersLoaded
  | ShowDetailLoadRegistrants
  | ShowDetailLoadShowAggregateData
  | ShowDetailShowLoaded
  | ShowDetailLoadShowTickets
  | ShowDetailShowTicketsLoaded
  | ShowDetailCompanyLoaded
  | ShowDetailRegistrantsLoaded
  | ShowDetailRevenuesChanged
  | ShowDetailRegistrantsChanged
  | ShowDetailAttendeesChanged
  | ShowDetailTicketsCountersLoaded
  | ShowDetailSelectedRegistrantsLoaded
  | ShowDetailSelectAllRegistrantsInTab
  | ShowDetailStartListeningShowTickets
  | ShowDetailStartListeningRegistrantsNextPage;

export const FILTER_ALL = 150;
export const FILTER_INVITED = 0;
export const FILTER_NOT_GOING = 1;
export const FILTER_TICKET_ASSIGNED = 2;
export const FILTER_ATTENDEES = 3;

export class ShowDetailLoadShow implements Action {
  readonly type = ShowDetailActionsType.LOAD_SHOW;
  constructor(public showId: string) {}
}

export class ShowDetailLoadCompany implements Action {
  readonly type = ShowDetailActionsType.LOAD_COMPANY;
  constructor(public companyId: string) {}
}

export class ShowDetailLoadMaxInvites implements Action {
  readonly type = ShowDetailActionsType.LOAD_MAX_INVITES;
  constructor(public show: Show) {}
}

export class ShowDetailClearData implements Action {
  readonly type = ShowDetailActionsType.CLEAR_DATA;
  constructor(public showId) {}
}

export class ShowDetailMaxInvitesLoaded implements Action {
  readonly type = ShowDetailActionsType.MAX_INVITES_LOADED;
  constructor(public maxInvites: number) {}
}

export class ShowDetailSearchRegistrants implements Action {
  readonly type = ShowDetailActionsType.SEARCH_REGISTRANTS;
  constructor(public showId: string, public text: string) {}
}

export class ShowDetailFilterRegistrant implements Action {
  readonly type = ShowDetailActionsType.FILTER_REGISTRANTS;
  constructor(public showId: string, public filter: number) {}
}
export class ShowDetailTablePageChanged implements Action {
  readonly type = ShowDetailActionsType.TABLE_PAGE_CHANGED;
  constructor(public tabIndex: number, public page: number) {}
}

export class ShowDetailStartListeningForShowCounters implements Action {
  readonly type = ShowDetailActionsType.START_LISTENING_SHOW_COUNTERS;
  constructor(public showId: string) {}
}

export class ShowDetailCountersLoaded implements Action {
  readonly type = ShowDetailActionsType.SHOW_COUNTERS_LOADED;
  constructor(public counters: ShowCounters, public statistics: Statistic[]) {}
}

export class ShowDetailRequestNewRegistrantsPage implements Action {
  readonly type = ShowDetailActionsType.REQUEST_NEW_REGISTRANTS_PAGE;
  constructor(public requestPage: { key: string; page: number; pageLimit: number }, public pageSize: number) {}
}

export class ShowDetailLoadRegistrants implements Action {
  readonly type = ShowDetailActionsType.LOAD_REGISTRANTS;
  constructor(public showId: string) {}
}

export class ShowDetailLoadShowAggregateData implements Action {
  readonly type = ShowDetailActionsType.LOAD_SHOW_AGGREGATED_DATA;
  constructor(public showId: string) {}
}

export class ShowDetailLoadSelectedRegistrants implements Action {
  readonly type = ShowDetailActionsType.LOAD_SELECTED_REGISTRANTS;
  constructor(public showId: string) {}
}

export class ShowDetailSelectedRegistrantsLoaded implements Action {
  readonly type = ShowDetailActionsType.SELECTED_REGISTRANTS_LOADED;
  constructor(public selectedRegistrants: Registrant[]) {}
}

export class ShowDetailShowLoaded implements Action {
  readonly type = ShowDetailActionsType.SHOW_LOADED;
  constructor(public show: Show) {}
}

export class ShowDetailLoadShowTickets implements Action {
  readonly type = ShowDetailActionsType.LOAD_SHOW_TICKETS;
  constructor(public showId: string) {}
}

export class ShowDetailShowTicketsLoaded implements Action {
  readonly type = ShowDetailActionsType.SHOW_TICKETS_LOADED;
  constructor(public tickets: Ticket[]) {}
}

export class ShowDetailCompanyLoaded implements Action {
  readonly type = ShowDetailActionsType.COMPANY_LOADED;
  constructor(public company: Company) {}
}

export class ShowDetailRegistrantsLoaded implements Action {
  readonly type = ShowDetailActionsType.REGISTRANTS_LOADED;
  constructor(public registrants: Registrant[]) {}
}

export class ShowDetailRevenuesChanged implements Action {
  readonly type = ShowDetailActionsType.REVENUES_UPDATED;
  constructor(public revenues: number) {}
}

export class ShowDetailRegistrantsChanged implements Action {
  readonly type = ShowDetailActionsType.REGISTRANTS_UPDATED;
  constructor(public registrants: number) {}
}

export class ShowDetailAttendeesChanged implements Action {
  readonly type = ShowDetailActionsType.ATTENDEES_UPDATED;
  constructor(public attendees: number) {}
}

export class ShowDetailTicketsCountersLoaded implements Action {
  readonly type = ShowDetailActionsType.SHOW_TICKETS_COUNTERS_LOADED;
  constructor(public showTicketsCounters: ShowTicketCounters) {}
}

export class ShowDetailSelectAllRegistrantsInTab implements Action {
  readonly type = ShowDetailActionsType.SELECT_ALL_REGISTRANTS_IN_TAB;
  constructor() {}
}
export class ShowDetailStartListeningShowTickets implements Action {
  readonly type = ShowDetailActionsType.START_LISTENING_SHOW_TICKETS;
  constructor(public showId) {}
}

export class ShowDetailStartListeningRegistrantsNextPage implements Action {
  readonly type = ShowDetailActionsType.START_LISTENING_REGISTRANTS_NEXT_PAGE;
  constructor(public showId, public tabIndex, public pageLimit) {}
}
