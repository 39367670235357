import { NgZone } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { AuthServiceErrors } from '@services/auth/auth.service.errors';
import { SnackBarService } from '@services/snack-bar.service';

export function handleAuthError(
  loginError: string,
  snackBarService: SnackBarService,
  translate: TranslateService,
  zone: NgZone
): void {
  if (loginError) {
    const errorKey = authErrorTextHelper(loginError);

    translate.get(errorKey).subscribe(msg => {
      zone.run(() => {
        snackBarService.open({ msg, type: 'error' }, 10000);
      });
    });
  }
}

export function authErrorTextHelper(error: string): string {
  switch (error) {
    case AuthServiceErrors.WRONG_PASWORD:
      return 'errors.wrongPassword';
    case AuthServiceErrors.USER_NOT_FOUND:
      return 'errors.userDoesNotExists';
    case AuthServiceErrors.INVALID_EMAIL:
      return 'errors.invalidEmail';
    case AuthServiceErrors.POP_UP_CLOSED_BY_USER:
      return 'errors.popUpClosed';
    case AuthServiceErrors.EMAIL_ALREADY_IN_USE:
      return 'errors.emailAlreadyBeingUsed';
    case AuthServiceErrors.INVALID_PASSWORD:
      return 'errors.invalidPassword';
    default:
      return 'errors.unknownError';
  }
}
