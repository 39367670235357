import { Component, Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<Component> {
  canDeactivate(currentComponent: any): boolean {
    if (!currentComponent.isPristine()) {
      if (confirm(currentComponent.msgBeforeLeavePage)) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  }
}
