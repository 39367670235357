import { ModifiedDataAction, AddedDataAction } from './firestore.actions';
import { insertInArray, deleteContiguousInArray, updateInArray } from '@shared/tools/array-operators';
import { BaseModel } from '@core/models/basemodel';

export function buildParentPath(ancestorsId: string, collectionName: string, id: string): string {
  const initialSegment = ancestorsId ? `${ancestorsId}/` : '';
  return `${initialSegment}${collectionName}/${id}`;
}

export function handleSubcollectionEffectAction<T extends BaseModel>(
  action: AddedDataAction | ModifiedDataAction,
  modifiedLocallyAction: string,
  allEntities: { [key: string]: T },
  subcollectionName: string
): ModifiedDataAction {
  const data = { ...action.data } as T;

  const ancestorsId = action.ancestorsId;
  const { parentId, newAncestorsId } = processAncestorsId(ancestorsId);

  const entity = { ...(allEntities[parentId] as object) } as T;

  data.id = action.id;

  const list = entity[subcollectionName];

  if (!!list) {
    const newSubcollection = action instanceof AddedDataAction ? insertInArray(list, data) : updateInArray(list, data);
    entity[subcollectionName] = newSubcollection;
  }

  return new ModifiedDataAction(modifiedLocallyAction, entity.id, entity, newAncestorsId);
}

function processAncestorsId(ancestorsId: string): { parentId: string; newAncestorsId: string } {
  const split = ancestorsId.split('/');

  const newSplit = deleteContiguousInArray(split, split.length - 2, 2);

  const parentId = split[split.length - 1];

  //------------------------------------------------------------

  let newAncestorsId = '';
  newSplit.forEach(segment => {
    newAncestorsId = newAncestorsId.concat(segment).concat('/');
  });
  newAncestorsId = newAncestorsId.slice(0, newAncestorsId.length - 1);

  return { parentId, newAncestorsId };
}
