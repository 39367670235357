import { FirestoreReducer, FirestoreReducerDataState } from '@store/firestore/firestore.reducer';
import { BaseModel } from '@core/models/basemodel';

/**This file will contain (after all firestore reducers initialization)
 * all info required by @firebase.effects to correctly filter the action,
 * choose firestore collection, redirect actions after asynchronous actions have finished
 */

/**Object mapping all actions to its reducer so firestore effects can acces all needed variables from the reducer */
export const allFirestoreActionsToReducers: {
  [key: string]: FirestoreReducer<BaseModel, FirestoreReducerDataState<BaseModel>>;
} = {};

/**Array containing all firestore actions regarding add to a collection */
export const allFirestoreAddEffectTypes: string[] = [];

/**Array containing all firestore actions regarding upsert to a collection */
export const allFirestoreUpsertEffectTypes: string[] = [];

/**Array containing all firestore actions regarding delete of a document in a collection */
export const allFirestoreDeleteEffectTypes: string[] = [];

/**Array containing all firestore actions regarding modify to a document in a collection */
export const allFirestoreModifyEffectTypes: string[] = [];
