import { Company } from '@models/company';
import { Store } from '@ngrx/store';
import { CompanyReducer, CompanyDataState } from '@store/companies/company.firestore.reducer';
import { AngularFirestore, QueryFn } from '@angular/fire/firestore';
import { FirestoreReduxListener } from '@store/firestore/firestore.redux.listener';

export class CompanyFirestoreReduxListener extends FirestoreReduxListener<Company, CompanyDataState> {
  constructor(db: AngularFirestore, store: Store<CompanyDataState>, queryFn?: QueryFn) {
    super(db, store, CompanyReducer.getInstance());

    this.initializeCollectionWithQuery({ queryFn });
    this.startListening();
  }
}
