import { Component, NgZone } from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { AuthDataState } from '@store/auth/auth.reducer';
import { RecoverPasswordAction } from '@store/auth/auth.actions';
import { authDataState } from '@store/auth';
import { FormValidators } from '@shared/forms/form-validators';
import { FormControlStructure } from '@core/models/form-control-structure';
import { WodenFormBuilder } from '@shared/forms/woden-form-builder';
import { Dictionary } from '@core/types/dictionary.interface';
import { handleAuthError } from '../auth-error-text-helper';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarService } from '@services/snack-bar.service';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss']
})
export class ForgotComponent extends FormValidators {
  private EMAIL_FORM_CTRL_KEY = 'emailFormCtrl';

  myForm: FormGroup;

  loading: boolean;
  emailSent: boolean;

  constructor(
    private store: Store<AuthDataState>,
    private translate: TranslateService,
    private snackBarService: SnackBarService,
    private zone: NgZone
  ) {
    super();
  }

  ngOnInit() {
    this.emailSent = false;
    super.ngOnInit();

    this.store.pipe(select(authDataState)).subscribe(({ loading, authError, passwordRecovered }) => {
      this.loading = loading;

      if (passwordRecovered) {
        this.emailSent = true;
      }

      handleAuthError(authError, this.snackBarService, this.translate, this.zone);
    });
  }

  get emailFormCtrl(): AbstractControl {
    return this.myForm.get(this.EMAIL_FORM_CTRL_KEY);
  }

  get classesForBtn(): Dictionary {
    return { 'btn-blue': this.myForm.valid, 'btn-grey-disabled': this.myForm.invalid };
  }

  initForm(): void {
    const emailFormCtrl = new FormControlStructure(this.EMAIL_FORM_CTRL_KEY, 'forEmail');
    const formBuilder = new WodenFormBuilder([emailFormCtrl]);
    this.myForm = formBuilder.getFormInstance();
  }

  onReset() {
    const { valid, value } = this.validateForm();

    if (valid) {
      const { emailFormCtrl } = value;
      this.store.dispatch(new RecoverPasswordAction(emailFormCtrl));
    }
  }
}
