import { FirestoreSubCollectionReducer } from '@store/firestore/firestore-subcollection.reducer';
import { BaseModel } from '@core/models/basemodel';
import { FirestoreReducerDataState } from '@store/firestore/firestore.reducer';
import {
  FIRESTORE_REGISTRANTS_SUBCOLLECTION_EVENT_MAIL_LIST,
  FIRESTORE_EVENT_MAIL_LIST_SUBCOLLECTION_STATUS
} from '@store/firestore/allFirestoreCollections';

export interface RegistrantEventMailStatusDataState extends FirestoreReducerDataState<BaseModel> {}

export class RegistrantEventMailStatusReducer extends FirestoreSubCollectionReducer<
  BaseModel,
  RegistrantEventMailStatusDataState
> {
  private static instance: RegistrantEventMailStatusReducer;

  parentCollectionName = FIRESTORE_REGISTRANTS_SUBCOLLECTION_EVENT_MAIL_LIST;
  collectionName = FIRESTORE_EVENT_MAIL_LIST_SUBCOLLECTION_STATUS;
  AUTO_SET_INNER_COLLECTION_ID = false;

  ADD_ACTION = '[EventMailStatus] Add eventMailStatus';
  UPSERT_ACTION = '[EventMailStatus] Upsert eventMailStatus';
  DELETE_ACTION = '[EventMailStatus] Delete eventMailStatus';
  MODIFY_ACTION = '[EventMailStatus] Modify eventMailStatus';

  ADDED_ACTION = '[EventMailStatus] Added eventMailStatus from firebase';
  DELETED_ACTION = '[EventMailStatus] Deleted eventMailStatus from firebase';
  MODIFIED_ACTION = '[EventMailStatus] Modified eventMailStatus from firebase';
  UPSERTED_ACTION = '[EventMailStatus] Upserted eventMailStatus firebase';
  LAST_DOCUMENT_SNAPSHOT = '[EventMailStatus] Last documentSnapshot from firebase';
  MODIFIED_LOCALLY_ACTION = '[EventMailStatus] Modify locally eventMailStatus';

  static getInstance() {
    if (!RegistrantEventMailStatusReducer.instance) {
      RegistrantEventMailStatusReducer.instance = new RegistrantEventMailStatusReducer();
    }
    return RegistrantEventMailStatusReducer.instance;
  }
}
