import { BaseModel } from './basemodel';
import { EventMail } from './event-mail';

export enum RegistrantStates {
  INVITED = 'invited',
  NOT_GOING = 'notGoing',
  REGISTERED = 'registered'
}
export class Registrant implements BaseModel {
  id?: string;
  isDeleted?: false;
  email: string;
  phone: string;
  fullName: string;
  reference: number;
  /**Name got from backend */
  creation_date: Date;
  creationDate: Date;
  registrantId?: string;
  idCardNumber?: string;
  /*** */
  eventId?: string;
  photo?: string;
  price?: number;
  status?: number;
  tickets?: number;
  ticketId?: string;

  paymentMethod?: string;

  actualState?: RegistrantState;
  stateHistory?: RegistrantState[];

  // Adding the most up to date mail status for the registrant
  currentEmailStatus?: MailStatus;

  //Locally added fields
  selected: boolean;
  eventMailList?: EventMail[];
}

export function registrantHasBeenOnlyInvited(registrant: Registrant): boolean {
  if (!registrant) {
    return false;
  }

  const hasBeenRegistered = registrantHasBeenRegistered(registrant);
  return (
    !hasBeenRegistered && registrant.stateHistory.filter(state => state.type === RegistrantStates.INVITED).length > 0
  );
}

export function registrantHasBeenRegistered(registrant: Registrant): boolean {
  if (!registrant) {
    return false;
  }

  return registrant.stateHistory.filter(state => state.type === RegistrantStates.REGISTERED).length > 0;
}

export interface RegistrantState {
  type: RegistrantStates;
  date: Date;
}

export interface MailStatus {
  description: String;
  event: String;
  timestamp: Date;
}
