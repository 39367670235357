<div class="cnt-snack-bar" [ngClass]="cntClass" [class.mat-elevation-z6]="true">
  <!-- Icon -->
  <mat-icon class="icon">{{ icon }}</mat-icon>

  <!-- Message -->
  <p class="message">{{ message }}</p>
  <!-- Buttons -->
  <a *ngIf="btnLink" routerLink="{{ btnLink }}">
    <button class="btn-white" (click)="close()">{{ txtBtn | translate }}</button>
  </a>

  <button *ngIf="!btnLink && txtBtn" class="btn-white" (click)="close()">{{ txtBtn | translate }}</button>

  <button mat-icon-button *ngIf="!btnLink && !txtBtn" (click)="close()">
    <mat-icon aria-label="Example icon-button with a heart icon">close</mat-icon>
  </button>
</div>
