import { FirestoreReducerDataState } from '@store/firestore/firestore.reducer';
import { FIRESTORE_COLLECTION_PLANS } from '@store/firestore/allFirestoreCollections';
import { FirestoreCollectionReducer } from '@store/firestore/firestore-collection.reducer';
import { Plan } from '@core/models/plan.model';
import { planDataState } from '.';
import { MemoizedSelector, createSelector } from '@ngrx/store';

export interface PlanDataState extends FirestoreReducerDataState<Plan> {}

export class PlanReducer extends FirestoreCollectionReducer<Plan, PlanDataState> {
  private static instance: PlanReducer;

  featureSelector = planDataState;

  INNER_COLLECTION_ID = 'planId';

  collectionName = FIRESTORE_COLLECTION_PLANS;

  ADD_ACTION = '[Plan] Add plan';
  UPSERT_ACTION = '[Plan] Upsert plan';
  DELETE_ACTION = '[Plan] Delete plan';
  MODIFY_ACTION = '[Plan] Modify plan';

  ADDED_ACTION = '[Plan] Added plan from firebase';
  DELETED_ACTION = '[Plan] Deleted plan from firebase';
  MODIFIED_ACTION = '[Plan] Modified plan from firebase';
  UPSERTED_ACTION = '[Plan] Upserted plan from firebase';
  LAST_DOCUMENT_SNAPSHOT = '[Plan] Last documentSnapshot from firebase';
  MODIFIED_LOCALLY_ACTION = '[Plan] Modify locally plan';

  static getInstance() {
    if (!PlanReducer.instance) {
      PlanReducer.instance = new PlanReducer();
    }
    return PlanReducer.instance;
  }
}

export const stripeEnabledPlansSelector: MemoizedSelector<object, Plan[]> = createSelector(
  PlanReducer.getInstance().getConvertToArraySelector(),
  plans => plans.filter(plan => plan.stripeSkuId)
);

export const demoPlanSelector: MemoizedSelector<object, Plan> = createSelector(
  PlanReducer.getInstance().getConvertToArraySelector(),
  plans => {
    return plans.find(plan => plan.name === 'demo');
  }
);
