import { ActionReducerMap, MetaReducer, Action } from '@ngrx/store';

import { BaseModel } from '@core/models/basemodel';
import { AuthActionsType } from './auth/auth.actions.name';
// Reducers
import { AuthDataState, authDataReducer } from './auth/auth.reducer';
import { allFirestoreReducers, initializeFirestoreEffects } from './firestore/allFirestoreReducers';
import { CreateShowDataState, createShowDataReducer } from './create-show/create-show.reducer';
import { DashboardDataState, dashboardDataReducer } from './dashboard/dashboard.reducer';
import { EmailDataState, emailDataReducer } from '@store/email/email.reducer';
import { FirestoreReducerDataState } from '@store/firestore/firestore.reducer';
import { inviteUserDataReducer, InviteUserDataState } from './invite-user/invite-user.reducer';
import { PlanSubscriptionDataState } from './plan-subscription/plan-subscription.firestore.reducer';
import { RegistrantEventMailListDataState } from './registrants/registrant-event-mail-list.firestore-subcollection.reducer';
import { RegistrantEventMailStatusDataState } from './registrants/registrant-event-mail-status.firestore-subcollection.reducer';
import { SidenavDataState, sidenavDataReducer } from './sidenav/sidenav.reducer';
import { StepperDataState, stepperDataReducer } from './stepper/stepper.reducer';
import { ShowDetailDataState, showDetailDataReducer } from '@store/show-detail/show-detail.reducer';
import { TicketDataState } from './tickets/tickets.firestore.reducer';
// Effects
import { AuthEffects } from './auth/auth.effects';
import { CompaniesEffects } from './companies/companies.effects';
import { DashboardEffects } from './dashboard/dashboard.effects';
import { EmailEffects } from './email/email.effects';
import { FirebaseEffects } from './firestore/firestore.effects';
import { InviteUserEffects } from './invite-user/invite-user.effects';
import { RegistrantEventMailListEffects } from './registrants/registrant-event-mail-list.effects';
import { RegistrantEventMailStatusEffects } from './registrants/registrant-event-mail-status.effects';
import { RegistrantsEffects } from './registrants/registrants.effects';
import { ShowDetailEffects } from './show-detail/show-detail.effects';
import { ShowEffects } from './shows/show.effects';
import { ImageGalleryDataState, imageGalleryDataReducer } from './image-gallery/image-gallery.reducer';
import { ImageGalleryEffects } from './image-gallery/image-gallery.effects';

//Needed to initialize effects keys and initialStates
initializeFirestoreEffects();

/**AppState structure follows key value format using as value the DataState from ui reducers and Firestore reducers */
export interface AppState {
  [key: string]:
    | AuthDataState
    | EmailDataState
    | TicketDataState
    | StepperDataState
    | DashboardDataState
    | InviteUserDataState
    | CreateShowDataState
    | ShowDetailDataState
    | PlanSubscriptionDataState
    | RegistrantEventMailListDataState
    | RegistrantEventMailStatusDataState
    | SidenavDataState
    | ImageGalleryDataState
    | FirestoreReducerDataState<BaseModel>;
}

export const reducers: ActionReducerMap<AppState> = {
  auth: authDataReducer,
  email: emailDataReducer,
  stepper: stepperDataReducer,
  dashboard: dashboardDataReducer,
  imageGallery: imageGalleryDataReducer,
  inviteUser: inviteUserDataReducer,
  createShow: createShowDataReducer,
  showDetail: showDetailDataReducer,
  sidenav: sidenavDataReducer
};

/**Create all firestore reducers for adding it to the Store */
allFirestoreReducers.forEach(firebaseReducer => {
  reducers[firebaseReducer.reducerName] = firebaseReducer.createDataReducer();
});

export const metaReducers: MetaReducer<AppState>[] = [];

function logoutMetaReducer(reducer) {
  return function(state: AppState, action: Action) {
    return reducer(action.type === AuthActionsType.LOGOUT ? undefined : state, action);
  };
}

metaReducers.push(logoutMetaReducer);

// Effects
export const effects = [
  AuthEffects,
  CompaniesEffects,
  DashboardEffects,
  EmailEffects,
  FirebaseEffects,
  ImageGalleryEffects,
  InviteUserEffects,
  RegistrantEventMailListEffects,
  RegistrantEventMailStatusEffects,
  RegistrantsEffects,
  ShowDetailEffects,
  ShowEffects
];
