import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { RegistrantEventMailListReducer } from './registrant-event-mail-list.firestore-subcollection.reducer';
import { Observable } from 'rxjs';
import { withLatestFrom, map, tap, filter } from 'rxjs/operators';
import {
  AddedDataAction,
  ModifiedDataAction,
  createSortAction,
  SortActionOrder
} from '@store/firestore/firestore.actions';
import { registrantDataState } from '.';
import { Store, select, Action } from '@ngrx/store';
import { AppState } from '..';
import { RegistrantReducer } from './registrant.firestore.reducer';
import { FirestoreReduxService } from '@services/firebase/firestore-redux.service';
import {
  handleSubcollectionEffectAction,
  buildParentPath
} from '@store/firestore/firestore-subcollection-effect-helper';
import { Registrant } from '@core/models/registrant';
import { Entities } from '@store/firestore/firestore.reducer';

@Injectable()
export class RegistrantEventMailListEffects {
  private reducer = RegistrantEventMailListReducer.getInstance();

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private firestoreReduxService: FirestoreReduxService
  ) {}

  @Effect()
  addedOrModifiedModel$: Observable<ModifiedDataAction> = this.actions$.pipe(
    filter((action: Action) =>
      [this.reducer.ADDED_ACTION, this.reducer.MODIFIED_ACTION, this.reducer.MODIFIED_LOCALLY_ACTION].includes(
        action.type
      )
    ),
    tap((action: Action) => {
      if (action.type !== this.reducer.ADDED_ACTION) {
        return;
      }

      const innerAction = action as AddedDataAction;
      const parentRoute = buildParentPath(innerAction.ancestorsId, this.reducer.collectionName, innerAction.id);

      const sortAction = createSortAction('timestamp', SortActionOrder.ASC);

      this.firestoreReduxService.startRegistrantEventMailStatusFirestoreReduxListener(parentRoute, [sortAction]);
    }),
    withLatestFrom(this.store.pipe(select(registrantDataState))),
    map(
      ([action, { registrants }]): ModifiedDataAction => {
        const innerAction = action as AddedDataAction;
        const actionType = RegistrantReducer.getInstance().MODIFIED_LOCALLY_ACTION;
        const registrantsEntities = registrants as Entities<Registrant>;
        return handleSubcollectionEffectAction<Registrant>(
          innerAction,
          actionType,
          registrantsEntities,
          'eventMailList'
        );
      }
    )
  );
}
