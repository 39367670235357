export enum DashboardActionsType {
  INITIALIZE = '[Dashboard] Initialize',
  SHOWS_SEARCH = '[Dashboard] Shows search',
  SHOWS_FILTER = '[Dashboard] Shows filter',
  STRIPE_ERROR = '[Dashboard] Stripe error',
  SHOW_SELECTED = '[Dashboard] Show selected',
  SHOWS_UPDATED = '[Dashboard] Shows updated',
  LOAD_USER_SELF = '[Dashboard] Load Userself',
  LOAD_MY_COMPANY = '[Dashboard] Load my company',
  USER_SELF_LOADED = '[Dashboard] Userself loaded',
  ALL_SHOWS_DETAIL = '[Dashboard] All shows detail',
  REVENUES_UPDATED = '[Dashboard] Revenues updated',
  MY_COMPANY_LOADED = '[Dashboard] My company loaded',
  ATTENDEES_UPDATED = '[Dashboard] Attendees updated',
  LOAD_RTDB_COUNTERS = '[Dashboard] Load RTDB counters',
  SHOWS_PAGE_CHANGED = '[Dashboard] Shows page changed',
  REGISTRANTS_UPDATED = '[Dashboard] Registrants updated',
  URL_QUERIES_CHANGED = '[Dashboard] URL queries changed',
  LOAD_STRIPE_SESSION = '[Dashboard] Load stripe session',
  RTDB_COUNTERS_LOADED = '[Dashboard] RTDB counters loaded',
  STRIPE_SESSION_LOADED = '[Dashboard] Stripe session loaded',
  INTERFACE_MODE_CHANGED = '[Dashboard] Interface mode changed',
  TRY_TO_SET_INTERFACE_MODE = '[Dashboard] Try to set interface mode',
  LOAD_DEMO_PLAN_AND_ACTUAL_SUBSCRIPTION = '[Dashboard] Load demo plan and actual subscription',
  DEMO_PLAN_AND_ACTUAL_SUBSCRIPTION_LOADED = '[Dashboard] Demo plan and actual subscription loaded'
}
