import { Component, Input } from '@angular/core';
import { Dictionary } from '@core/types/dictionary.interface';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent {
  @Input()
  size: number;
  @Input()
  color: string;
  @Input()
  centered = false;

  get loadingStyle(): Dictionary {
    const size = this.size || 5;
    const color = this.color || '#662d90';
    return { 'width.em': size, 'height.em': size, color };
  }

  get classes(): Dictionary {
    return { centered: this.centered };
  }

  get borderWidth(): Dictionary {
    let borderWidth = Math.floor(this.size);
    if (borderWidth < 1) {
      borderWidth = 1;
    }
    return { 'border-width.px': borderWidth };
  }
}
