import { BaseModel } from './basemodel';
import { IChargePlan } from './company';

/**This class is refering to the 'events' collection */

export enum ShowKind {
  VIRTUAL = 'virtual',
  PHYSICAL = 'physical',
  LOTTERY = 'lottery'
}

export enum VirtualShowProvider {
  MEET = 'meet'
}
export class Show implements BaseModel {
  id?: string;
  name: string;
  endDate: Date;
  userId: string;
  startDate: Date;
  isActive: boolean;
  companyId: string;
  rsvpHTML: string;
  //Name extracted from firestore
  mettingPoint: string;
  sendMailAll: boolean;
  templateHTML: string;
  endDateOffset: number;
  startDateOffset: number;
  subscriptionId?: string;
  totalRegistrants: number;
  totalConfirmed?: number;
  chargePlan?: IChargePlan;
  virtualData?: IVirtualData;
  kind: ShowKind[] = [ShowKind.PHYSICAL];

  images?: {
    cardBackground?: string;
    cardLogo?: string;
    poster?: string;
    scannerLogo?: string;
  };

  venue: Venue;
}

export interface Venue {
  name: string;
  city: string;
  state: string;
  address: string;
  country: string;
  latitude: number;
  longitude: number;
}

export const isVirtualShow = (show: Show): boolean => {
  return show.kind.includes(ShowKind.VIRTUAL);
};

export const isPhysicalShow = (show: Show): boolean => {
  return !show.kind || show.kind.includes(ShowKind.PHYSICAL);
};

export const isMeetShow = (show: Show): boolean => {
  return show.virtualData.providers.includes(VirtualShowProvider.MEET);
};

export interface IVirtualData {
  meet?: {
    link: string;
    googleCalendarEventId: string;
  };
  providers: VirtualShowProvider[];
}
