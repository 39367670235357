import { FirestoreReducerDataState } from '@store/firestore/firestore.reducer';
// import { Timestamp } from 'firebase/firestore';
import { PlanSubscription } from '@core/models/plan-subscription';
import { FIRESTORE_COLLECTION_SUBSCRIPTIONS } from '@store/firestore/allFirestoreCollections';
import { subscriptionDataState } from '.';
import { MemoizedSelector, createSelector, select, Store } from '@ngrx/store';
import { myCompanySelector } from '@store/companies/company.firestore.reducer';
import { activeUsersCountSelector } from '@store/users/user.firestore.reducer';
import { ShowReducer } from '@store/shows/show.firestore.reducer';
import { Show } from '@core/models/show';
import { FirestoreCollectionReducer } from '@store/firestore/firestore-collection.reducer';
import { combineLatest, Observable } from 'rxjs';
import { demoPlanSelector } from '@store/plans/plan.firestore.reducer';
import { RegistrantReducer } from '@store/registrants/registrant.firestore.reducer';
import { map } from 'rxjs/operators';

export interface PlanSubscriptionDataState extends FirestoreReducerDataState<PlanSubscription> {}

export class PlanSubscriptionReducer extends FirestoreCollectionReducer<PlanSubscription, PlanSubscriptionDataState> {
  private static instance: PlanSubscriptionReducer;

  featureSelector = subscriptionDataState;

  INNER_COLLECTION_ID = 'subscriptionId';

  collectionName = FIRESTORE_COLLECTION_SUBSCRIPTIONS;

  ADD_ACTION = '[Subscription] Add subscription';
  UPSERT_ACTION = '[Subscription] Upsert subscription';
  DELETE_ACTION = '[Subscription] Delete subscription';
  MODIFY_ACTION = '[Subscription] Modify subscription';

  ADDED_ACTION = '[Subscription] Added subscription from firebase';
  DELETED_ACTION = '[Subscription] Deleted subscription from firebase';
  MODIFIED_ACTION = '[Subscription] Modified subscription from firebase';
  UPSERTED_ACTION = '[Subscription] Upserted subscription from firebase';
  LAST_DOCUMENT_SNAPSHOT = '[Subscription] Last documentSnapshot from firebase';
  MODIFIED_LOCALLY_ACTION = '[Subscription] Modify locally subscription';

  static getInstance() {
    if (!PlanSubscriptionReducer.instance) {
      PlanSubscriptionReducer.instance = new PlanSubscriptionReducer();
    }
    return PlanSubscriptionReducer.instance;
  }

  buildModel(data: object): PlanSubscription {
    const builtModel = super.buildModel(data);
    let subscriptionDate = builtModel.subscriptionDate as any;
    if (subscriptionDate) {
      subscriptionDate = subscriptionDate.toDate();
      builtModel.subscriptionDate = subscriptionDate;
    }

    return builtModel;
  }
}

export const actualSubscriptionSelector: MemoizedSelector<object, PlanSubscription> = createSelector(
  myCompanySelector,
  PlanSubscriptionReducer.getInstance().getAllEntitiesSelector(),
  (company, planSubscriptions) => {
    return company ? planSubscriptions[company.subscriptionId] : undefined;
  }
);

export const showsByActualSubscriptionSelector: MemoizedSelector<object, Show[]> = createSelector(
  actualSubscriptionSelector,
  ShowReducer.getInstance().getConvertToArraySelector(),
  (actualSubscription, allShows) => {
    if (!actualSubscription) {
      return [];
    }

    return allShows.filter(show => {
      return show.subscriptionId === actualSubscription.id;
    });
  }
);

export const showsByActualSubscriptionCountSelector: MemoizedSelector<object, number> = createSelector(
  showsByActualSubscriptionSelector,
  showsByActualSubscription => showsByActualSubscription.length
);

export const maxShowsLimitReachedSelector: MemoizedSelector<object, boolean> = createSelector(
  showsByActualSubscriptionCountSelector,
  actualSubscriptionSelector,
  (actualPlanShowsQty, actualSubscription) => {
    return actualSubscription && actualPlanShowsQty >= actualSubscription.eventQty;
  }
);

export const maxUserLimitReachedSelector: MemoizedSelector<object, boolean> = createSelector(
  activeUsersCountSelector,
  actualSubscriptionSelector,
  (activeUsersCount, actualSubscription) => {
    const maxUsers = actualSubscription && actualSubscription.maxUsers ? actualSubscription.maxUsers : 5;

    return actualSubscription && activeUsersCount >= maxUsers;
  }
);

export const isSubscriptionValidSelector: MemoizedSelector<object, boolean> = createSelector(
  actualSubscriptionSelector,
  maxShowsLimitReachedSelector,
  (actualSubscription, maxShowsLimitReached) => {
    if (!actualSubscription) {
      return undefined;
    }

    let maxDaysLimitReached = false;

    if (actualSubscription.maxDays > 0) {
      const today = Date.now();
      const maxHours = 24 * actualSubscription.maxDays;
      const elapsedHours = (today - actualSubscription.subscriptionDate.getTime()) / 60 / 60 / 1000;

      maxDaysLimitReached = elapsedHours > maxHours;
    }

    return !maxDaysLimitReached && !maxShowsLimitReached;
  }
);

export function maxShowInvitesIfTrialSubscription(
  show: Show,
  store: Store<any>
): Observable<{ maxInvites: number; actualInvites: number }> {
  if (!show) {
    return;
  }
  const showSubscriptionSelector = PlanSubscriptionReducer.getInstance().getEntityByIdSelector(show.subscriptionId);

  const showInvites = RegistrantReducer.getInstance().getInvitedByShowIdSelector(show.id);

  return combineLatest([
    store.pipe(select(actualSubscriptionSelector)),
    store.pipe(select(demoPlanSelector)),
    store.pipe(select(showSubscriptionSelector)),
    store.pipe(select(showInvites))
  ]).pipe(
    map(([actualSubscription, demoPlan, eventOriginalSubscription, actualInvitesArray]) => {
      const allDataPresent = demoPlan && actualSubscription;

      console.log('El show tiene chargePlan?', !show.chargePlan);
      console.log('actualSubscription', actualSubscription);
      console.log('demoPlan', demoPlan);
      console.log('eventOriginalSubscription', eventOriginalSubscription);
      console.log('actualInvitesArray', actualInvitesArray);
      console.log('allDataPresent', allDataPresent);

      if (!allDataPresent) {
        return { maxInvites: 0, actualInvites: 0 };
      }

      /// If show.chargePlan is defined it's because it's not using subscription but the new chargePlan model
      const createdUsingSubscription = !show.chargePlan;
      const actualPlanIsDemo = !createdUsingSubscription && demoPlan.id === actualSubscription.planId;
      console.log('actualPlanIsDemo', actualPlanIsDemo);
      const eventCreatedInDemoSubscription = eventOriginalSubscription
        ? eventOriginalSubscription.planId === demoPlan.id
        : actualPlanIsDemo;

      //-1 means subscription was/is not trial subscription
      let maxInvites = -1;

      if (allDataPresent && createdUsingSubscription && eventCreatedInDemoSubscription) {
        maxInvites = eventOriginalSubscription ? eventOriginalSubscription.maxInvites : actualSubscription.maxInvites;
      }

      const actualInvites = actualInvitesArray.length;
      console.log('maxInvites', maxInvites);
      console.log('actualInvites', actualInvites);
      return { maxInvites, actualInvites };
    })
  );
}
