import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, combineLatest, of, from } from 'rxjs';
import { DashboardActionsType } from './dashboard.actions.name';
import { switchMap, map, withLatestFrom, delay, tap, first, filter } from 'rxjs/operators';
import { Action, Store, select, MemoizedSelector } from '@ngrx/store';
import { AppState } from '..';
import {
  DashboardShowsChanged,
  DashboardShowsSearch,
  DashboardShowsFilter,
  DashboardLoadUserSelf,
  FILTER_UPCOMING,
  FILTER_PAST,
  DashboardUserSelfLoaded,
  DashboardMyCompanyLoaded,
  DashboardLoadMyCompany,
  DashboardLoadRTDBCounters,
  DashboardRTDBCountersLoaded,
  DashboardLoadPlanAndActualSubscription,
  DashboardPlanAndActualSubscriptionLoaded,
  DashboardTryToSetInterfaceMode,
  DashboardInterfaceModeChanged,
  DashboardStripeSessionLoaded
} from './dashboard.actions';
import { ShowReducer } from '@store/shows/show.firestore.reducer';
import { Show } from '@core/models/show';
import { dashboardDataState } from '.';
import { authDataState } from '@store/auth';
import { myCompanySelector } from '@store/companies/company.firestore.reducer';
import { Company, CompanyCounters } from '@core/models';
import { AngularFireDatabase } from '@angular/fire/database';
import {
  actualSubscriptionSelector,
  isSubscriptionValidSelector
} from '@store/plan-subscription/plan-subscription.firestore.reducer';
import { demoPlanSelector } from '@store/plans/plan.firestore.reducer';
import { DashboardInterfaceMode } from './dashboard.reducer';
import { AuthService } from '@services/auth/auth.service';
import { ApiService } from '@services/firebase/api.service';

@Injectable()
export class DashboardEffects {
  private showReducer: ShowReducer;
  private actualFilter: MemoizedSelector<object, Show[]>;

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private dbRealtime: AngularFireDatabase,
    private authService: AuthService,
    private apiService: ApiService
  ) {
    this.showReducer = ShowReducer.getInstance();
    this.actualFilter = this.showReducer.getConvertToArraySelector();
  }

  @Effect({ dispatch: false })
  initialize$: Observable<Action> = this.actions$.pipe(
    ofType(DashboardActionsType.INITIALIZE),
    tap(_ => this.store.dispatch(new DashboardLoadUserSelf())),
    tap(_ => this.store.dispatch(new DashboardLoadMyCompany())),
    tap(_ => this.store.dispatch(new DashboardLoadPlanAndActualSubscription())),
    tap(_ => this.store.dispatch(new DashboardTryToSetInterfaceMode(DashboardInterfaceMode.INITIAL)))
  );

  @Effect()
  loadUserSelf$: Observable<Action> = this.actions$.pipe(
    ofType(DashboardActionsType.LOAD_USER_SELF),
    switchMap(_ =>
      this.store.pipe(
        select(authDataState),
        delay(1000)
      )
    ),
    map(({ userSelf }) => new DashboardUserSelfLoaded(userSelf))
  );

  @Effect()
  loadDemoPlanAndActualSubscription$: Observable<Action> = this.actions$.pipe(
    ofType(DashboardActionsType.LOAD_DEMO_PLAN_AND_ACTUAL_SUBSCRIPTION),
    switchMap(_ =>
      combineLatest([this.store.pipe(select(actualSubscriptionSelector)), this.store.pipe(select(demoPlanSelector))])
    ),
    map(([actualSubscription, demoPlan]) => new DashboardPlanAndActualSubscriptionLoaded(actualSubscription, demoPlan))
  );

  @Effect()
  loadMyCompany$: Observable<Action> = this.actions$.pipe(
    ofType(DashboardActionsType.LOAD_MY_COMPANY),
    switchMap(_ => this.store.pipe(select(myCompanySelector)).pipe(filter(myCompany => !!myCompany))),
    map((myCompany: Company) => {
      return new DashboardMyCompanyLoaded(myCompany);
    })
  );

  @Effect({ dispatch: false })
  myCompanyLoaded$: Observable<Action> = this.actions$.pipe(
    ofType(DashboardActionsType.MY_COMPANY_LOADED),
    filter(({ myCompany }) => !!myCompany),
    tap((action: DashboardMyCompanyLoaded) => {
      const { myCompany } = action;
      this.store.dispatch(new DashboardLoadRTDBCounters(myCompany.companyId));
    })
  );

  @Effect()
  loadRTDBCounters$: Observable<Action> = this.actions$.pipe(
    ofType(DashboardActionsType.LOAD_RTDB_COUNTERS),
    switchMap((action: DashboardLoadRTDBCounters) => {
      const { companyId } = action;
      return this.dbRealtime.object(`companies/${companyId}`).valueChanges();
    }),
    map((counters: CompanyCounters) => new DashboardRTDBCountersLoaded(counters))
  );

  @Effect()
  tryToSetInterfaceMode$: Observable<Action> = this.actions$.pipe(
    ofType(DashboardActionsType.TRY_TO_SET_INTERFACE_MODE),
    switchMap((action: DashboardTryToSetInterfaceMode) =>
      combineLatest([
        of(action.dashboardInterfaceMode),
        this.store
          .pipe(select(isSubscriptionValidSelector))
          .pipe(filter(isSubscriptionValid => isSubscriptionValid !== undefined)),
        this.store.select(dashboardDataState).pipe(filter(dashboardState => dashboardState.thereAreShows !== undefined))
      ]).pipe(first())
    ),
    switchMap(([dashboardInterfaceMode, isSubscriptionValid, { myCompany, thereAreShows }]) => {
      const { stripePaymentMethodId, lastPaymentFailed, customPaymentMethod } = myCompany;
      const hasStripePaymentMethod = !!stripePaymentMethodId;
      const canMakePayment = (hasStripePaymentMethod && !lastPaymentFailed) || !!customPaymentMethod;
      const canCreateNewShow = isSubscriptionValid || canMakePayment;

      const finalActions: Action[] = [];
      let finalDashboardInterfaceMode = dashboardInterfaceMode;

      function showCardDialog() {
        finalDashboardInterfaceMode = DashboardInterfaceMode.NORMAL;
        finalActions.push(new DashboardInterfaceModeChanged(DashboardInterfaceMode.CARD_DIALOG));
      }

      switch (dashboardInterfaceMode) {
        case DashboardInterfaceMode.INITIAL:
          if (thereAreShows === false && canCreateNewShow) {
            finalActions.push(new DashboardInterfaceModeChanged(DashboardInterfaceMode.STEPPER));
          } else if (!canCreateNewShow) {
            showCardDialog();
          }
        case DashboardInterfaceMode.STEPPER:
          if (!canCreateNewShow) {
            showCardDialog();
          }
          break;
      }

      finalActions.push(new DashboardInterfaceModeChanged(finalDashboardInterfaceMode));
      return finalActions;
    })
  );

  @Effect()
  showSearch$: Observable<Action> = this.actions$.pipe(
    ofType(DashboardActionsType.SHOWS_SEARCH),
    switchMap((action: DashboardShowsSearch) => {
      const text = action.search;
      let showsSelector = this.showReducer.getShowsByTextSelector(action.search, this.actualFilter);
      if (text.trim().length === 0) {
        showsSelector = this.actualFilter;
      }

      const showsAction = this.store.pipe(select(showsSelector)).pipe(map(shows => new DashboardShowsChanged(shows)));
      return showsAction;
    })
  );

  @Effect()
  loadStripeSession$: Observable<Action> = this.actions$.pipe(
    ofType(DashboardActionsType.LOAD_STRIPE_SESSION),
    switchMap(() => from(this.authService.getJwt())),
    switchMap(jwt => from(this.apiService.getStripeSessionId(jwt))),
    switchMap(response => {
      const finalActions: Action[] = [];
      const { isError, data } = response;
      if (isError) {
        finalActions.push(new DashboardInterfaceModeChanged(DashboardInterfaceMode.NORMAL));
        return finalActions;
      }
      const sessionId = data.sessionId;

      finalActions.push(new DashboardStripeSessionLoaded(sessionId));
      finalActions.push(new DashboardInterfaceModeChanged(DashboardInterfaceMode.CARD_FORM));
      return finalActions;
    })
  );

  @Effect()
  stripeError$: Observable<Action> = this.actions$.pipe(
    ofType(DashboardActionsType.STRIPE_ERROR),
    map(_ => new DashboardInterfaceModeChanged(DashboardInterfaceMode.NORMAL))
  );

  @Effect()
  showFilter$: Observable<Action> = this.actions$.pipe(
    ofType(DashboardActionsType.SHOWS_FILTER),
    withLatestFrom(this.store.pipe(select(dashboardDataState))),
    map(([action, { search }]) => {
      const tabFilter = (action as DashboardShowsFilter).filter;
      switch (tabFilter) {
        case FILTER_UPCOMING:
          this.actualFilter = this.showReducer.getUpcomingShowsSelector();
          break;
        case FILTER_PAST:
          this.actualFilter = this.showReducer.getPastShowsSelector();
          break;
        default:
          this.actualFilter = this.showReducer.getConvertToArraySelector();
          break;
      }

      return new DashboardShowsSearch(search);
    })
  );
}
