import { Action } from '@ngrx/store';
import { InviteUserActionsType } from './invite-user.actions.name';

export type InviteUserActions = InviteUser | UserInvited | InviteUserError;

export class InviteUser implements Action {
  readonly type = InviteUserActionsType.INVITE_USER;
  constructor(public email: string, public companyId: string, public superUserId: string) {}
}

export class InviteUserError implements Action {
  readonly type = InviteUserActionsType.INVITE_USER_ERROR;
  constructor(public error: string) {}
}

export class UserInvited implements Action {
  readonly type = InviteUserActionsType.USER_INVITED;
}
