import { Action } from '@ngrx/store';
import { Show } from '@core/models/show';
import { EmailActionsType } from '@store/email/email.actions.name';

export type EmailAction = EmailLoadShow | EmailSaveEmail | EmailEmailSaved | EmailShowLoaded;

export class EmailLoadShow implements Action {
  readonly type = EmailActionsType.LOAD_SHOW;
  constructor(public showId: string) {}
}

export class EmailSaveEmail implements Action {
  readonly type = EmailActionsType.SAVE_EMAIL;
  constructor(public show: Show, public html: { rsvpHTML?: string; templateHTML?: string }) {}
}

export class EmailEmailSaved implements Action {
  readonly type = EmailActionsType.EMAIL_SAVED;
  constructor(public show: Show) {}
}

export class EmailShowLoaded implements Action {
  readonly type = EmailActionsType.SHOW_LOADED;
  constructor(public show: Show) {}
}
