import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { Dictionary } from '@core/types/dictionary.interface';

interface SnackbarData {
  msg: string;
  txtBtn?: string;
  btnLink?: string;
  type?: string;
}
@Component({
  selector: 'app-snack-bar',
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss']
})
export class SnackBarComponent {
  constructor(
    public snackbarRef: MatSnackBarRef<SnackBarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: SnackbarData
  ) {}

  get message(): string {
    return this.data.msg ? this.data.msg : '';
  }

  get txtBtn(): string | boolean {
    return this.data.txtBtn ? this.data.txtBtn : false;
  }

  get btnLink(): string {
    return this.data.btnLink ? this.data.btnLink : '';
  }

  get cntClass(): Dictionary {
    const type = this.data.type;
    return { error: type === 'error', warning: type === 'warning', info: type === 'info' };
  }

  get icon(): string {
    return this.data.type || 'check_circle';
  }

  close() {
    this.snackbarRef.dismiss();
  }
}
