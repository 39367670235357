import { FirestoreReduxListener } from '@store/firestore/firestore.redux.listener';
import { AngularFirestore } from '@angular/fire/firestore';
import { Store } from '@ngrx/store';
import {
  RegistrantEventMailListReducer,
  RegistrantEventMailListDataState
} from './registrant-event-mail-list.firestore-subcollection.reducer';
import { BaseModel } from '@core/models/basemodel';
import { FirestoreCollectionReference } from '@core/models/firestore-collection-reference';

export class RegistrantEventMailListFirestoreReduxListener extends FirestoreReduxListener<
  BaseModel,
  RegistrantEventMailListDataState
> {
  constructor(
    db: AngularFirestore,
    store: Store<RegistrantEventMailListDataState>,
    firestoreCollectionReference: FirestoreCollectionReference
  ) {
    super(db, store, RegistrantEventMailListReducer.getInstance());

    this.initializeCollectionWithQuery(firestoreCollectionReference);
    this.startListening();
  }
}
