export const wodenConsole = `
I8,        8        ,8I                      88                           
'8b       d8b       d8'                      88                           
 "8,     ,8"8,     ,8"   """""""             88                           
  Y8     8P Y8     8P   ,adPPYba,    ,adPPYb,88   ,adPPYba,  8b,dPPYba,   
  '8b   d8' '8b   d8'  a8"     "8a  a8"     Y88  a8P_____88  88P'   '"8a  
   '8a a8'   '8a a8'   8b       d8  8b       88  8PP"""""""  88       88  
    '8a8'     '8a8'    "8a,   ,a8"  "8a,   ,d88  "8b,   ,aa  88       88  
     '8'       '8'      '"YbbdP"'    '"8bbdP"Y8   '"Ybbd8"'  88       88  
`;

export const wodenVersion = ([major, minor, patch]: string[]) => `
                              +-+-+-+-+-+
                              |  ${major}.${minor}.${patch}  |
                              +-+-+-+-+-+
`;
