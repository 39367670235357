import { BaseModel } from './basemodel';

export interface ApiKey {
  createdBy: string;
  key: string;
  lastCreated: any;
}
export class Company implements BaseModel {
  id?: string;
  name: string;
  planId: string;
  companyId: string;
  isActive: boolean;
  subscriptionId?: string;
  preferences: Preference;
  apiKey?: ApiKey;
  chargePlan?: IChargePlan;
  stripeCustomerId?: string;
  lastPaymentFailed: boolean;
  customPaymentMethod: boolean; // Use this to signal that Stripe won't be used for payments and the billing will be handled internally (like Royalty)
  stripePaymentMethodId?: string;
  googleOAuthTokens?: GoogleOAuthTokens;
}

export interface IChargePlan {
  defaultFeeAmount: number;
  registrantFeePercentage: number;
  manualOrListFeeAmount: number;
}

interface Preference {
  /**Name used in firestore */
  Pin: string;
  bbcMail: string;
  imageProfile: string;
  /**Name used in firestore */
  EmailCopyHidden: string;
}

interface GoogleOAuthTokens {
  refreshToken: string;
}
