import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';

import { SidenavDataState } from '@store/sidenav/sidenav.reducer';
import { ShowSidenav, ToggleToolbar } from '@store/sidenav/sidenav.actions';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit, OnDestroy {
  constructor(private store: Store<SidenavDataState>) {}
  ngOnInit(): void {
    this.store.dispatch(new ShowSidenav(false));
    this.store.dispatch(new ToggleToolbar(false));
  }
  ngOnDestroy(): void {
    this.store.dispatch(new ShowSidenav(true));
    this.store.dispatch(new ToggleToolbar(true));
  }
}
