export class WodenDate {
  //Date if every event date value would be the same but done at London
  private fakeUTC: Date;
  private offset: number;

  static fromPCTime(date: Date, offset: number): WodenDate {
    const wodenDate = new WodenDate();
    wodenDate.fakeUTC = WodenDate.cloneDate(date, true);
    wodenDate.offset = offset;
    return wodenDate;
  }

  static fromUTCTime(date: Date, offset: number): WodenDate {
    const wodenDate = new WodenDate();
    wodenDate.offset = offset;
    wodenDate.fakeUTC = WodenDate.createDateFromUTC(new Date(date.getTime() + offset * 1000));

    return wodenDate;
  }

  static fromFakeUTCTime(date: Date, offset: number): WodenDate {
    const wodenDate = new WodenDate();
    wodenDate.offset = offset;
    wodenDate.fakeUTC = date;

    return wodenDate;
  }

  //Function to create a date with the same year, month, day, hour and minutes in UTC or in localTime
  static cloneDate(originalDate: Date, setAsUTC: boolean = false): Date {
    const receivedDate = originalDate || new Date();

    if (!setAsUTC) {
      return new Date(originalDate.getTime());
    }

    const zDelimiter = 'Z';

    const year = receivedDate.getFullYear();
    const month = receivedDate.getMonth() < 9 ? `0${receivedDate.getMonth() + 1}` : receivedDate.getMonth() + 1;
    const day = receivedDate.getDate() < 10 ? `0${receivedDate.getDate()}` : receivedDate.getDate();
    const hour = receivedDate.getHours() < 10 ? `0${receivedDate.getHours()}` : receivedDate.getHours();
    const minutes = receivedDate.getMinutes() < 10 ? `0${receivedDate.getMinutes()}` : receivedDate.getMinutes();

    const newDate = new Date(`${year}-${month}-${day}T${hour}:${minutes}:00${zDelimiter}`);
    return newDate;
  }

  static createDateFromUTC(originalDate: Date): Date {
    const receivedDate = originalDate || new Date();

    const year = receivedDate.getUTCFullYear();
    const month = receivedDate.getUTCMonth();
    const day = receivedDate.getUTCDate();
    const hour = receivedDate.getUTCHours();
    const minutes = receivedDate.getUTCMinutes();

    const newDate = new Date(year, month, day, hour, minutes);
    return newDate;
  }

  convertToUTCTime(): Date {
    return new Date(this.fakeUTC.getTime() - this.offset * 1000);
  }

  convertToFakeUTCTime(): Date {
    return this.fakeUTC;
  }

  convertToPCTime(): Date {
    return WodenDate.cloneDate(this.fakeUTC, false);
  }
}
