import { Observable, from, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { switchMap, filter, catchError } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import {
  AddedDataAction,
  AddDataAction,
  ModifyDataAction,
  DeleteDataAction,
  UpsertDataAction
  // UpsertedDataAction
} from './firestore.actions';
import {
  allFirestoreAddEffectTypes,
  allFirestoreModifyEffectTypes,
  allFirestoreActionsToReducers,
  allFirestoreDeleteEffectTypes,
  allFirestoreUpsertEffectTypes
} from './firestore.effects.types';

/**Class created to handle asynchronous firestore related requests */

@Injectable()
export class FirebaseEffects {
  constructor(private actions$: Actions, private db: AngularFirestore) {}

  @Effect({ dispatch: false })
  upsertModel$ = this.actions$.pipe(
    filter(action => allFirestoreUpsertEffectTypes.includes(action.type)),
    switchMap((action: UpsertDataAction) => {
      const data = { ...action.data };
      const requestType = action.type;
      const reducer = allFirestoreActionsToReducers[requestType];
      const modelIdName = reducer.INNER_COLLECTION_ID;
      const collection = reducer.getCollectionReference(this.db, {});
      const id = data.id ? data.id : collection.ref.doc().id;

      reducer.modifyModelDataBeforeWriting(data);

      data.lastModified = new Date();

      const doc = collection.doc(id);
      data[modelIdName] = id;
      const modifyPromise = doc.set(data, { merge: true }).then(() => data);

      return from(modifyPromise).pipe(
        catchError(error => {
          console.log(`Error in upsertModel`, error);
          return of('Error in upsert Model', error);
        })
      );
    })
  );

  /**Keeps listening for an action to create a firestore model and does everything needed before calling ACTION_ADDED with the recently created document */
  @Effect({ dispatch: false })
  addModel$ = this.actions$.pipe(
    filter(action => allFirestoreAddEffectTypes.includes(action.type)),
    switchMap(
      (action: AddDataAction): Observable<AddedDataAction> => {
        const data = { ...action.data };
        const requestType = action.type;
        const reducer = allFirestoreActionsToReducers[requestType];
        const modelIdName = reducer.INNER_COLLECTION_ID;
        const collection = reducer.getCollectionReference(this.db, {});

        const newDocReference = collection.ref.doc();
        data.lastModified = new Date();
        data[modelIdName] = newDocReference.id;
        if (!data.isDeleted) {
          data.isDeleted = false;
        }

        const addPromise = newDocReference.set(data, { merge: true }).then(() => data);

        return from(addPromise).pipe(
          catchError(error => {
            console.log(`Error in addModel`, error);
            return of('Error in add Model', error);
          })
        );
      }
    )
  );

  @Effect({ dispatch: false })
  modifyModel$ = this.actions$.pipe(
    filter(action => allFirestoreModifyEffectTypes.includes(action.type)),
    switchMap((action: ModifyDataAction) => {
      const data = { ...action.data };
      const id = data.id;
      const requestType = action.type;
      const reducer = allFirestoreActionsToReducers[requestType];
      const collection = reducer.getCollectionReference(this.db, {});

      data.lastModified = new Date();

      reducer.modifyModelDataBeforeWriting(data);

      const modifyPromise = collection
        .doc(id)
        .set(data, { merge: true })
        .then();

      return from(modifyPromise).pipe(
        catchError(error => {
          console.log(`Error in modifyModel`, error);
          return of('Error in modify Model', error);
        })
      );
    })
  );

  @Effect({ dispatch: false })
  deleteModel$ = this.actions$.pipe(
    filter(action => allFirestoreDeleteEffectTypes.includes(action.type)),
    switchMap((action: DeleteDataAction) => {
      const id = action.id;
      const requestType = action.type;
      const reducer = allFirestoreActionsToReducers[requestType];
      const collection = reducer.getCollectionReference(this.db, {});

      const lastModified = new Date();
      const isDeleted = true;

      const modifyPromise = collection
        .doc(id)
        .set({ lastModified, isDeleted }, { merge: true })
        .then();

      return from(modifyPromise).pipe(
        catchError(error => {
          console.log(`Error in deleteModel`, error);
          return of('Error in delete Model', error);
        })
      );
    })
  );
}
