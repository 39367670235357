import { Action } from '@ngrx/store';
import { CreateShowActionsType } from './create-show.actions.name';
import { Show } from '@core/models/show';
import { Ticket } from '@core/models/ticket';
import { Registrant } from '@core/models/registrant';

export type CreateShowActions =
  | AddShowAction
  | AddPosterToShow
  | AddTicketAction
  | EditTicketAction
  | EditedTicketAction
  | CancelEditedTicketAction
  | RemoveTicketAction
  | AddRegistrantList
  | AssignTicketRegistrantList
  | DeleteRegistrantList
  | UnassignRegistrantList
  | ResetCreateShow
  | ShowUpsertStarted
  | ShowUpsertFinished;

export class AddShowAction implements Action {
  readonly type = CreateShowActionsType.ADD_SHOW;
  constructor(public show: Show) {}
}
export class AddPosterToShow implements Action {
  readonly type = CreateShowActionsType.ADD_POSTER_TO_SHOW;
  constructor(public poster: string) {}
}

export class AddTicketAction implements Action {
  readonly type = CreateShowActionsType.ADD_TICKET;
  constructor(public ticket: Ticket) {}
}

export class EditTicketAction implements Action {
  readonly type = CreateShowActionsType.EDIT_TICKET;
  constructor(public ticket: Ticket) {}
}

export class EditedTicketAction implements Action {
  readonly type = CreateShowActionsType.EDITED_TICKET;
  constructor(public ticket: Ticket) {}
}

export class CancelEditedTicketAction implements Action {
  readonly type = CreateShowActionsType.EDITED_TICKET_CANCELED;
}

export class RemoveTicketAction implements Action {
  readonly type = CreateShowActionsType.REMOVE_TICKET;
  constructor(public id: string) {}
}

export class AddRegistrantList implements Action {
  readonly type = CreateShowActionsType.ADD_REGISTRANT_LIST;
  constructor(readonly listId: string, readonly name: string, readonly elements: Registrant[]) {}
}

export class AssignTicketRegistrantList implements Action {
  readonly type = CreateShowActionsType.ASSIGN_TICKET_REGISTRANT_LIST;
  constructor(readonly listId: string, readonly ticketId: string) {}
}

export class DeleteRegistrantList implements Action {
  readonly type = CreateShowActionsType.DELETE_REGISTRANT_LIST;
  constructor(readonly listId: string) {}
}

export class UnassignRegistrantList implements Action {
  readonly type = CreateShowActionsType.UNASSIGN_REGISTRANT_LIST;
  constructor(readonly listId: string) {}
}

export class ResetCreateShow implements Action {
  readonly type = CreateShowActionsType.RESET_CREATE_SHOW_DATA;
}

export class ShowUpsertStarted implements Action {
  readonly type = CreateShowActionsType.SHOW_UPSERT_STARTED;
}

export class ShowUpsertFinished implements Action {
  readonly type = CreateShowActionsType.SHOW_UPSERT_FINISHED;
}
