import { createReducer, on } from '@ngrx/store';
import { ChikettoImage } from '@models/chiketto-image';
import {
  loadImageGallery,
  loadImageGallerySuccess,
  loadImageGalleryFailure,
  noEventImageGallery
} from './image-gallery.actions';

export const imageGalleryFeatureKey = 'imageGallery';

export interface ImageGalleryDataState {
  chikettoImages: ChikettoImage[];
  nextQuery: string;
  error: string;
  isLoading: boolean;
  isThereMoreToLoad: boolean;
}

export const initialState: ImageGalleryDataState = {
  chikettoImages: [],
  nextQuery: '',
  error: '',
  isLoading: false,
  isThereMoreToLoad: true
};

export const imageGalleryDataReducer = createReducer(
  initialState,
  on(loadImageGallery, state => {
    return { ...state, isLoading: true };
  }),

  on(noEventImageGallery, state => {
    return { ...state, isLoading: false };
  }),

  on(loadImageGallerySuccess, (state, data) => {
    const { chikettoImages, nextQuery } = data;
    const actualImages = [...state.chikettoImages];
    actualImages.push(...chikettoImages);
    return {
      ...state,
      isLoading: false,
      chikettoImages: actualImages,
      nextQuery,
      isThereMoreToLoad: !!nextQuery
    };
  }),

  on(loadImageGalleryFailure, (state, data) => {
    const { error } = data;
    return { ...state, isLoading: false, error };
  })
);
