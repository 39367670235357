import { CreateShowActions } from './create-show.actions';
import { CreateShowActionsType } from './create-show.actions.name';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import { createShowDataState } from '.';
import { Show } from '@core/models/show';
import { Ticket } from '@core/models/ticket';
import { List } from '@core/types/list.interface';
import { insertInArray, updateInArray } from '@shared/tools/array-operators';
import { Registrant } from '@core/models/registrant';

export interface CreateShowDataState {
  show: Show;
  lists: List<Registrant>[];
  loading: boolean;
  editingTicket: Ticket;
  tickets: { [id: string]: Ticket };
  deletedTickets: { [id: string]: Ticket };
  upsertStarted: boolean;
  upsertFinished: boolean;
  selectedTicketsByListId: { [listId: string]: string };
}

export const initialState: CreateShowDataState = {
  show: undefined,
  lists: [],
  loading: false,
  tickets: {},
  deletedTickets: {},
  upsertStarted: false,
  upsertFinished: false,
  editingTicket: undefined,
  selectedTicketsByListId: {}
};

export function createShowDataReducer(state = initialState, action: CreateShowActions): CreateShowDataState {
  const tickets = { ...state.tickets };
  switch (action.type) {
    case CreateShowActionsType.ADD_SHOW: {
      const show = action.show;
      return { ...state, show };
    }
    case CreateShowActionsType.ADD_POSTER_TO_SHOW: {
      const poster = action.poster;
      const images = { ...state.show.images, poster };

      const show = { ...state.show, images };
      return { ...state, show };
    }
    case CreateShowActionsType.ADD_TICKET:
      const ticket = action.ticket;
      tickets[ticket.id] = ticket;
      return { ...state, tickets };
    case CreateShowActionsType.EDIT_TICKET:
      const editingTicket = action.ticket;
      return { ...state, editingTicket };
    case CreateShowActionsType.EDITED_TICKET_CANCELED:
      return { ...state, editingTicket: undefined };
    case CreateShowActionsType.EDITED_TICKET:
      const editedTicket = action.ticket;
      tickets[editedTicket.id] = editedTicket;
      return { ...state, tickets, editingTicket: undefined };
    case CreateShowActionsType.REMOVE_TICKET:
      const id = action.id;
      const ticketToDelete = tickets[id];
      delete tickets[id];
      const deletedTickets = { ...state.deletedTickets, id: ticketToDelete };
      return { ...state, tickets, deletedTickets };
    case CreateShowActionsType.ADD_REGISTRANT_LIST: {
      const { listId, elements, name } = action;
      const newList: List<Registrant> = { id: listId, name, elements };
      const lists = insertInArray(state.lists, newList);

      return { ...state, lists };
    }
    case CreateShowActionsType.ASSIGN_TICKET_REGISTRANT_LIST: {
      const { ticketId, listId } = action;

      //Update all registrants in list with ticketId and eventId
      const innerTicket = state.tickets[ticketId];
      const currentList = state.lists.find((list: List<Registrant>) => list.id === listId);
      let newList = { ...currentList };

      if (currentList) {
        const elements = currentList.elements.map((registrant: Registrant) => ({
          ...registrant,
          ticketId,
          price: innerTicket.price,
          eventId: state.show.id
        }));
        newList = { ...currentList, elements };
        //Update selected ticketIds list and mapping with listId: ticketId
        const selectedTicketsByListId = { ...state.selectedTicketsByListId, [listId]: ticketId };
        const lists = updateInArray(state.lists.slice(), newList);
        return { ...state, lists, selectedTicketsByListId };
      }

      return state;
    }
    case CreateShowActionsType.DELETE_REGISTRANT_LIST: {
      const listId = action.listId;
      const allLists = state.lists.slice();
      const selectedTicketsByListId = { ...state.selectedTicketsByListId };

      const index = allLists.findIndex(list => list.id === listId);
      allLists.splice(index, 1);

      delete selectedTicketsByListId[listId];

      return { ...state, lists: allLists, selectedTicketsByListId };
    }
    case CreateShowActionsType.SHOW_UPSERT_STARTED:
      return { ...state, loading: true, upsertStarted: true, upsertFinished: false };
    case CreateShowActionsType.SHOW_UPSERT_FINISHED:
      return { ...state, loading: false, upsertStarted: false, upsertFinished: true };
    case CreateShowActionsType.RESET_CREATE_SHOW_DATA: {
      return initialState;
    }
    default:
      return state;
  }
}

export const showSelector = createSelector(createShowDataState, state => {
  return state.show;
});

export const ticketsSelector = createSelector(createShowDataState, state => {
  return state.tickets;
});

export const convertCreatedTicketsToArraySelector = createSelector(ticketsSelector, tickets => {
  return Object.keys(tickets).map(ticketId => tickets[ticketId]);
});

export const deletedTicketsSelector = createSelector(createShowDataState, state => {
  return state.deletedTickets;
});

export const convertDeletedTicketsToArraySelector = createSelector(deletedTicketsSelector, deletedTickets => {
  return Object.keys(deletedTickets).map(ticketId => deletedTickets[ticketId]);
});

//Selector to extract tickets with assigned registrant list
export const selectedTicketIdSelector: MemoizedSelector<object, string[]> = createSelector(
  createShowDataState,
  ({ selectedTicketsByListId }) => {
    const selectedTicketIds = [];
    for (const key in selectedTicketsByListId) {
      if (selectedTicketsByListId.hasOwnProperty(key)) {
        const selectedTicketId = selectedTicketsByListId[key];
        selectedTicketIds.push(selectedTicketId);
      }
    }
    return selectedTicketIds;
  }
);

//Selector to extract tickets with no assigned registrant list
export const unselectedTicketsSelector = createSelector(
  createShowDataState,
  selectedTicketIdSelector,
  ({ tickets }, selectedTicketIds) => {
    return Object.keys(tickets)
      .map(ticketId => tickets[ticketId])
      .filter(ticket => !selectedTicketIds.includes(ticket.id));
  }
);
