import { Action } from '@ngrx/store';

import { SidenavActionsType } from './sidenav.actions.name';

export type SidenavAction = ShowSidenav | ToggleToolbar;

export class ShowSidenav implements Action {
  readonly type = SidenavActionsType.TOGGLE_SIDENAV;
  constructor(readonly value: boolean) {}
}

export class ToggleToolbar implements Action {
  readonly type = SidenavActionsType.TOGGLE_TOOLBAR;
  constructor(readonly value: boolean) {}
}

export class ToggleCMSList implements Action {
  readonly type = SidenavActionsType.TOGGLE_CMS_LIST;
  constructor(readonly cms: string) {}
}

export class SetMobileResoltion implements Action {
  readonly type = SidenavActionsType.SET_MOBILE;
  constructor(readonly isMobile: boolean) {}
}
