import { Show } from '@models/show';
import { showDataState } from '@store/shows';
// import { Timestamp } from 'firebase/firestore';
import { FIRESTORE_COLLECTION_SHOWS } from '@store/firestore/allFirestoreCollections';
import { FirestoreReducerDataState } from '@store/firestore/firestore.reducer';
import { MemoizedSelector, createSelector } from '@ngrx/store';
import { removeDiacritics } from '@shared/helpers';
import { FirestoreCollectionReducer } from '@store/firestore/firestore-collection.reducer';
import { WodenDate } from '@core/models/woden-date';

export interface ShowDataState extends FirestoreReducerDataState<Show> {}

export class ShowReducer extends FirestoreCollectionReducer<Show, ShowDataState> {
  private static instance: ShowReducer;

  featureSelector = showDataState;
  collectionName = FIRESTORE_COLLECTION_SHOWS;

  INNER_COLLECTION_ID = 'eventId';

  ADD_ACTION = '[Show] Add show';
  UPSERT_ACTION = '[Show] Upsert show';
  DELETE_ACTION = '[Show] Delete show';
  MODIFY_ACTION = '[Show] Modify show';

  ADDED_ACTION = '[Show] Added show from firebase';
  DELETED_ACTION = '[Show] Deleted show from firebase';
  MODIFIED_ACTION = '[Show] Modified show from firebase';
  UPSERTED_ACTION = '[Show] Upserted show from firebase';
  LAST_DOCUMENT_SNAPSHOT = '[Show] Last documentSnapshot from firebase';
  MODIFIED_LOCALLY_ACTION = '[Show] Modify locally show';

  static getInstance() {
    if (!ShowReducer.instance) {
      ShowReducer.instance = new ShowReducer();
    }

    return ShowReducer.instance;
  }

  buildModel(data: object): Show {
    const builtModel = super.buildModel(data);
    let startDate = builtModel.startDate as any;
    if (startDate) {
      startDate = startDate.toDate();

      const startDateOffset = builtModel.startDateOffset || 0;
      const wodenDate = WodenDate.fromUTCTime(startDate, startDateOffset);

      builtModel.startDate = wodenDate.convertToPCTime();
    }

    let endDate = builtModel.endDate as any;
    if (endDate) {
      endDate = endDate.toDate();

      const endDateOffset = builtModel.endDateOffset || 0;
      const wodenDate = WodenDate.fromUTCTime(endDate, endDateOffset);

      builtModel.endDate = wodenDate.convertToPCTime();
    }

    return builtModel;
  }

  getShowsByTextSelector(text = '', selector: MemoizedSelector<object, Show[]>): MemoizedSelector<object, Show[]> {
    text = removeDiacritics(text);
    const regex = new RegExp(`${text.toLowerCase()}`);
    const selectorToCreate = createSelector(selector, shows => {
      return shows.filter(show => {
        const showName = removeDiacritics(show.name.toLowerCase());
        let matched = !!showName.match(regex);
        if (!matched && show.venue) {
          matched =
            !!removeDiacritics(show.venue.name.toLowerCase()).match(regex) ||
            !!removeDiacritics(show.venue.country.toLowerCase()).match(regex) ||
            !!removeDiacritics(show.venue.state.toLowerCase()).match(regex) ||
            !!removeDiacritics(show.venue.city.toLowerCase()).match(regex);
        }

        return matched;
      });
    });

    return selectorToCreate;
  }

  getPastShowsSelector(): MemoizedSelector<object, Show[]> {
    const CUSTOM_ID = 'PAST_SHOWS';

    const selectorToCreate = createSelector(this.getConvertToArraySelector(), shows => {
      const today = new Date();
      return shows.filter(show => {
        return show.startDate < today;
      });
    });

    return this.getOrCreateSelectorById(CUSTOM_ID, selectorToCreate);
  }

  getUpcomingShowsSelector(): MemoizedSelector<object, Show[]> {
    const CUSTOM_ID = 'UPCOMING_SHOWS';

    const selectorToCreate = createSelector(this.getConvertToArraySelector(), shows => {
      const today = new Date();
      return shows.filter(show => {
        return show.startDate >= today;
      });
    });

    return this.getOrCreateSelectorById(CUSTOM_ID, selectorToCreate);
  }

  getShowsBySubscriptionSelector(subscriptionId: string): MemoizedSelector<object, Show[]> {
    const CUSTOM_ID = `SUBSCRIPTION_${subscriptionId}_SHOWS`;

    const selectorToCreate = createSelector(this.getConvertToArraySelector(), shows => {
      return shows.filter(show => {
        return show.subscriptionId === subscriptionId;
      });
    });

    return this.getOrCreateSelectorById(CUSTOM_ID, selectorToCreate);
  }
}
