import { User } from '@models/user';
import { Store } from '@ngrx/store';
import { UserReducer, UserDataState } from '@store/users/user.firestore.reducer';
import { AngularFirestore, QueryFn } from '@angular/fire/firestore';
import { FirestoreReduxListener } from '@store/firestore/firestore.redux.listener';

export class UserFirestoreReduxListener extends FirestoreReduxListener<User, UserDataState> {
  constructor(db: AngularFirestore, store: Store<UserDataState>, queryFn?: QueryFn) {
    super(db, store, UserReducer.getInstance());

    //When initializing this in the parent constructor, when listening to changes an error was shown: cannot call onSnapshot on undefined
    this.initializeCollectionWithQuery({ queryFn });
    this.startListening();
  }
}
