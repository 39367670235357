import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserInvitationEmail } from '@core/models/email-user-invitation';
import { userInvitationEmail } from '@shared/emails/user-invitation';
import { EventEmail } from '@core/models/email-event';
import { RSVPEmail } from '@core/models/email-rsvp';
import { WodenDate } from '@core/models/woden-date';
import { environment } from '@env';
import { CountPaidAndCreationDate } from '@core/types/count-paid-creation-date-interface';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private HEADER_APP_JSON = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(private http: HttpClient) {}

  private getBearer(jwt: string): HttpHeaders {
    return new HttpHeaders({
      Authorization: `Bearer ${jwt}`
    });
  }

  sendRSVPEmail(email: RSVPEmail): Observable<object> {
    return this.http.post(environment.api.rsvpEmailUrl, email, { headers: this.HEADER_APP_JSON });
  }

  sendEventEmail(email: EventEmail): Observable<object> {
    return this.http.post(environment.api.emailUrl, email, { headers: this.HEADER_APP_JSON });
  }

  sendInvitationEmail(email: string, password: string): Observable<object> {
    const html = userInvitationEmail;
    const emailToSend: UserInvitationEmail = {
      html,
      to: email,
      from: 'info@wodenevents.com',
      bcc: '',
      registrants: [],
      subject: 'Invitation',
      password,
      userName: email
    };

    return this.http.post(environment.api.invitationEmailUrl, emailToSend, { headers: this.HEADER_APP_JSON });
  }

  getLocationUTCOffset(lat: number, lng: number, dateInPCTime: Date): Observable<object> {
    const date = WodenDate.cloneDate(dateInPCTime, true);
    const timestamp = date.getTime();
    const params = {};
    params['lat'] = lat;
    params['lng'] = lng;
    params['timestamp'] = timestamp;
    return this.http.get(environment.api.getLocationOffsetUrl, { headers: this.HEADER_APP_JSON, params });
  }

  async getRegistrantsCountAndPaidSumByDate(showId: string, interval: string): Promise<CountPaidAndCreationDate[]> {
    const url = environment.api.bigQueryRegistrantsCountAndPaidSumByDateUrl;
    // const changedUrl = url.replace('https://us-central1-woden-dev.cloudfunctions.net/api', 'http://localhost:8080');
    return this.getChartData<CountPaidAndCreationDate>('', url, showId, interval);
  }

  private async getChartData<T>(jwt: string, url: string, showId: string, interval?: string): Promise<T[]> {
    try {
      if (interval) {
        url = url.concat(`?interval=${encodeURI(interval)}`);
      }
      if (showId) {
        url = url.concat(interval ? '&' : '?').concat(`eventId=${encodeURI(showId)}`);
      }
      return (await this.http.get(url, { headers: this.getBearer(jwt) }).toPromise()) as [];
    } catch (error) {
      console.log(`El error es:`, error);
    }
  }

  sendGoogleOAuthCode(code: string, userId: string): Observable<Object> {
    return this.http.post(environment.api.googleOAuthCode, { code, userId }, { headers: this.HEADER_APP_JSON });
  }

  async getStripeSessionId(jwt: string): Promise<{ isError: boolean; data: { sessionId: string } }> {
    const bearerHeader = this.getBearer(jwt);

    const response = await this.http.get(environment.api.stripeSessionIdUrl, { headers: bearerHeader }).toPromise();

    return response as any;
  }
}
