import { Action } from '@ngrx/store';
import { DashboardActionsType } from './dashboard.actions.name';
import { Show } from '../../core/models/show';
import { User, Company, CompanyCounters, Plan, PlanSubscription } from '@core/models';
import { DashboardInterfaceMode } from './dashboard.reducer';

export type DashboardAction =
  | DashboardInitialize
  | DashboardShowSelected
  | DashboardAllShowDetails
  | DashboardShowsSearch
  | DashboardShowsFilter
  | DashboardShowsChanged
  | DashboardRevenuesChanged
  | DashboardRegistrantsChanged
  | DashboardAttendeesChanged
  | DashboardShowsPageChanged
  | DashboardLoadUserSelf
  | DashboardUserSelfLoaded
  | DashboardLoadMyCompany
  | DashboardMyCompanyLoaded
  | DashboardRTDBCountersLoaded
  | DashboardUrlQueriesChanged
  | DashboardTryToSetInterfaceMode
  | DashboardInterfaceModeChanged
  | DashboardLoadPlanAndActualSubscription
  | DashboardPlanAndActualSubscriptionLoaded
  | DashboardLoadStripeSession
  | DashboardStripeSessionLoaded
  | DashboardStripeError;

export const FILTER_NONE = 0;
export const FILTER_UPCOMING = 1;
export const FILTER_PAST = 2;

export class DashboardInitialize implements Action {
  readonly type = DashboardActionsType.INITIALIZE;
  constructor() {}
}

export class DashboardShowSelected implements Action {
  readonly type = DashboardActionsType.SHOW_SELECTED;
  constructor(public showId: string) {}
}

export class DashboardAllShowDetails implements Action {
  readonly type = DashboardActionsType.ALL_SHOWS_DETAIL;
}

export class DashboardShowsSearch implements Action {
  readonly type = DashboardActionsType.SHOWS_SEARCH;
  constructor(public search: string) {}
}

export class DashboardShowsFilter implements Action {
  readonly type = DashboardActionsType.SHOWS_FILTER;
  constructor(public filter: number) {}
}

export class DashboardShowsChanged implements Action {
  readonly type = DashboardActionsType.SHOWS_UPDATED;
  constructor(public shows: Show[]) {}
}

export class DashboardRevenuesChanged implements Action {
  readonly type = DashboardActionsType.REVENUES_UPDATED;
  constructor(public revenues: number) {}
}

export class DashboardRegistrantsChanged implements Action {
  readonly type = DashboardActionsType.REGISTRANTS_UPDATED;
  constructor(public registrants: number) {}
}

export class DashboardAttendeesChanged implements Action {
  readonly type = DashboardActionsType.ATTENDEES_UPDATED;
  constructor(public attendees: number) {}
}

export class DashboardShowsPageChanged implements Action {
  readonly type = DashboardActionsType.SHOWS_PAGE_CHANGED;
  constructor(public pageIndex: number) {}
}

export class DashboardLoadUserSelf implements Action {
  readonly type = DashboardActionsType.LOAD_USER_SELF;
  constructor() {}
}
export class DashboardUserSelfLoaded implements Action {
  readonly type = DashboardActionsType.USER_SELF_LOADED;
  constructor(public userSelf: User) {}
}

export class DashboardTryToSetInterfaceMode implements Action {
  readonly type = DashboardActionsType.TRY_TO_SET_INTERFACE_MODE;
  constructor(public dashboardInterfaceMode: DashboardInterfaceMode) {}
}
export class DashboardInterfaceModeChanged implements Action {
  readonly type = DashboardActionsType.INTERFACE_MODE_CHANGED;
  constructor(public dashboardInterfaceMode: DashboardInterfaceMode) {}
}

export class DashboardLoadPlanAndActualSubscription implements Action {
  readonly type = DashboardActionsType.LOAD_DEMO_PLAN_AND_ACTUAL_SUBSCRIPTION;
  constructor() {}
}

export class DashboardPlanAndActualSubscriptionLoaded implements Action {
  readonly type = DashboardActionsType.DEMO_PLAN_AND_ACTUAL_SUBSCRIPTION_LOADED;
  constructor(public actualSubscription?: PlanSubscription, public demoPlan?: Plan) {}
}

export class DashboardLoadMyCompany implements Action {
  readonly type = DashboardActionsType.LOAD_MY_COMPANY;
  constructor() {}
}
export class DashboardMyCompanyLoaded implements Action {
  readonly type = DashboardActionsType.MY_COMPANY_LOADED;
  constructor(public myCompany: Company) {}
}

export class DashboardLoadRTDBCounters implements Action {
  readonly type = DashboardActionsType.LOAD_RTDB_COUNTERS;
  constructor(public companyId: string) {}
}

export class DashboardRTDBCountersLoaded implements Action {
  readonly type = DashboardActionsType.RTDB_COUNTERS_LOADED;
  constructor(public counters: CompanyCounters) {}
}
export class DashboardUrlQueriesChanged implements Action {
  readonly type = DashboardActionsType.URL_QUERIES_CHANGED;
  constructor(public urlQueries: { purchaseTried: boolean; purchaseSucceeded: boolean }) {}
}

export class DashboardLoadStripeSession implements Action {
  readonly type = DashboardActionsType.LOAD_STRIPE_SESSION;
  constructor() {}
}

export class DashboardStripeSessionLoaded implements Action {
  readonly type = DashboardActionsType.STRIPE_SESSION_LOADED;
  constructor(public stripeSessionId: string) {}
}

export class DashboardStripeError implements Action {
  readonly type = DashboardActionsType.STRIPE_ERROR;
  constructor(public errorMessage: string) {}
}
