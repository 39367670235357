import { FirestoreReducerDataState } from '@store/firestore/firestore.reducer';
import { Registrant } from '@core/models/registrant';
import { FIRESTORE_COLLECTION_REGISTRANTS } from '@store/firestore/allFirestoreCollections';
import { attendedRegistrantDataState } from '@store/registrants/attended-registrants';
import { MemoizedSelector, createSelector, Store } from '@ngrx/store';
import { removeDiacritics } from '@shared/helpers';
import { FirestoreCollectionReducer } from '@store/firestore/firestore-collection.reducer';
import { ModifiedDataAction } from '@store/firestore/firestore.actions';

export interface AttendedRegistrantDataState extends FirestoreReducerDataState<Registrant> {}

export class AttendedRegistrantReducer extends FirestoreCollectionReducer<Registrant, AttendedRegistrantDataState> {
  private static instance: AttendedRegistrantReducer;
  collectionName = FIRESTORE_COLLECTION_REGISTRANTS;
  featureSelector = attendedRegistrantDataState;

  INNER_COLLECTION_ID = 'idCardNumber';

  ADD_ACTION = '[AttendedRegistrants] Add registrant';
  UPSERT_ACTION = '[AttendedRegistrants] Upsert registrant';
  DELETE_ACTION = '[AttendedRegistrants] Delete registrant';
  MODIFY_ACTION = '[AttendedRegistrants] Modify registrant';

  ADDED_ACTION = '[AttendedRegistrants] Added registrant from firebase';
  DELETED_ACTION = '[AttendedRegistrants] Deleted registrant from firebase';
  MODIFIED_ACTION = '[AttendedRegistrants] Modified registrant from firebase';
  UPSERTED_ACTION = '[AttendedRegistrants] Upserted registrant firebase';
  LAST_DOCUMENT_SNAPSHOT = '[AttendedRegistrants] Last documentSnapshot from firebase';
  MODIFIED_LOCALLY_ACTION = '[AttendedRegistrants] Modify locally registrant';

  static getInstance() {
    if (!AttendedRegistrantReducer.instance) {
      AttendedRegistrantReducer.instance = new AttendedRegistrantReducer();
    }
    return AttendedRegistrantReducer.instance;
  }

  get reducerName(): string {
    return 'attended-registrants';
  }

  getFieldsToRemove(): string[] {
    const fieldsToRemove = super.getFieldsToRemove();
    fieldsToRemove.push('selected', 'eventMailList');
    return fieldsToRemove;
  }

  initializeFieldsToRemove(registrant: Registrant): Registrant {
    registrant.selected = false;
    registrant.eventMailList = [];

    return registrant;
  }

  ////////////////////////////////////////////////SHOW SELECTORS////////////////////////////////////////////////

  /**Selector in charge of extracting an array with all non deleted registrants per show  */
  getAttendedRegistrantsWithDeletedByShowIdSelector(showId: string): MemoizedSelector<object, Registrant[]> {
    const CUSTOM_ID = `SHOW_ATTENDED_${showId}_REGISTRANTS_WITH_DELETED`;
    const selectorToCreate = createSelector(this.getConvertToArrayWithDeletedSelector(), allRegistrants =>
      allRegistrants.filter(registrant => registrant.eventId === showId)
    );

    return this.getOrCreateSelectorById(CUSTOM_ID, selectorToCreate);
  }

  getAttendedRegistrantsWithDeletedCounterByShowIdSelector(showId: string): MemoizedSelector<object, number> {
    const CUSTOM_ID = `SHOW_ATTENDED_${showId}_REGISTRANTS_WITH_DELETED_COUNTER`;
    const selectorToCreate = createSelector(
      this.getAttendedRegistrantsWithDeletedByShowIdSelector(showId),
      allRegistrants => allRegistrants.length
    );

    return this.getOrCreateSelectorById(CUSTOM_ID, selectorToCreate);
  }

  /**Selector in charge of extracting an array with all non deleted registrants per show  */
  getRegistrantsByShowIdSelector(showId: string): MemoizedSelector<object, Registrant[]> {
    const CUSTOM_ID = `SHOW_ATTENDED_${showId}_REGISTRANTS`;
    const selectorToCreate = createSelector(this.getConvertToArraySelector(), allRegistrants =>
      allRegistrants.filter(registrant => registrant.eventId === showId)
    );

    return this.getOrCreateSelectorById(CUSTOM_ID, selectorToCreate);
  }

  getAttendeesByShowIdSelector(showId: string): MemoizedSelector<object, Registrant[]> {
    const CUSTOM_ID = `SHOW_${showId}_ATTENDEES`;
    const selectorToCreate = createSelector(this.getConvertToArraySelector(), registrantsByShowId =>
      registrantsByShowId.filter(registrant => registrant.eventId === showId && registrant.status === 1)
    );

    return this.getOrCreateSelectorById(CUSTOM_ID, selectorToCreate);
  }

  /**Selector in charge of extracting an array with all selected registrants per show  */
  getSelectedAttendeesByShowIdSelector(showId: string): MemoizedSelector<object, Registrant[]> {
    const CUSTOM_ID = `SHOW_${showId}_SELECTED_ATTENDEES`;
    const selectorToCreate = createSelector(this.getRegistrantsByShowIdSelector(showId), allRegistrants =>
      allRegistrants.filter(registrant => registrant.selected)
    );

    return this.getOrCreateSelectorById(CUSTOM_ID, selectorToCreate);
  }

  //SEARCH

  getRegistrantsByShowIdSearchSelector(
    text: string,
    selector: MemoizedSelector<object, Registrant[]>
  ): MemoizedSelector<object, Registrant[]> {
    text = removeDiacritics(text);
    const regex = new RegExp(`${text.toLowerCase()}`);
    const selectorToCreate = createSelector(selector, allRegistrants => {
      return allRegistrants.filter(registrant => {
        const fullName = removeDiacritics(registrant.fullName.toLowerCase());

        let matched = !!fullName.match(regex);

        if (!matched && registrant.email) {
          matched = !!removeDiacritics(registrant.email.toLowerCase()).match(regex);
        }

        return matched;
      });
    });

    return selectorToCreate;
  }

  ////////////////////////////////////////////////END SHOW SELECTORS////////////////////////////////////////////////
}

export function oneAttendedChangeSelectStatus(store: Store<any>, registrant: Registrant, selected: boolean): void {
  const reducer = AttendedRegistrantReducer.getInstance();

  const innerRegistrant = { ...registrant, selected };

  const actionType = reducer.MODIFIED_LOCALLY_ACTION;
  store.dispatch(new ModifiedDataAction(actionType, innerRegistrant.id, innerRegistrant));
}

export function multipleAttendedChangeSelectStatus(store: Store<any>, registrants: Registrant[], selected: boolean) {
  registrants
    .filter(registrant => registrant.selected !== selected)
    .forEach(registrant => oneAttendedChangeSelectStatus(store, registrant, selected));
}
