import { BaseModel } from '../../core/models/basemodel';
import { FirestoreReducer, FirestoreReducerDataState } from './firestore.reducer';
import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
import { MemoizedSelector } from '@ngrx/store';
import { FirestoreCollectionReference } from '@core/models/firestore-collection-reference';

/**Abstract class modeling Firestore Reducers from Subcollections.
 * It doesn't support collections*/

export abstract class FirestoreSubCollectionReducer<
  T extends BaseModel,
  DataState extends FirestoreReducerDataState<T>
> extends FirestoreReducer<T, DataState> {
  abstract parentCollectionName: string;
  collectionName: string;
  featureSelector: MemoizedSelector<object, DataState>;

  getCollectionReference(
    db: AngularFirestore,
    firestoreCollectionReference: FirestoreCollectionReference
  ): AngularFirestoreCollection {
    const { ancestorsId, queryFn } = firestoreCollectionReference;
    if (!ancestorsId) {
      throw new Error(`You must define parentId for ${this.collectionName}`);
    }

    const parentDoc = db.doc(ancestorsId);

    if (queryFn) {
      return parentDoc.collection<T>(this.collectionName, queryFn);
    } else {
      return parentDoc.collection<T>(this.collectionName);
    }
  }
}
