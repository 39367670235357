import { SidenavActionsType } from './sidenav.actions.name';

export interface SidenavDataState {
  opened: boolean;
  showToolbar: boolean;
  showCMSList: boolean;
  currentLang: string;
  isMobile: boolean;
}

export const initialState: SidenavDataState = {
  opened: true,
  showToolbar: true,
  showCMSList: false,
  currentLang: 'en',
  isMobile: false
};

export function sidenavDataReducer(state: SidenavDataState = initialState, action): SidenavDataState {
  switch (action.type) {
    case SidenavActionsType.TOGGLE_SIDENAV:
      const opened = action.value;
      return { ...state, opened };

    case SidenavActionsType.TOGGLE_TOOLBAR:
      const showToolbar = action.value;
      return { ...state, showToolbar };

    case SidenavActionsType.TOGGLE_CMS_LIST:
      return { ...state, showCMSList: !state.showCMSList };

    case SidenavActionsType.SET_MOBILE:
      const { isMobile } = action;
      return { ...state, isMobile };

    default:
      return state;
  }
}
