import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';

import { AuthDataState } from '@store/auth/auth.reducer';
import { authDataState } from '@store/auth';
import { SetUrlForRedirectAction } from '@store/auth/auth.actions';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { User } from '@core/models/user';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  userSelf: User;
  isAuthenticated: boolean;

  private unAuthUrls = ['/changePassword', '/login', '/signup', '/forgot-pass'];

  constructor(private store: Store<AuthDataState>, private router: Router) {
    store.pipe(select(authDataState)).subscribe(({ isAuthenticated, userSelf }) => {
      this.userSelf = userSelf;
      this.isAuthenticated = isAuthenticated;
    });
  }

  canActivate(_next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const url: string = state.url;
    // Store the attempted URL for redirecting
    this.store.dispatch(new SetUrlForRedirectAction(url));

    return this.store.pipe(
      select(authDataState),
      map(({ isAuthenticated }) => {
        this.isAuthenticated = isAuthenticated;
        return isAuthenticated;
      }),
      map(_ => this.checkLogin(url)),
      catchError(_ => of(this.checkLogin(url)))
    );
  }

  checkLogin(url: string): boolean {
    const isUnAuthRoute = this.unAuthUrls.includes(url);
    if (isUnAuthRoute) {
      if (this.isAuthenticated) {
        this.router.navigateByUrl('dashboard');
        return false;
      } else {
        return true;
      }
    } else {
      return this.isAuthenticated;
    }
  }
}
