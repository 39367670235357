import { User } from '@models/user';
import { AuthAction } from './auth.actions';
import { AuthActionsType } from './auth.actions.name';
import { createSelector } from '@ngrx/store';
import { authDataState } from '.';

export interface AuthDataState {
  userSelf: User;
  loading: boolean;
  authError: string /** Stores any error from login, register or forgot password components */;
  redirectUrl: string;
  isAuthenticated: boolean;
  passwordRecovered: boolean;
}

export const initialState: AuthDataState = {
  loading: false,
  userSelf: null,
  authError: null,
  redirectUrl: undefined,
  isAuthenticated: false,
  passwordRecovered: false
};

export function authDataReducer(state = initialState, action: AuthAction): AuthDataState {
  switch (action.type) {
    case AuthActionsType.LOAD_USER_SELF:
    case AuthActionsType.CHANGE_PASSWORD:
      return { ...state, loading: true };
    case AuthActionsType.RECOVER_PASSWORD:
      return { ...state, loading: true, passwordRecovered: false };
    case AuthActionsType.USER_SELF_LOADED:
      const isAuthenticated = !!action.user;
      return { ...state, loading: false, userSelf: action.user, isAuthenticated };
    case AuthActionsType.AUTH_ERROR:
      return { ...state, loading: false, authError: action.errorCode };
    case AuthActionsType.REGISTER:
    case AuthActionsType.LOAD_USER_SELF:
    case AuthActionsType.LOGIN_WITH_GOOGLE:
    case AuthActionsType.LOGIN_WITH_CREDENTIALS:
      return { ...state, loading: true, authError: null };
    case AuthActionsType.PASSWORD_RECOVERED:
      return { ...state, loading: false, authError: null, passwordRecovered: true };
    case AuthActionsType.SET_URL_FOR_REDIRECT:
      return { ...state, redirectUrl: action.url };

    default:
      return state;
  }
}

export const userSelfSelector = createSelector(authDataState, ({ userSelf }) => {
  return userSelf;
});
