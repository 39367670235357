import { FirestoreReduxListener } from '@store/firestore/firestore.redux.listener';
import { Plan } from '@core/models/plan.model';
import { PlanDataState, PlanReducer } from './plan.firestore.reducer';
import { Store } from '@ngrx/store';
import { QueryFn, AngularFirestore } from '@angular/fire/firestore';

export class PlanFirestoreReduxListener extends FirestoreReduxListener<Plan, PlanDataState> {
  constructor(db: AngularFirestore, store: Store<PlanDataState>, queryFn?: QueryFn) {
    super(db, store, PlanReducer.getInstance());

    this.initializeCollectionWithQuery({ queryFn });
    this.startListening();
  }
}
