import { createAction, props } from '@ngrx/store';
import { ImageGalleryActionsType } from './image-gallery.actions.name';
import { ChikettoImage } from '@models/chiketto-image';

export const loadImageGallery = createAction(
  ImageGalleryActionsType.IMAGE_GALLERY_LOAD,
  props<{
    companyId: string;
  }>()
);

export const loadImageGallerySuccess = createAction(
  ImageGalleryActionsType.IMAGE_GALLERY_LOAD_SUCCESS,
  props<{ chikettoImages: ChikettoImage[]; nextQuery: string }>()
);

export const noEventImageGallery = createAction(ImageGalleryActionsType.IMAGE_GALLERY_EMPTY);

export const loadImageGalleryFailure = createAction(
  ImageGalleryActionsType.IMAGE_GALLERY_LOAD_FAILURE,
  props<{ error: any }>()
);
