<div class="cnt-dialog spr-cnt-cms-list-connector">
  <!-- [@slideContent]="animationState"
  (@slideContent.start)="onAnimationStart($event)"
  (@slideContent.done)="onAnimationDone($event)" -->

  <h2 class="title demo-plan" *ngIf="isDemoPlan">
    {{ 'cmsList.apiKeyNotAvailableForDemoPlan' | translate }}
    <mat-icon (click)="closeDialog()" class="close to-right">close</mat-icon>
  </h2>
  <h2 class="title" *ngIf="!cmsChosen && !isDemoPlan">
    {{ 'cmsList.addSalesChannels' | translate }}
    <mat-icon (click)="closeDialog()" class="close to-right">close</mat-icon>
  </h2>
  <h2 class="title" *ngIf="cmsChosen && !isDemoPlan">
    {{ cmsChosen.name | titlecase }} <mat-icon (click)="closeDialog()" class="close to-right">close</mat-icon>
  </h2>


  <div class="dialog-body">
    <mat-divider class="dialog-divider to-top"></mat-divider>

    <p class="description" *ngIf="!cmsChosen">{{ 'cmsList.ourRecomendation' | translate }}</p>

    <div class="cnt-sub-section" *ngIf="!cmsChosen">
      <div class="cnt-cms" *ngFor="let cms of cmsList">
        <img src="/assets/imgs/cms/{{ cms.name }}.svg" alt="{{ cms.name }}" class="brand"
          [ngClass]="cntClass(cms.active)" />
        <div class="cnt-info">
          <h3>{{ cms.name | titlecase }}</h3>
          <span [ngClass]="cntClass(cms.active)">{{
            (cms.active ? 'cmsList.freeToInstall' : 'cmsList.commingSoon') | translate
          }}</span>
          <p>{{ 'cmsList.' + cms.name | translate }}</p>
        </div>
        <button class="btn-purple" [disabled]="!cms.active || isDemoPlan"
          (click)="selectCMS(cms)">{{ 'add' | translate }}
        </button>
      </div>
    </div>
    <div class="cnt-sub-section api-key-section" *ngIf="cmsChosen">
      <h2 class="go-back" (click)="goBack()">
        <span class="wi-back-arrow icon"></span>
        {{ 'cmsList.addSalesChannels' | translate }}
      </h2>
      <p class="recomendation">{{ 'cmsList.connectorRecomendation' | translate }}</p>
      <div class="cnt-cms">
        <img src="/assets/imgs/cms/{{ cmsChosen.name }}.svg" alt="{{ cmsChosen.name }}" class="brand"
          [ngClass]="cntClass(cmsChosen.active)" />
        <div class="cnt-info">
          <h3>{{ cmsChosen.name | titlecase }}</h3>
          <span [ngClass]="cntClass(cmsChosen.active)">
            {{ (cmsChosen.active ? 'freeToInstall' : 'commingSoon') | translate }}
          </span>
          <p>{{ 'cmsList.' + cmsChosen.name | translate }}</p>

          <div class="cnt-api-key" *ngIf="!accessToken">
            <label>{{ 'cmsList.generate' | translate }}</label>
            <button class="btn-purple" [disabled]="!cmsChosen.active || !permissionToGenerate || isDemoPlan"
              (click)="tryGenerateApiKey()" *ngIf="!generatingKey">
              {{ 'cmsList.generateApiKey' | translate }}
            </button>
            <small *ngIf="!permissionToGenerate" class="error">{{ 'cmsList.dontHavePermission' | translate }}</small>
            <mat-spinner diameter="30" *ngIf="generatingKey"></mat-spinner>
          </div>
          <div class="cnt-api-key" *ngIf="accessToken">
            <div class="cnt-header-token">
              <label>{{ 'cmsList.accessToken' | translate }}</label>
              <div class="cnt-copy-token" (click)="copyToken()">
                <mat-icon>file_copy</mat-icon>
                <span>{{ 'copy' | translate }}</span>
              </div>
            </div>
            <div class="cnt-token">
              <span>{{ accessToken }}</span>
            </div>
            <small>{{ 'cmsList.instructionToken' | translate }}</small>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>