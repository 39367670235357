import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { InviteUserActionsType } from './invite-user.actions.name';
import { switchMap, map, catchError } from 'rxjs/operators';
import { AuthService } from '@services/auth/auth.service';
import { InviteUser, UserInvited, InviteUserError } from './invite-user.actions';

@Injectable()
export class InviteUserEffects {
  constructor(private actions$: Actions, private authService: AuthService) {}

  @Effect()
  inviteUser$: Observable<Action> = this.actions$.pipe(
    ofType(InviteUserActionsType.INVITE_USER),
    switchMap((action: InviteUser) => {
      const email = action.email;
      const companyId = action.companyId;
      const superUserId = action.superUserId;
      return this.authService.inviteUser(email, companyId, superUserId).pipe(
        map(_ => new UserInvited()),
        catchError(error => of(new InviteUserError(error.message)))
      );
    })
  );
}
