<mat-sidenav-container class="cnt-sidenav">
  <mat-sidenav
    class="sidenav"
    fixedInViewport="true"
    [fixedTopGap]="0"
    [fixedBottomGap]="0"
    [opened]="opened"
    [mode]="mobileQuery.matches ? 'over' : 'side'"
    *ngIf="isAuthenticated"
  >
    <!-- Sidenav logo -->
    <mat-toolbar class="header" [ngClass]="isMobile">
      <img src="assets/imgs/woden.svg" alt="Woden" class="logo" routerLink="/dashboard" />
    </mat-toolbar>
    <!-- Menu -->
    <div class="cnt-menu" (click)="tryCloseSideNav()">
      <mat-nav-list>
        <a mat-list-item class="item-menu" routerLink="/dashboard" routerLinkActive="active">
          <span class="wi-home"></span> Dashboard
        </a>
        <!-- Sales channels accordion -->
        <mat-accordion mat-list-item class="item-menu-2">
          <mat-expansion-panel>
            <mat-expansion-panel-header (click)="$event.stopPropagation()">
              <mat-panel-title>
                {{ 'sideNav.salesChannels' | translate }}
              </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="cms-list">
              <div class="cnt-cms">
                <img src="/assets/imgs/cms/woocommerce.svg" alt="woocommerce" />
                <span>Woocommerce</span>
                <button class="btn-purple btn-small" (click)="showCMSList('woocommerce')" *ngIf="!userSelf?.woo">
                  Add
                </button>
                <mat-icon *ngIf="userSelf?.woo">check</mat-icon>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </mat-nav-list>

      <mat-nav-list>
        <a
          mat-list-item
          class="item-menu"
          routerLink="/payments"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <span class="wi-credit-card"></span> Payments
        </a>
        <a mat-list-item class="item-menu" routerLink="/settings" routerLinkActive="active">
          <span class="wi-settings"></span> Settings
        </a>
      </mat-nav-list>
    </div>
  </mat-sidenav>

  <mat-sidenav-content class="container-app">
    <!-- city background -->
    <div class="cnt-img-bg">
      <div class="img-bg cnt-bg"></div>
      <div class="img-bg cnt-bg1"></div>
      <div class="img-bg cnt-bg2"></div>
    </div>
    <mat-toolbar class="toolbar" *ngIf="showToolbar && isAuthenticated">
      <!-- Toggle sidenav button -->
      <button mat-icon-button (click)="toggleSideNav()">
        <ng-container *ngIf="mobileQuery.matches">
          <mat-icon class="menu-icon" fontSet="fa" fontIcon="fa-bars" aria-hidden="true"></mat-icon>
          <img src="/assets/imgs/woden-icon.svg" alt="Woden" class="logo-sm" />
        </ng-container>
      </button>
      <!-- User card -->
      <div class="cnt-user-card" [matMenuTriggerFor]="menu" [ngClass]="isMobile">
        <img [src]="companyImg" class="user-avatar" />
        <div class="user-info" *ngIf="!mobileQuery.matches">
          <p class="mat-body-2">{{ companyName }}</p>
          <p class="mat-caption">{{ userEmail }}</p>
        </div>
      </div>
      <!-- User menu -->
      <div class="cnt-user-menu">
        <mat-menu #menu="matMenu" xPosition="before" yPosition="below" [overlapTrigger]="false" class="user-menu">
          <mat-divider></mat-divider>
          <button mat-menu-item routerLink="/settings">
            <mat-icon class="menu-icon" fontSet="fa" fontIcon="fa-cog" aria-hidden="true"></mat-icon>
            <span>Settings</span>
          </button>
          <mat-divider></mat-divider>
          <button mat-menu-item (click)="onLogout()">
            <mat-icon class="menu-icon" fontSet="fa" fontIcon="fa-sign-out" aria-hidden="true"></mat-icon>
            <span>Log Out</span>
          </button>
        </mat-menu>
      </div>
    </mat-toolbar>
    <!-- Fade toolbar -->
    <mat-toolbar class="toolbar-invisible" *ngIf="isAuthenticated"></mat-toolbar>
    <!-- application outlet <Router> -->
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
