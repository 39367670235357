import { FirestoreReduxListener } from '@store/firestore/firestore.redux.listener';
import { Ticket } from '@core/models/ticket';
import { TicketDataState, TicketReducer } from './tickets.firestore.reducer';
import { AngularFirestore, QueryFn } from '@angular/fire/firestore';
import { Store } from '@ngrx/store';

export class TicketFirestoreReduxListener extends FirestoreReduxListener<Ticket, TicketDataState> {
  constructor(db: AngularFirestore, store: Store<TicketDataState>, queryFn?: QueryFn) {
    super(db, store, TicketReducer.getInstance());

    this.initializeCollectionWithQuery({ queryFn });
    this.startListening();
  }
}
